import React, { FC, useContext, Suspense, useState, useEffect, useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CustomSpinner } from '../../shared/Spinner';
import { MoviesList } from '../../components/MoviesList';
import { useTranslation } from 'react-i18next';
import styles from './SearchPage.module.scss';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useSearchQuery } from './query';
import { URLSearchQueryStateModel } from './types';
import { AuthContext } from 'src/context/auth';
import {generateImageUrl} from "../../utils/cdnService";

const SearchPage: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;
  let searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [query, setQuery] = useState<URLSearchQueryStateModel>({
    q: searchParams.get('q') as string,
    page: 1,
  });
  const { searchData, isSearchQueryLoading, searchMeta } = useSearchQuery(query, isAuthenticated);

  useEffect(() => {
    setQuery({
      ...query,
      q: searchParams.get('q') as string,
    });
  }, [query, search, searchParams]);

  if (isSearchQueryLoading) {
    return <CustomSpinner />;
  }
  const renderMovieList = () => {
    if (searchData && searchData?.length === 0) {
      return (
        <React.Fragment>
          <Col sm={12}>
            <h2>{t('searchPage.notFound')}</h2>
          </Col>
          <Col sm={6}>
            <h2>{t('searchPage.proposals')}</h2>
            <ul className="result-info ml--15">
              <li>{t('searchPage.spellCheck')}</li>
              <li>{t('searchPage.fewerTerms')}</li>
            </ul>
          </Col>
          <Col sm={6}>
            <img
              className="img-responsive"
              src={generateImageUrl('gcore', 0, 0, '/old_images/BMO_no-result.jpg?v=59')}
              alt="no result"
            />
          </Col>
        </React.Fragment>
      );
    }

    return <MoviesList data={searchData} />;
  };

  const handlePageChange = (data: { selected: number }) => {
    const selectedPage = data.selected + 1;
    setQuery({
      ...query,
      page: selectedPage,
    });
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setQuery({
      ...query,
      sort: e.target.value,
    });
    if (e.target.value === 'new') {
      setQuery({
        ...query,
        sort: 'license.online_start',
      });
    }
    if (e.target.value === 'old') {
      setQuery({
        ...query,
        sort: '-license.online_start',
      });
    }
    if (e.target.value === 'title-a') {
      setQuery({
        ...query,
        sort: 'content_data.title',
      });
    }
    if (e.target.value === 'title-d') {
      setQuery({
        ...query,
        sort: '-content_data.title',
      });
    }
  };
  const renderPagination = () => {
    if (searchMeta && !isSearchQueryLoading) {
      return (
        <ReactPaginate
          previousLabel={<i className="fa fa-chevron-left"></i>}
          nextLabel={<i className="fa fa-chevron-right"></i>}
          breakLabel={'...'}
          breakClassName={styles.breakMe}
          pageCount={searchMeta?.last_page}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          forcePage={(query.page as number) - 1}
          containerClassName={'pagination'}
          activeClassName={styles.active}
        />
      );
    }
    return null;
  };
  return (
    <Container>
      <Row>
        <Col sm={12}>{/*{renderBanners()}*/}</Col>
      </Row>

      <h2> SUCHERGEBNISSE - {searchParams.get('q')}</h2>
      {searchData && searchData?.length > 0 && searchMeta?.last_page > 1 && (
        <Col sm={{ span: 9, offset: 3 }}>
          <div className={styles.paginationWrapper}>
            <div className={styles.pagination}>{renderPagination()}</div>
          </div>
        </Col>
      )}
      {searchData && searchData?.length > 0 && (
        <Row>
          <Col className={styles.sortWrapper} md={4}>
            <div className={styles.sortOptions}>
              <Form.Select
                value={(query.sort as string) || 'license.online_start'}
                onChange={handleOptionChange}
                size="sm"
              >
                <option value="license.online_start">{t('moviesPage.filterNew')}</option>
                <option value="-license.online_start">{t('moviesPage.filterOld')}</option>
                <option value="content_data.title">{t('moviesPage.filterA')}</option>
                <option value="-content_data.title">{t('moviesPage.filterZ')}</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
      )}

      <Suspense fallback={<CustomSpinner />}>
        <Row>{renderMovieList()}</Row>
        <Row>
          {searchData && searchData?.length > 0 && searchMeta?.last_page > 1 && (
            <Col sm={{ span: 9, offset: 3 }}>
              <div className={styles.paginationWrapper}>
                <div className={styles.pagination}>{renderPagination()}</div>
              </div>
            </Col>
          )}
        </Row>
      </Suspense>
    </Container>
  );
};

export default SearchPage;
