import { useMutation } from '@tanstack/react-query';
import { CheckPin } from './types';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';

const useCheckPinMutation = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (body: CheckPin) => {
      const response = await fetchRedirect(createUrl(`/checkPin`), {
        method: 'POST',
        body: JSON.stringify(body),
      });
      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
  });
  return { mutate, isLoading };
};

export default useCheckPinMutation;
