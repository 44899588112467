import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { DepositOverviewModel } from './types';

type VouchersQueryModel = {
  vouchersData: DepositOverviewModel[];
  isVouchersQueryLoading: boolean;
  refetchVouchersQuery: () => void;
  voucherError: boolean;
};

const useVouchersQuery = (): VouchersQueryModel => {
  const [voucherError, setVoucherError] = useState(false);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['deposit_vouchers'],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/payment/overview/vouchers'));
      if (res.response.ok) {
        return res.body.data;
      } else {
        setVoucherError(true);
      }
    },
  });

  const vouchersData = data;
  const isVouchersQueryLoading = isLoading;
  const refetchVouchersQuery = refetch;
  return { vouchersData, isVouchersQueryLoading, refetchVouchersQuery, voucherError };
};

export default useVouchersQuery;
