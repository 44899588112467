import React, { FC, Suspense, useContext, useEffect, useState } from 'react';
import styles from './MoviesPage.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import ReactPaginate from 'react-paginate';
import { CustomSpinner } from '../../shared/Spinner';
import { MoviesList } from '../../components/MoviesList';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { MultiSelect } from 'react-multi-select-component';

import { AuthContext } from '../../context/auth';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { RangesModel, StudiosModel, TagsModel } from './types';
import { ChangeDurationModel, ChangePageModel, ChangeStudiosModel, URLQueryModel } from './types';
import { useStudiosQuery, useTagsQuery, useMoviesRangesQuery, useMoviesQuery } from './query';
import { renderFormattedTitle } from '../../utils/stringManipulation';
import { CustomLink } from 'src/components/CustomLink';

const MoviesPage: FC = () => {
  const [selected, setSelected] = useState<ChangeStudiosModel[]>([]);
  const [selectedRanges, setSelectedRanges] = useState<ChangeDurationModel[]>([]);
  const navigate = useNavigate();

  const [query, setQuery] = useState<URLQueryModel>({
    page: qs.parse(window.location.search, { ignoreQueryPrefix: true }).page || 1,
    sort: qs.parse(window.location.search, { ignoreQueryPrefix: true }).sort || '',
    studio: qs.parse(window.location.search, { ignoreQueryPrefix: true }).sort || '',
    duration: qs.parse(window.location.search, { ignoreQueryPrefix: true }).sort || '',
  });
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [navExpanded, setNavExpanded] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  // GET MOVIES
  const { studios, areStudiosLoading } = useStudiosQuery(isAuthenticated);
  const { ranges, areRangesLoading } = useMoviesRangesQuery(isAuthenticated);
  const { tags, areTagsLoading } = useTagsQuery(isAuthenticated);
  const { movies, meta, isMovieLoading } = useMoviesQuery(query, isAuthenticated);

  const renderMovieList = () => {
    if (isMovieLoading && !areTagsLoading) {
      return <CustomSpinner />;
    } else {
      return <MoviesList data={movies} />;
    }
  };

  const renderPagination = () => {
    if (meta) {
      return (
        <ReactPaginate
          previousLabel={<i className="fa fa-chevron-left" />}
          nextLabel={<i className="fa fa-chevron-right" />}
          breakLabel={'...'}
          breakClassName={styles.breakMe}
          pageCount={meta && meta.last_page}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          forcePage={(query.page as number) - 1}
          containerClassName={'pagination'}
          activeClassName={styles.active}
          previousClassName={query.page === 1 ? `${styles.hideButton}` : `${styles.showButton}`}
          nextClassName={meta && query.page === meta.last_page ? `${styles.hideButton}` : `${styles.showButton}`}
        />
      );
    }
    return null;
  };

  const closeNav = () => {
    setNavExpanded(false);
    setOpen(false);
  };

  const setNav = (expanded: boolean) => {
    setNavExpanded(expanded);
    setOpen(!open);
  };
  const renderTags = () => {
    if (tags) {
      return (
        <ul className={styles.category}>
          <Navbar onToggle={setNav} expanded={navExpanded} className={styles.navbarWrapper} expand="md">
            <Navbar.Toggle aria-controls="category-box" className={styles.categoryButton}>
              <div>
                Kategorien
                <span id="category-head-icon-wrap" className="open">
                  {open ? (
                    <i className="fa fa-caret-up" aria-hidden="true" />
                  ) : (
                    <i className="fa fa-caret-down" aria-hidden="true" />
                  )}
                </span>
              </div>
            </Navbar.Toggle>
            <Navbar.Collapse className={styles.categoryBox} id="category-box">
              <Nav onClick={() => closeNav()}>
                <CustomLink to={`/${language}/movies`}>
                  <li className={styles.active}>Alle Filme</li>
                </CustomLink>
                {tags.map((tag: TagsModel['data'], index: number) => (
                  <CustomLink key={index} to={`/${language}/tag/${tag.id}`}>
                    <li>{renderFormattedTitle(tag.name)}</li>
                  </CustomLink>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </ul>
      );
    }
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setQuery({
      ...query,
      sort: e.target.value,
    });
    if (e.target.value === 'new') {
      setQuery({
        ...query,
        sort: '-license.online_start',
      });
    }
    if (e.target.value === 'old') {
      setQuery({
        ...query,
        sort: 'license.online_start',
      });
    }
    if (e.target.value === 'title-a') {
      setQuery({
        ...query,
        sort: 'content_data.title',
      });
    }
    if (e.target.value === 'title-d') {
      setQuery({
        ...query,
        sort: '-content_data.title',
      });
    }
  };

  const handlePageChange = (data: ChangePageModel) => {
    const selectedPage = data.selected + 1;
    setQuery({
      ...query,
      page: selectedPage,
    });
  };

  useEffect(() => {
    let formatedQuery = qs.stringify(query);
    navigate(`?${formatedQuery}`, { replace: true });
  }, [navigate, query]);

  const formatLabel = (name: string, no: number) => {
    const n = 15;
    const label = name?.length > n ? name.substr(0, n - 1) + '...' : name;
    return `${label} (${no})`;
  };

  const handleStudioChange = (values: ChangeStudiosModel[]) => {
    setSelected(values);
    if (values?.length < 0) {
    }
    const collection = values.map((v) => v.value);
    setQuery({
      ...query,
      studio: collection,
    });
  };

  const handleDurationChange = (values: ChangeDurationModel[]) => {
    setSelectedRanges(values);
    if (values?.length < 0) {
    }
    const collection = values.map((v) => v.value);
    setQuery({
      ...query,
      duration: collection,
    });
  };

  const handleResetFilters = () => {
    setQuery({
      page: 1,
    });
    setSelected([]);
    setSelectedRanges([]);
  };

  if (areTagsLoading || areStudiosLoading || areRangesLoading) {
    return (
      <Container className={styles.mainWrapper}>
        <CustomSpinner />
      </Container>
    );
  }
  return (
    <Container className={styles.mainWrapper}>
      <Row>
        <Col className={styles.topNavigation} sm={{ span: 9, offset: 3 }}>
          {selected?.length > 0 || query.duration || query.duration ? (
            <React.Fragment>
              <span onClick={handleResetFilters} style={{ cursor: 'pointer', background: '#3047af', marginRight: 10 }}>
                <i style={{ padding: 5, fontSize: 20 }} className="fa fa-times" />
              </span>
              <span
                style={{
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
                onClick={handleResetFilters}
              >
                {t('moviesPage.resetFilters')}
              </span>
            </React.Fragment>
          ) : null}
          <div className={styles.paginationWrapper}>
            <div className={styles.pagination}>{renderPagination()}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <div className={styles.title}>
            <h2>{t('moviesPage.title')}</h2>
          </div>
        </Col>
        <Col className={styles.mobilePagination} sm={{ span: 9, offset: 3 }}>
          {selected?.length > 0 || query.duration || query.duration ? (
            <span
              style={{
                cursor: 'pointer',
                userSelect: 'none',
              }}
              onClick={handleResetFilters}
            >
              {t('moviesPage.resetFilters')}
            </span>
          ) : null}
          <div className={styles.paginationWrapper}>
            <div className={styles.pagination}>{renderPagination()}</div>
          </div>
        </Col>
        <Col md={9}>
          <Row>
            <Col className={styles.sortWrapper} md={4}>
              <div className={styles.sortOptions}>
                <Form.Select
                  value={(query.sort as string) || '-license.online_start'}
                  onChange={handleOptionChange}
                  size="sm"
                >
                  <option value="-license.online_start">{t('moviesPage.filterNew')}</option>
                  <option value="license.online_start">{t('moviesPage.filterOld')}</option>
                  <option value="content_data.title">{t('moviesPage.filterA')}</option>
                  <option value="-content_data.title">{t('moviesPage.filterZ')}</option>
                </Form.Select>
              </div>
            </Col>

            {isAuthenticated && (
              <React.Fragment>
                <Col className={styles.sortWrapper} md={4}>
                  <MultiSelect
                    className={styles.customSelect}
                    isLoading={areStudiosLoading}
                    options={studios && studios.map((studio: StudiosModel) => ({
                      label: formatLabel(studio.name, studio.content_length),
                      value: studio.id,
                    }))}
                    value={selected}
                    onChange={handleStudioChange}
                    labelledBy={'Select'}
                    overrideStrings={{
                      selectSomeItems: 'Studios',
                    }}
                  // selectAllLabel={t('moviesPage.allSelected')}
                  />
                </Col>
                <Col className={styles.sortWrapper} md={4}>
                  <MultiSelect
                    className={styles.customSelect}
                    isLoading={areRangesLoading}
                    options={
                      ranges &&
                      ranges.map((range: RangesModel) => ({
                        label: `${range.from}  ${range.to ? `to ${range.to}` : '>'} (${range.count})`,
                        value: `${range.from}${range.to ? `,${range.to}` : ''}`,
                        background: 'white',
                        '.dropdown-content': {
                          background: 'white',
                        },
                      }))
                    }
                    hasSelectAll={false}
                    disableSearch
                    value={selectedRanges}
                    onChange={handleDurationChange}
                    labelledBy={'Select'}
                    overrideStrings={{
                      selectSomeItems: 'Duration',
                    }}
                  />
                </Col>
              </React.Fragment>
            )}
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className={styles.categoryWrapper} md={3}>
          <div>
            <div className={styles.categories}>{renderTags()}</div>
          </div>
        </Col>
        <Col md={9}>
          <Suspense fallback={<CustomSpinner />}>
            <Row>
              {renderMovieList()}

              <Col sm={{ span: 9, offset: 3 }}>
                <div className={styles.paginationWrapper}>
                  <div className={styles.pagination}>{renderPagination()}</div>
                </div>
              </Col>
            </Row>
          </Suspense>
        </Col>
      </Row>
    </Container>
  );
};

export default MoviesPage;
