import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { queryClient } from 'src/core/root/components/ReactQueryProvider/ReactQueryProvider';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { WishlistBookmarksModel } from '../query/types';
import { URLWishlistQueryStateModel } from '../types';

const useBookmarksDeleteMutation = (query: URLWishlistQueryStateModel) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: number) => {
      const response: any = await fetchRedirect(createUrl(`/user/bookmarks/delete/${data}`));
      if (!response.ok) {
        return response.body;
      }
      return response.body;
    },
    onMutate: async (newTodo) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['wishlist_bookmark', `${Object.values(query)}`] });

      // Snapshot the previous value
      const previousTodos = queryClient.getQueryData(['wishlist_bookmark', `${Object.values(query)}`]);

      // Optimistically update to the new value
      queryClient.setQueryData(['wishlist_bookmark', `${Object.values(query)}`], (old: any) =>
        old.data.filter((video: WishlistBookmarksModel) => video.id !== newTodo)
      );

      // Return a context object with the snapshotted value
      return { previousTodos };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (context: any) => {
      queryClient.setQueryData(['wishlist_bookmark', `${Object.values(query)}`], context.previousTodos);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['wishlist_bookmark', `${Object.values(query)}`] });
    },
  });
  return { mutate, isLoading };
};

export default useBookmarksDeleteMutation;
