import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import styles from './PaymentOverview.module.scss';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { Balance } from '../../components/Balance';
import { useTranslation } from 'react-i18next';
import { useDepositQuery, useVouchersQuery } from '../Deposit/query';

const PaymentOverview: FC = () => {
  const { t } = useTranslation();
  const { depositData, isDepositOverviewLoading } = useDepositQuery();
  const { vouchersData, isVouchersQueryLoading } = useVouchersQuery();

  const renderDepositData = () => {
    if (!isDepositOverviewLoading) {
      return (
        depositData &&
        depositData.map((data) => (
          <tr>
            <th>{data.payment_type}</th>
            <th>
              {data.amount}
              {data.currency}
            </th>
            <th>{data.date}</th>
            <th>{data.platform}</th>
          </tr>
        ))
      );
    }
  };
  const renderVoucherData = () => {
    if (!isVouchersQueryLoading) {
      return (
        vouchersData &&
        vouchersData.map((data) => (
          <tr>
            <th>{data.payment_type}</th>
            <th>
              {data.amount}
              {data.currency}
            </th>
            <th>{data.date}</th>
            <th>{data.platform}</th>
          </tr>
        ))
      );
    }
  };

  return (
    <Container>
      <div className={styles.pageHeader}>
        <Balance />
      </div>
      <h2>{t('paymentsPage.paymentsPageTitle')}</h2>
      <Row>
        <Col>
          <Accordion>
            <Card className={styles.card}>
              <Accordion.Item className={styles.accordion_item} eventKey="2">
                <Accordion.Button className={styles.accordion_button}>{t('paymentsPage.deposit')}</Accordion.Button>
                <Accordion.Body as={Card.Body}>
                  <Table className={styles.table} bordered size="sm">
                    <thead>
                      <tr>
                        <th>{t('paymentsPage.depositAccountType')}</th>
                        <th>{t('paymentsPage.depositAmount')}</th>
                        <th>{t('paymentsPage.depositDate')}</th>
                        <th>{t('paymentsPage.depositPlatform')}</th>
                      </tr>
                    </thead>
                    <tbody>{renderDepositData()}</tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Card>
            <Card className={styles.card}>
              <Accordion.Item className={styles.accordion_item} eventKey="4">
                <Accordion.Button className={styles.accordion_button}>{t('paymentsPage.credits')}</Accordion.Button>
                <Accordion.Body as={Card.Body}>
                  <Table className={styles.table} bordered size="sm">
                    <thead>
                      <tr>
                        <th>{t('paymentsPage.creditType')}</th>
                        <th>{t('paymentsPage.creditAmount')}</th>
                        <th>{t('paymentsPage.creditDate')}</th>
                        <th>{t('paymentsPage.creditPlatform')}</th>
                      </tr>
                    </thead>
                    <tbody>{renderVoucherData()}</tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentOverview;
