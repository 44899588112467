import React, { useEffect, useContext, useState, FC } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import styles from './App.module.scss';
//Components
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { EuPortability } from './components/UI/Modals/EuPortability';

import { AuthContext } from './context/auth';

import { usePrefetchQuery } from './pages/movies/movieDetails/query';
import { useAppLogout } from './core/api/components/AppLogout/useAppLogout';
import moment from 'moment';
import { StbPromotionsModal } from './components/UI/Modals/StbPromotionsModal';
import { RoutesList } from './routes';
import { CookieModal } from './components/Cookie';

const gaCode = process.env.REACT_APP_GOOGLE_ANALYTICS;

// Initalizing google analytics

ReactGA.initialize(gaCode as string);

const App: FC = () => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, firstLogin, euPortabilityStatusPopup, euPortabilityStatusPopupText, logout, user } =
    authContext;
  const [showModal, setShowModal] = useState<boolean>(false);
  const userHasPromotions = user.stb_promotions?.length && user.stb_promotions?.length > 0 ? true : false;
  const [userOnboardingModal, setUserOnboardingModal] = useState<boolean>(false);
  const { pathname } = useLocation();
  usePrefetchQuery();

  // We need country code
  usePrefetchQuery();

  useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);

  useEffect(() => {
    if (isAuthenticated && !loading && firstLogin) {
      if (euPortabilityStatusPopup) {
        setShowModal(true);
      }
      if (userHasPromotions) {
        setUserOnboardingModal(true);
      }
    }
  }, [euPortabilityStatusPopup, firstLogin, isAuthenticated, loading, userHasPromotions]);

  useEffect(() => {
    if (isAuthenticated) {
      // We are keeping everything in localstorage cause payment window is opening in the same tab but client wishes if he close the tab and come back after 10 minutes to logout.
      const timestamp = localStorage.getItem('blue_lastTimeStamp');
      if (timestamp) {
        const twoMinutesAgo = moment(timestamp).add(10, 'minutes');
        const timeNow = moment().isAfter(twoMinutesAgo);
        if (timeNow) {
          logout();
        }
      }
    }
  }, [isAuthenticated, logout]);

  //App logout timer and event listener
  useAppLogout();

  return (
    <>
      <div className={styles.container}>
        <Header />
        <div className={styles.content}>
          <EuPortability
            text={euPortabilityStatusPopupText}
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
            centered={true}
          />

          {userHasPromotions && (
            <StbPromotionsModal
              show={userOnboardingModal}
              onHide={() => {
                setUserOnboardingModal(false);
              }}
            />
          )}

          <RoutesList />
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
      <CookieModal />
    </>
  );
};

export default App;
