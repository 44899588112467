import merge from 'lodash/merge';
import { configureRefreshFetch, fetchJSON } from 'refresh-fetch';
import { HeaderModal } from 'src/hooks/types';

const TOKEN_NAME = 'blue_token';
const REFRESH_TOKEN_NAME = 'blue_refresh_token';

const retrieveToken = () => localStorage.getItem(TOKEN_NAME);
const saveToken = (token: string) => localStorage.setItem(TOKEN_NAME, token);

const clearToken = () => localStorage.removeItem(TOKEN_NAME);
const clearRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN_NAME);

const retriveRefetchToken = () => localStorage.getItem(REFRESH_TOKEN_NAME);
const saveRefreshToken = (token: string) => localStorage.setItem(REFRESH_TOKEN_NAME, token);

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const fetchJSONWithToken = (url: string, options = {}) => {
  const token = retrieveToken();
  let headers: HeaderModal = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    platform: 'web',
    device: 'web',
    'X-Locale': 'de',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  let optionsWithToken;
  optionsWithToken = merge(options, { headers: headers });

  return fetchJSON(url, optionsWithToken);
};

const shouldRefreshToken = (error: any) => {
  return error.response?.status === 401 && error.body?.message === 'Token has expired.';
};

const refreshToken = () => {
  const token = retrieveToken();

  const refresh_token = retriveRefetchToken();
  const data = {
    refresh_token,
    scope: '',
    access_token: token,
  };
  return fetchJSONWithToken(`${BASE_URL}/refresh-token`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      platform: 'web',
      device: 'web',
      'X-Locale': 'de',
    },
    body: JSON.stringify(data),
  })
    .then((response: any) => {
      saveToken(response.body.data.access_token);
      saveRefreshToken(response.body.data.refresh_token);
    })
    .catch((error: any) => {
      // Clear token and continue with the Promise catch chain
      clearRefreshToken();
      clearToken();
      throw error;
    });
};

const fetch = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken,
  refreshToken,
});

export { fetch, refreshToken };
