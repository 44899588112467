import React, { FC, useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LogoutPage: FC = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading } = authContext;
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated && !loading) {
      navigate('/');
    }
  }, [navigate, isAuthenticated, loading]);

  return (
    <Container>
      <Row>
        <Col className="pb-5">
          <h2 style={{ fontWeight: '500' }}>{t('logoutPage.logoutPageHeader')}</h2>
          <h2>
            <i style={{ color: 'green' }} className="fa fa-check"></i> {t('logoutPage.logoutPageMessage')}
          </h2>
          <h3>{t('logoutPage.logoutPageText')}</h3>
        </Col>
      </Row>
    </Container>
  );
};

export default LogoutPage;
