import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './CustomLink.module.scss';
type Props = {
  to: string;
  children: React.ReactNode;
  className?: string;
  key?: string | number;
};
const CustomLink: FC<Props> = ({ key, to, children, className }) => {
  return (
    <Link key={key} to={to} className={`${styles.link} ${className}`}>
      {children}
    </Link>
  );
};

export default CustomLink;
