import React, { FC } from 'react';
import styles from './PromotionBadge.module.scss';
import {generateImageUrl} from "../../utils/cdnService";

type Props = {
  image: string;
  value: string | number;
  margin?: boolean;
};

const PromotionBadge: FC<Props> = ({ image, value, margin }) => {
  return (
    <div className={styles.promotionBadgeWrapper}>
      <div className={styles.promotionImage}>
        <div className={`${styles.imgContainer} ${margin && styles.imgMargin}`}>
          <img src={`${generateImageUrl('gcore', 0, 0, image)}`} alt="promo" />
          <div className={styles.promotionText}>{value}</div>
        </div>
      </div>
    </div>
  );
};

export default PromotionBadge;
