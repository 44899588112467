import { useContext } from 'react';
import { AuthContext } from 'src/context/auth';

const useGetUsername = (): string => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const username = localStorage.getItem('blue_webusername');
  const numericRegex = /^[0-9]+$/;
  if (username && numericRegex.test(username)) {
    return username;
  }

  if (user.last_login_username) {
    return user.last_login_username;
  }

  return '';
};

export default useGetUsername;
