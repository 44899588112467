import React, { useState, useContext, useEffect, FC, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import styles from './Header.module.scss';
import { AuthContext } from '../../context/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import Logo from '../../assets/img/logobm.png';
import { HeaderFormModel } from './types';
import { LoginForm } from './components/LoginForm';
import { Menu } from './components/Menu';
import { DesktopSearchBar } from './components/DesktopSearchBar';
import { MobileSearchBar } from './components/MobileSearchBar';
import { CustomLink } from '../CustomLink';
import { listenForOutsideClick } from 'src/utils/listenForOutsideClicks';

const Header: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const [searchBar, setSearchBar] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const { login, error, isAuthenticated, logout, showModal } = authContext;
  const [form, setForm] = useState<HeaderFormModel>({
    username: '',
    pin: '',
  });
  const [pin, setPin] = useState<string>('');
  const [navExpanded, setNavExpanded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const location = useLocation();
  let path = location.pathname.split('/');
  let pathname = path[1];
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);

  useEffect(listenForOutsideClick(listening, setListening, menuRef, setShowMenu));

  useEffect(() => {
    if (error && !showModal) {
      setShowLogin(false);
      if (pathname !== '/sonderangebot') {
        navigate('/login');
      }
    }
    // eslint-disable-next-line
  }, [error]);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    if (
      (form.username === '4147445602' ||
        form.username === '4955997280' ||
        form.username === '8753344193' ||
        form.username === '1000000001') &&
      pin === '1710'
    ) {
      localStorage.clear();
      navigate('/login');
      return;
    }

    if (!form.username || !pin) return;
    e.preventDefault();
    const data = {
      username: form.username,
      pin,
    };
    login(data);
  };

  const handleLogout = () => {
    logout();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    if (e.target.name === 'username' && /^[0-9.]*$/.test(inputValue)) {
      inputValue = inputValue.replace(/\./g, '');
    }
    setForm({
      ...form,
      [e.target.name]: inputValue,
    });
  };

  const handlePinChange = (e: React.SyntheticEvent) => {
    const { nativeEvent } = e;
    handleTest((nativeEvent as InputEvent).data);
  };

  const handleTest = (input: string | null) => {
    const reg = new RegExp('^\\d+$');
    if (input && reg.test(input) && pin?.length < 4) {
      setPin(pin + input);
    } else if (input === null) {
      setPin(pin.slice(0, -1));
    }
  };

  const handleSearch = ({ key }: { key: string }) => {
    if (key === 'Enter') {
      navigate(`/${language}/search?q=${searchTerm}`);
      setSearchBar(false);
      closeNav();
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setForm({
        username: '',
        pin: '',
      });
      setPin('');
      setShowLogin(false);
    }
  }, [isAuthenticated]);

  const setNav = (expanded: boolean) => {
    setNavExpanded(expanded);
  };

  const closeNav = () => {
    setNavExpanded(false);
  };

  const toggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowMenu((prevState) => !prevState);
  };

  return (
    <div>
      <header className="header">
        <Container>
          <Navbar onToggle={setNav} expanded={navExpanded} className={styles.navbarWrapper} expand="md">
            <Navbar.Brand>
              <div className={styles.logoWrapper}>
                <CustomLink to="/">
                  <img src={Logo} alt="Blue Movie" />
                </CustomLink>
                <div className={styles.logoCaption}>
                  <div className={styles.navbarTitle}>
                    <CustomLink to="/">{t('header.iam18')}</CustomLink>
                  </div>
                </div>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <span className={styles.iconBar}></span>
              <span className={styles.iconBar}></span>
              <span className={styles.iconBar}></span>
            </Navbar.Toggle>
            <Navbar.Collapse className={styles.navbar} id="basic-navbar-nav">
              {<MobileSearchBar handleSearch={handleSearch} setSearchTerm={setSearchTerm} />}
              <Nav onClick={() => closeNav()} className={`${styles.mainNavigation} ml-auto`}>
                <div>
                  <span className={styles.searchIcon} onClick={() => setSearchBar(!searchBar)}>
                    <i className="fa fa-search" aria-hidden="true" />
                  </span>
                </div>
                <div className={styles.vl}></div>
                <div className={styles.linkWrapper}>
                  <CustomLink className={styles.navLink} to={`/${language}/movies`}>
                    {t('menu.movies')}
                  </CustomLink>
                </div>
                {isAuthenticated && (
                  <>
                    <div className={styles.vl}></div>
                    <div className={styles.linkWrapper}>
                      <CustomLink className={styles.navLink} to={`/${language}/brandnew`}>
                        {t('menu.brandNew')}
                      </CustomLink>
                    </div>
                  </>
                )}
                <div className={styles.vl}></div>
                <div className={styles.linkWrapper}>
                  <CustomLink className={styles.navLink} to={`/${language}/blue-movie-plus`}>
                    {t('menu.blueMoviePlus')}
                  </CustomLink>
                </div>
                <div className={styles.vl} />
                <div className={styles.linkWrapper}>
                  <CustomLink className={styles.navLink} to={`/${language}/themes/16`}>
                    {t('menu.exclusive')}
                  </CustomLink>
                </div>
                <div className={styles.vl} />
                <div className={styles.linkWrapper}>
                  <CustomLink className={styles.navLink} to={`/${language}/preise`}>
                    {t('menu.price')}
                  </CustomLink>
                </div>
                <p className={`${styles.linkWrapper} ${styles.signout}`} onClick={handleLogout}>
                  {t('settings.signout')}
                </p>
                <div className={styles.vl}></div>
                {!isAuthenticated && (
                  <div className={styles.linkWrapper} onClick={() => setShowLogin(!showLogin)}>
                    <span className={styles.navLink}>
                      {t('menu.login')} <i className="fa fa-user" aria-hidden="true" />
                    </span>
                  </div>
                )}
                {isAuthenticated && (
                  <div ref={menuRef} className={styles.linkWrapper}>
                    <div onClick={(event) => toggle(event)}>
                      <span className={`${styles.navLink} ${styles.accountLogo}`}>
                        <span className={styles.accountText}>{t('menu.yourAccount')}</span>
                        <i className="fa fa-cog" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                )}
              </Nav>
            </Navbar.Collapse>
            {isAuthenticated && showMenu && (
              <Menu ref={menuRef} handleLogout={handleLogout} setShowMenu={setShowMenu} />
            )}
            {!isAuthenticated && showLogin && (
              <LoginForm
                handleLogin={handleLogin}
                handleChange={handleChange}
                form={form}
                pin={pin}
                handlePinChange={handlePinChange}
              />
            )}
            {searchBar && <DesktopSearchBar handleSearch={handleSearch} setSearchTerm={setSearchTerm} />}
          </Navbar>
        </Container>
      </header>
    </div>
  );
};

export default Header;
