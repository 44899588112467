import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './Protection.module.scss';
import Spinner from '../../../shared/Spinner/Spinner';
import { useStaticPageQuery } from 'src/hooks/query';
import {generateImageUrl} from "../../../utils/cdnService";
const ProtectionPage: FC = () => {
  const { html, isLoading } = useStaticPageQuery('protection of minors');

  if (isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <div dangerouslySetInnerHTML={html} />
        </Col>
        <Col className={styles.bottomLink} sm={12} style={{ textAlign: 'center' }}>
          <a
            href="https://www.jugendschutzprogramm.de"
            target="_blank"
            rel="noopener noreferrer"
            title="Gehe zu: www.jugendschutzprogramm.de"
          >
            <img
              className="button button-tworows"
              src={generateImageUrl('gcore', 0, 0, '/old_images/jusprog-age-de-icon-18.png?v=59')}
              alt="jugendschutzprogramm.de"
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default ProtectionPage;
