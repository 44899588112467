import React, { FC } from 'react';
import styles from './TermsAndConditionsComponent.module.scss';
import { useTranslation } from 'react-i18next';

interface TermsAndConditionsI {
  termsAndConditions: boolean;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TermsAndConditionsComponent: FC<TermsAndConditionsI> = ({ termsAndConditions, handleCheckboxChange }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <div className={styles.termsAndConditionsCheckbox}>
      {language === 'de' ? (
        <div className={styles.termsAndConditionsText}>
          <span>{t('bmPlusPage.termsAndConditions1')}</span>{' '}
          <a href={`https://bluemovie.com/${language}/agb`} rel="noopener noreferrer" target="_blank">
            {'AGBs'}
          </a>
          <span>{t('bmPlusPage.termsAndConditions2')}</span>
        </div>
      ) : (
        <div className={styles.termsAndConditionsText}>
          <span>{t('bmPlusPage.termsAndConditions1')}</span>{' '}
          <a href={`https://bluemovie.com/${language}/agb`} rel="noopener noreferrer" target="_blank">
            {'T&C’s'}
          </a>
        </div>
      )}
      <input
        type="checkbox"
        name="termsAndConditions"
        value="agree"
        checked={termsAndConditions}
        onChange={handleCheckboxChange}
      />
    </div>
  );
};

export default TermsAndConditionsComponent;
