import Pusher from 'pusher-js';
import Channel from 'pusher-js/types/src/core/channels/channel';
import { PUSHER_ENV } from '../core/constants/constants';

class PusherSingleton {
  private static instance: PusherSingleton;
  private _pusher!: Pusher;
  private _channels: { [channelName: string]: Channel } = {};

  private constructor() {
    if (!PusherSingleton.instance) {
      this._pusher = new Pusher(`${PUSHER_ENV}`, {
        cluster: 'eu',
      });
      PusherSingleton.instance = this;
    }

    return PusherSingleton.instance;
  }

  public static getInstance(): PusherSingleton {
    if (!PusherSingleton.instance) {
      PusherSingleton.instance = new PusherSingleton();
    }

    return PusherSingleton.instance;
  }

  public getPusher(): Pusher {
    return this._pusher;
  }

  public subscribe(channelName: string): Channel {
    if (this._channels[channelName]) {
      return this._channels[channelName];
    }

    const channel = this._pusher.subscribe(channelName);
    this._channels[channelName] = channel;

    return channel;
  }

  public unsubscribe(channelName: string): void {
    if (!this._channels[channelName]) {
      return;
    }

    this._pusher.unsubscribe(channelName);
    delete this._channels[channelName];
  }
}

export default PusherSingleton;
