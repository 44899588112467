import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { StudiosModel } from 'src/pages/movies/types';
import { fetch as fetchRedirect } from 'src/utils/refetch';

type PurchasedContentQueryModel = {
  studios: StudiosModel[];
  areStudiosLoading: boolean;
};

const usePurchasedContentStudiosQuery = (): PurchasedContentQueryModel => {
  const { data, isLoading } = useQuery({
    queryKey: ['purchased_content_studios'],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/user/purchased/content/studios'));
      if (res.response.ok) {
        return res.body?.data;
      }
    },
  });

  const studios = data;
  const areStudiosLoading = isLoading;

  return { studios, areStudiosLoading };
};

export default usePurchasedContentStudiosQuery;
