import { useMutation } from '@tanstack/react-query';
import { EmailDataModel } from '../types';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { createUrl } from 'src/core/api/utils/createUrl';

const useEmailMutation = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: EmailDataModel) => {
      const response = await fetchRedirect(createUrl(`/user/cancel/ticket`), {
        method: 'POST',
        body: JSON.stringify(data),
      });

      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
  });
  return { mutate, isLoading };
};

export default useEmailMutation;
