import React, { FC, Suspense } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { MoviesList } from 'src/components/MoviesList';
import { CustomSpinner } from 'src/shared/Spinner';
import { BmPlusMetaModel, BmPlusMovieModel } from '../../query/types';
import { URLQueryStateModel } from '../../types';
import styles from './PaginationContainer.module.scss';

type Props = {
  isBmPlusQueryLoading: boolean;
  bmPlusMovies: BmPlusMovieModel[];
  bmPlusMeta: BmPlusMetaModel;
  handlePageChange: (selectedItem: { selected: number }) => void;
  query: URLQueryStateModel;
};

const PaginationContainer: FC<Props> = ({
  query,
  isBmPlusQueryLoading,
  bmPlusMovies,
  bmPlusMeta,
  handlePageChange,
}) => {
  const { t } = useTranslation();
  const renderMovieList = () => {
    if (isBmPlusQueryLoading) {
      return <CustomSpinner />;
    }
    return <MoviesList data={bmPlusMovies} />;
  };

  const renderPagination = () => {
    if (bmPlusMeta && !isBmPlusQueryLoading) {
      return (
        <ReactPaginate
          previousLabel={<i className="fa fa-chevron-left" />}
          nextLabel={<i className="fa fa-chevron-right" />}
          breakLabel={'...'}
          breakClassName={styles.breakMe}
          pageCount={bmPlusMeta.last_page}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          forcePage={(query.page as number) - 1}
          containerClassName={'pagination'}
          activeClassName={styles.active}
          previousClassName={query.page === 1 ? `${styles.hideButton}` : `${styles.showButton}`}
          nextClassName={
            bmPlusMeta && query.page === bmPlusMeta.last_page ? `${styles.hideButton}` : `${styles.showButton}`
          }
        />
      );
    }
    return null;
  };
  return (
    <Suspense fallback={<CustomSpinner />}>
      <Row>
        <Col sm={{ span: 6 }}>
          <h2>{t('bmPlusPage.title')}</h2>
        </Col>

        <Col sm={{ span: 6 }}>
          <div className={styles.paginationWrapper}>
            <div className={styles.pagination}>{renderPagination()}</div>
          </div>
        </Col>
      </Row>
      <Row>{renderMovieList()}</Row>

      <Row>
        <Col sm={{ span: 9, offset: 3 }}>
          <div className={styles.paginationWrapper}>
            <div className={styles.pagination}>{renderPagination()}</div>
          </div>
        </Col>
      </Row>
    </Suspense>
  );
};

export default PaginationContainer;
