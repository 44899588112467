import React, { useContext, useState } from 'react';
import { queryClient } from 'src/core/root/components/ReactQueryProvider/ReactQueryProvider';
import styles from './Wishlist.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CustomSpinner } from '../../shared/Spinner';
import { Balance } from '../../components/Balance';
import { useTranslation } from 'react-i18next';
import { BigButton } from '../../components/UI/BigButton';
import { Player } from '../../components/UI/Player';
import { AuthContext } from '../../context/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { PinConfirmationModal } from '../../components/UI/Modals/PinConfirmationModal';
import { OrderModal } from '../../components/UI/Modals/OrderModal';
import ReactPaginate from 'react-paginate';
import { useBookmarksQuery } from './query';
import { WishlistBookmarksModel } from './query/types';
import { useBookmarksDeleteMutation, useMoviePurchaseMutation } from './mutation';
import { URLWishlistQueryStateModel } from './types';
import {generateImageUrl} from "../../utils/cdnService";

const Wishlist = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, user, updateUser } = authContext;
  const [playerStatus, setPlayerStatus] = useState<boolean>(false);
  const [orderVisibility, setOrderVisibility] = useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState<string>('');
  const [selectedPrice, setSelectedPrice] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [selectedMovie, setSelectedMovie] = useState<WishlistBookmarksModel>();
  const [cfMediaID, setCfMediaID] = useState<string>('');
  const [query, setQuery] = useState<URLWishlistQueryStateModel>({
    page: 1,
  });
  const navigate = useNavigate();

  const { bookmarksData, bookmarksMeta, isBookmarksQueryLoading, refetchBookmarksQuery } = useBookmarksQuery(query);
  const { mutate } = useBookmarksDeleteMutation(query);
  const { mutateMoviePurchase } = useMoviePurchaseMutation();

  const handleDelete = async (id: number) => {
    mutate(id);
  };

  const renderDownloadHD = (movie: WishlistBookmarksModel) => {
    if (movie['download-hd'].price !== 0) {
      return (
        <BigButton
          sm={6}
          md={user.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
          // onClick={() => setOrderVisibility(true)}
          onClick={() => {
            setOrderVisibility(true);
            setSelectedMovie(movie);
            setSelectedButton('hd');
            setSelectedPrice(movie['download-hd'].price);
          }}
          icon="fa fa-download"
          text={`Kaufen (HD) FüR ${movie['download-hd'].price?.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
          })}`}
        />
      );
    } else {
      return (
        <BigButton
          active
          sm={6}
          md={user.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
          onClick={() => {
            setShow(true);
            setSelectedButton('hd');
            setSelectedMovie(movie);
          }}
          icon="fa fa-download"
          text={`Download (HD) FüR ${movie['download-hd'].hours_to_expire}`}
        />
      );
    }
  };

  const renderBmButton = (movie: WishlistBookmarksModel) => {
    if (movie && movie.banner === 'blue-movie-plus' && !user.subscribed) {
      return (
        <BigButton
          sm={6}
          md={4}
          share
          onClick={() =>
            navigate(`/${language}/blue-movie-plus`, {
              state: { location: 'singleMovie' },
            })
          }
          icon="fa fa-share"
          text="Blue Movie Plus kaufen"
        />
      );
    }
  };

  const renderStreamButton = (movie: WishlistBookmarksModel) => {
    if (movie && movie.stream?.license_type === 'subscription-stream') {
      return (
        <BigButton
          active
          md={user.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
          sm={6}
          onClick={() => {
            setShow(true);
            setSelectedButton('play');
            setSelectedMovie(movie);
          }}
          icon="fa fa-play-circle"
          text="Film ansehien"
        />
      );
    } else {
      if (movie.stream?.price === 0 && movie.stream.hours_to_expire) {
        return (
          <BigButton
            active
            md={user.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
            sm={6}
            onClick={() => {
              setShow(true);
              setSelectedButton('play');
              setSelectedMovie(movie);
            }}
            icon="fa fa-play-circle"
            text={`Ausgeliehen FüR ${movie.stream.hours_to_expire}`}
          />
        );
      }
      const formattedPrice = movie.stream.price?.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
      return (
        <BigButton
          active={movie.stream.price === 0 ? true : false}
          md={user.subscribed === false && movie.banner === 'blue-movie-plus' ? 4 : 4}
          sm={6}
          onClick={() => {
            movie.stream.price === 0 ? setShow(true) : setOrderVisibility(true);
            setSelectedButton('play');
            setSelectedMovie(movie);
            setSelectedPrice(movie.stream.price);
          }}
          icon="fa fa-play-circle"
          text={movie.stream?.welcome_bonus ? 'Jetzt gratis Abspielen' : `LEIHEN FüR ${formattedPrice} / 48 STD.`}
        />
      );
    }
  };

  const renderMenu = (movie: WishlistBookmarksModel) => {
    if (!isBookmarksQueryLoading && movie && isAuthenticated && !loading) {
      return (
        <Col xs={12}>
          <Row>
            {movie && movie.stream && renderStreamButton(movie)}
            {movie && movie.stream && renderDownloadHD(movie)}
            {movie && movie.stream && renderBmButton(movie)}
          </Row>
        </Col>
      );
    }
  };
  const handleClose = () => setShow(false);

  const handlePlayMovie = async (movie: WishlistBookmarksModel) => {
    let data: any = {};

    if (selectedButton === 'hd') {
      data = {
        price: movie['download-hd'].price,
        license_type: 'download-hd',
        content_name: movie.title,
      };
    } else if (selectedButton === 'sd') {
      data = {
        price: movie.download.price,
        license_type: 'download',
        content_name: movie.title,
      };
    } else if (selectedButton === 'play') {
      data = {
        price: movie.stream.price,
        license_type: movie.stream.license_type,
        content_name: movie.title,
      };
      if (movie.stream?.welcome_bonus) {
        data.welcome_bonus = movie.stream?.welcome_bonus;
      }
      if (movie.stream?.real_price) {
        data.real_price = movie.stream?.real_price;
      }
    }

    mutateMoviePurchase(
      { movieId: movie.id, data: data },
      {
        onSuccess(data) {
          setOrderVisibility(false);
          setShow(false);
          refetchBookmarksQuery();
          if (selectedButton === 'sd' || selectedButton === 'hd') {
            updateUser({
              balance: data.data.balance,
            });

            document.location.href = `${data.data.url}`;
          } else {
            updateUser({
              balance: data.data.balance,
            });
            setCfMediaID(data.data.url);
            setPlayerStatus(true);
          }
          queryClient.invalidateQueries({ queryKey: [`movies_${movie.id}`] });
        },
      }
    );
  };
  const renderStream = () => {
    if (!isBookmarksQueryLoading && bookmarksData && isAuthenticated && playerStatus) {
      return (
        <Player banner={selectedMovie?.banner} onHide={onHide} id={selectedMovie?.id} controls muted src={cfMediaID} />
      );
    }
  };
  const onHide = () => {
    setPlayerStatus(false);
  };

  const renderVideos = () => {
    if (isBookmarksQueryLoading) {
      return <CustomSpinner />;
    } else if (bookmarksData && bookmarksData?.length === 0) {
      return <h1>No data</h1>;
    } else {
      return (
        bookmarksData &&
        bookmarksData?.length > 0 &&
        bookmarksData.map((video) => {
          return (
            <Col sm="12" className={styles.movieWrapper} key={video.id}>
              <Row className={styles.movie}>
                <Col sm="3">
                  <figure className={styles.movieFigure}>
                    <img className="img-responsive" src={generateImageUrl('gcore', 0, 0, video.image)} alt="bookmark" />
                  </figure>
                </Col>
                <Col sm="9">
                  <Row>
                    <Col lg="7" md="6" sm="12" xs="12">
                      <h2 onClick={() => navigate(`/${language}/movie/${video.id}`)} className={styles.headline}>
                        {video.title}
                      </h2>
                    </Col>
                    <Col lg="3" md="3" sm="6" xs="12">
                      <div className={styles.headerButtons}>
                        <button onClick={() => handleDelete(video.id)}>
                          <i className="fa fa-trash" aria-hidden="true"></i>
                          {t('wishListPage.removeMovie')}
                        </button>
                      </div>
                    </Col>
                    <Col lg="2" md="3" sm="6" xs={false}>
                      <div className={styles.headerButtons}>
                        <button onClick={() => navigate(`/${language}/movie/${video.id}`)}>
                          <i className="fa fa-film" aria-hidden="true"></i>
                          {t('wishListPage.goToMovie')}
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <div className={styles.info}>
                        <span className={styles.infoLabel}>{t('wishListPage.label')}</span>
                        <span className={styles.infoValue}> {video.label}</span>
                      </div>
                      <p className="mt-15">{video.description}</p>
                    </Col>
                    {renderMenu(video)}
                  </Row>
                </Col>
              </Row>
            </Col>
          );
        })
      );
    }
  };

  const renderPagination = () => {
    if (bookmarksMeta && !isBookmarksQueryLoading) {
      return (
        <ReactPaginate
          previousLabel={<i className="fa fa-chevron-left"></i>}
          nextLabel={<i className="fa fa-chevron-right"></i>}
          breakLabel={'...'}
          breakClassName={styles.breakMe}
          pageCount={bookmarksMeta && bookmarksMeta.last_page}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          forcePage={(query.page as number) - 1}
          containerClassName={'pagination'}
          activeClassName={styles.active}
          previousClassName={query.page === 1 ? `${styles.hideButton}` : `${styles.showButton}`}
          nextClassName={
            bookmarksMeta && query.page === bookmarksMeta.last_page ? `${styles.hideButton}` : `${styles.showButton}`
          }
        />
      );
    }
    return null;
  };

  const handlePageChange = (data: { selected: number }) => {
    const selectedPage = data.selected + 1;
    setQuery({
      ...query,
      page: selectedPage,
    });
  };

  return (
    <Container>
      <div className={styles.pageHeader}>
        <Balance />
      </div>
      <h2>{t('wishListPage.title')}</h2>
      <Row>
        <Col sm={{ span: 9, offset: 3 }}>
          <div className={styles.paginationWrapper}>
            <div className={styles.pagination}>{renderPagination()}</div>
          </div>
        </Col>
      </Row>

      <Row>{renderVideos()}</Row>

      <Row>
        <Col sm={{ span: 9, offset: 3 }}>
          <div className={styles.paginationWrapper}>
            <div className={styles.pagination}>{renderPagination()}</div>
          </div>
        </Col>
      </Row>

      <Row>
        <PinConfirmationModal
          selectedButton={selectedButton}
          handleSuccess={() => handlePlayMovie(selectedMovie as WishlistBookmarksModel)}
          centered={true}
          show={show}
          onHide={handleClose}
        />
        <OrderModal
          selectedButton={selectedButton}
          balance={user && (user.balance?.toFixed(2) as string)}
          location={location.pathname}
          price={selectedPrice}
          handleSuccess={() => handlePlayMovie(selectedMovie as WishlistBookmarksModel)}
          centered={true}
          show={orderVisibility}
          onHide={() => setOrderVisibility(false)}
        />
      </Row>

      {bookmarksData && cfMediaID && renderStream()}
    </Container>
  );
};

export default Wishlist;
