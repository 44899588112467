import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';

const useDurationMutation = (id: number, contentType: 'svod' | 'tvod') => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const response = await fetchRedirect(createUrl(`/svod/${id}/duration?type=${contentType}`), {
        method: 'POST',
        body: JSON.stringify({
          duration: 10,
        }),
      });
      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
  });

  return { mutate, isLoading };
};

export default useDurationMutation;
