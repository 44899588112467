import { useQuery } from '@tanstack/react-query';

import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';

type VrMoviesQueryModel = {
  vrMoviesList: any;
  isVrMoviesQueryLoading: boolean;
};

const useVrMoviesQuery = (query: { page: number }, isAuthenticated: boolean): VrMoviesQueryModel => {
  const { data, isLoading } = useQuery({
    queryKey: ['vr_movies', isAuthenticated ? `${Object.values(query)}-hard` : `${Object.values(query)}-soft`],
    queryFn: async () => {
      const res = await fetchRedirect(createUrl(`/movies/rv`, query));
      if (!res.response.ok) {
        return res.body;
      } else {
        return res.body;
      }
    },
  });
  const isVrMoviesQueryLoading = isLoading;
  const vrMoviesList = (data as any)?.data;
  return { isVrMoviesQueryLoading, vrMoviesList };
};

export default useVrMoviesQuery;
