import React, { FC } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styles from './LoginButton.module.scss';

type Props = {
  loading: boolean;
  handleLogin: (e: any) => void;
  disabled?: boolean;
};

const LoginButton: FC<Props> = ({ disabled, handleLogin, loading }) => {
  return (
    <button disabled={disabled} className={styles.loginButton} onClick={(e) => handleLogin(e)}>
      {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <span>Absenden</span>}
    </button>
  );
};

export default LoginButton;
