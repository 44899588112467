import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './VrMoviesPage.module.scss';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { CustomSpinner } from '../../shared/Spinner';
import { MoviesList } from '../../components/MoviesList';
import { MainSlider } from '../../components/MainSlider';
import { useTranslation } from 'react-i18next';
import { BannersList } from '../../components/Banners';
import { useBannersQuery } from '../blueMoviePlus/query';
import { AuthContext } from 'src/context/auth';
import { useVrMoviesQuery } from './query';
import {generateImageUrl} from "../../utils/cdnService";


const VrMoviesPage = () => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;
  const { t } = useTranslation();
  const { banners } = useBannersQuery(4, isAuthenticated);
  const [query] = useState<{ page: number }>({
    page: 1,
  });
  const { vrMoviesList, isVrMoviesQueryLoading } = useVrMoviesQuery(query, isAuthenticated);

  const renderMovieList = () => {
    if (isVrMoviesQueryLoading) {
      return <CustomSpinner />;
    }
    return <MoviesList data={vrMoviesList} />;
  };
  const renderBanners = () => {
    if (banners && banners?.length === 1) {
      return (
        <div className={styles.headerImage}>
          <img src={generateImageUrl('gcore',0,0,banners[0].image)} alt="Blue Movie Neustarts" />
        </div>
      );
    } else if (banners && banners?.length > 1) {
      return <MainSlider images={banners} />;
    }
  };
  return (
    <Container>
      <Row>
        <Col sm={12}>
          {renderBanners()}
          {banners && (
            <BannersList banners={banners} alt="vr-movies-img" />
          )}
        </Col>
      </Row>
      <h2>{t('vrPage.title')}</h2>
      <Row>{renderMovieList()}</Row>
      <Row>
        <Col>
          <p>{t('vrPage.instructions')}</p>
          <Accordion defaultActiveKey="0">
            <Card className={styles.card}>
              <Accordion.Item as={Card.Header} eventKey="0">
                <Accordion.Header>Oculus Rift</Accordion.Header>
                <Accordion.Body className={styles.cardBody}>
                  <Card.Body>
                    <ol>
                      <li>Installieren Sie "Deo VR" aus dem Oculus Store.</li>
                      <li>Laden Sie ein Video von Bluemovie.net auf Ihren Computer herunter.</li>
                      <li>Wählen Sie das heruntergeladene Video aus und los geht’s!</li>
                      <li>Stellen Sie sicher, dass Sie 180° Side-By-Side Video verwenden.</li>
                    </ol>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Card>
            <Card className={styles.card}>
              <Accordion.Item as={Card.Header} eventKey="1">
                <Accordion.Header>HTC Vive</Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <ol>
                      <li>Installieren Sie "Deo VR" von deovr.com.</li>
                      <li>Laden Sie ein Video von Bluemovie.net auf Ihren Computer herunter.</li>
                      <li>Wählen Sie das heruntergeladene Video aus und los geht’s!</li>
                      <li>Stellen Sie sicher, dass Sie 180° Side-By-Side Video verwenden.</li>
                    </ol>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Card>
            <Card className={styles.card}>
              <Accordion.Item as={Card.Header} eventKey="2">
                <Accordion.Header>PlayStation VR</Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <ol>
                      <li>Installieren Sie "Littlstar" aus dem PlayStation Store.</li>
                      <li>Laden Sie ein Video von Bluemovie.net auf Ihren Computer herunter.</li>
                      <li>
                        Formatieren Sie einen USB Stick mit exFAT und erstellen Sie einen Ordner namens LITTLSTAR.
                      </li>
                      <li>Kopieren Sie das Video von Ihrem Computer in den LITTLSTAR Ordner.</li>
                      <li>Schließen Sie den USB Stick an die PlayStation an.</li>
                      <li>Starten Sie die Littlstar Anwendung, wähle das Video aus der Mediathek und los geht’s!</li>
                    </ol>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Card>
            <Card className={styles.card}>
              <Accordion.Item as={Card.Header} eventKey="4">
                <Accordion.Header>Samsung Gear VR</Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <ol>
                      <li>Installieren Sie "SamsungVR" von der Oculus App.</li>
                      <li>Laden Sie ein Video von Bluemovie.net auf Ihren Computer herunter.</li>
                      <li>
                        Verbinden Siew Ihr Smartphone via USB mit Ihrem Computer und kopieren Sie die Datei in einen
                        dieser Ordner:
                        <strong> \Card\Movies oder \Phone\Movies </strong>. Ihr Computer bietet Ihnen die Option, die
                        Datei zu kopieren und zu konvertieren oder nur zu kopieren. Wählen Sie <em>nur kopieren</em>{' '}
                        aus.
                      </li>
                      <li>
                        Schieben Sie Ihr Smartphone in das Gear VR Headset und starten Sie die Samsung VR App aus Oculus
                        Home heraus. Wählen Sie in der Samsung VR Anwendung das <em>Gerät</em> und dann{' '}
                        <em>Sideloaded</em> und wähle das Video aus, welches Sie sehen und genießen möchten!
                      </li>
                    </ol>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Card>
            <Card className={styles.card}>
              <Accordion.Item as={Card.Header} eventKey="5">
                CardBoard
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <Row>
                      <Col sm={6}>
                        <ol>
                          <h3>ANDROID</h3>
                          <li>Installieren Sie einen VR Player auf Ihr Smartphone. Wir empfehlen VRTV.</li>
                          <li>
                            Laden Sie ein Video von Bluemovie.net herunter. Wählen Sie die passende Video-Datei,
                            entweder 30fps oder 60fps in Abhängigkeit von der auf Ihrem Smartphone genutzten
                            Android-Version. Das Herunterladen kann einige Minuten dauern und ist abhängig von der
                            Geschwindigkeit Deiner Internetverbindung.
                          </li>
                          <li>
                            Verbinden Sie Ihr Smartphone via USB mit Ihrem Computer und übertragen Sie die
                            heruntergeladenen VR Videos auf Ihr Smartphone.
                          </li>
                          <li>
                            Öffnen Sie auf Ihrem Smartphone die VRTV App und wählen Sie das Video aus, welches Sie auf
                            Ihrem Smartphone kopiert haben. Sobald das Video startet, schieben Sie Ihr Smartphone in
                            Ihre VR-Brille und los geht’s!
                          </li>
                          <li>
                            <strong>Für das beste Erlebnis empfehlen wir folgende Einstellungen:</strong>
                            <br />
                            <strong>Screen:</strong> Dome 180
                            <br />
                            <strong>Video Type:</strong> 3D SBS
                            <br />
                            <strong>Projection:</strong> Normal
                            <br />
                            <strong>VR Mode:</strong> On
                          </li>
                        </ol>
                      </Col>
                      <Col sm={6}>
                        <ol className="vr-ios">
                          <h3>IOS</h3>
                          <li>Installieren Sie einen VR Player auf Ihrem Smartphone. Wir empfehlen FreeVRPlayer.</li>
                          <li>
                            <strong>Laden Sie ein Video von Bluemovie.net herunter.</strong> Sie können zwischen 30fps
                            und 60fps wählen. Letzteres ermöglicht sanfteres Headtracking, aber die Datei ist größer.
                            Das Herunterladen kann einige Mintuen dauern und ist abhängig von der Geschwindigkeit Deiner
                            Internetverbindung. VR Video-Dateien sind sehr groß.
                          </li>
                          <li>
                            <strong>Verbinden Sie Ihr Smartphone via USB mit Ihrem Computer</strong> und kopieren Sie
                            die Datei mit iTunes auf Ihr iPhone.
                          </li>
                          <li>
                            <strong>Wie sie die Video-Dateien auf Ihr iPhone kopieren:</strong>
                          </li>
                          <ul>
                            <li>Wählen Sie die VR Player App in der Liste der Dateifreigabe in iTunes aus.</li>
                            <li>
                              Ziehen Sie die Dateien aus einem Ordner oder Fenster in die Dokumentenliste, um sie zur
                              FreeVRPlayer App auf Ihrem Gerät zu kopieren.
                            </li>
                            <li>
                              Alternativ klicksten Sie auf <em>Hinzufügen</em>, wählsten Sie die Datei(en), die Sie von
                              Ihrem Computer kopieren möchten und klicken auf <em>Auswählen</em>. iTunes kopiert diese
                              Dateien zur FreeVRPlayer App auf Ihr Gerät.
                            </li>
                            <li>
                              Auf Ihrem iPhone öffnen Sie die FreeVRPlayer App, wählen Sie das Video aus, welches Sie
                              abspielen möchtest und sobald das Video startet, schieben Sie Ihr Smartphone in die
                              VR-Brille und los geht’s!
                            </li>
                          </ul>
                        </ol>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Accordion.Item>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default VrMoviesPage;
