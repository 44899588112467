
import {BASE_GCORE_IMG_URL, BASE_IMG_URL} from 'src/core/constants/constants';

const cdnMapping = {
    gcore: BASE_GCORE_IMG_URL,
    cf: BASE_IMG_URL
}


export const generateImageUrl = (cdnType: 'gcore' | 'cf', width: number, height: number, imagePath: string | undefined) => {
    return getFullImageUrl(cdnType, width, height, imagePath)
}

const getFullImageUrl = (cdnType: 'gcore' | 'cf', width: number, height: number, imagePath: string | undefined) => {
    // @ts-ignore
    const cdnBaseUrl: string = cdnMapping[cdnType]
    const validatedImagePath = imagePath && !imagePath?.startsWith('/') ? `/${imagePath}` : imagePath;
    if (width && height) {
        if (cdnType === 'gcore') {
            return `${cdnBaseUrl}${validatedImagePath}?fit=cover&width=${width}&height=${height}`;

        } else {
            return `${cdnBaseUrl}/cdn-cgi/image/fit=cover,width=${width},height=${height}${validatedImagePath}`;

        }
    }

    return cdnType === 'gcore' ? `${cdnBaseUrl}${validatedImagePath}` : cdnBaseUrl;


}
