import React, { useEffect, useState, useContext, FC } from 'react';
import { Stream } from '@cloudflare/stream-react';
import style from './Player.module.scss';
import { PlayerContext } from '../../../context/player';
import { AuthContext } from '../../../context/auth';
import { useDurationMutation } from './mutation';
import VideoJS from '../../Player/Player';

type Props = {
  src: string;
  onHide: () => void;
  id: number | undefined;
  banner: string | undefined;
  controls?: boolean;
  muted?: boolean;
  poster?: string;
};

const Player: FC<Props> = ({ src, poster, onHide, id, banner, controls = false, muted = false }) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const INTERVAL_MS = 10000;
  const [isPaying, setIsPlaying] = useState(false);
  const { startPlayer, pausePlayer, stopPlayer } = useContext(PlayerContext);
  const { mutate } = useDurationMutation(
    id as number,
    user?.subscribed && banner === 'blue-movie-plus' ? 'svod' : 'tvod'
  );

  useEffect(() => {
    let interval: NodeJS.Timer;
    // todo: this is not good condition for checking if user is watching subscribed content
    if (isPaying) {
      interval = setInterval(async () => {
        fetchTime('svod');
      }, INTERVAL_MS);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaying]);

  const fetchTime = (typeOfContent: 'svod' | 'tvod') => {
    if (isPaying) {
      mutate();
    }
  };

  useEffect(() => {
    return () => {
      setIsPlaying(false);
      stopPlayer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlay = React.useCallback(() => {
    startPlayer();
    setIsPlaying(true);
  }, []);

  const handlePause = React.useCallback(() => {
    pausePlayer();
    setIsPlaying(false);
  }, []);

  const handleHide = () => {
    stopPlayer();
    onHide();
  };

  const videoJsOptions = React.useMemo(() => {
    return {
      autoplay: false,
      controls: true,
      responsive: true,
      preload: true,
      fluid: true,
      sources: [
        {
          src,
          type: 'application/vnd.apple.mpegurl',
        },
      ],
    };
  }, [src]);

  function isUrl(src: string) {
    try {
      new URL(src);
      return true;
    } catch (e) {
      return false;
    }
  }
  return (
    <div className={style.overlay}>
      <div className={style.player}>
        <i onClick={handleHide} className="fa fa-times"></i>
        {isUrl(src) ? (
          <VideoJS options={videoJsOptions} onPlay={handlePlay} onPause={handlePause} />
        ) : (
          <Stream
            onPause={handlePause}
            onPlaying={handlePlay}
            controls={controls}
            muted={muted}
            src={src}
            poster={poster}
          />
        )}
      </div>
    </div>
  );
};

export default Player;
