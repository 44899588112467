import React, {FC, forwardRef} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Menu.module.scss';
import { CustomLink } from 'src/components/CustomLink';

type Props = {
  handleLogout: () => void;
  setShowMenu: (value: boolean) => void;
};

const Menu = forwardRef<HTMLDivElement, Props>(({handleLogout, setShowMenu }, ref)  => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <div ref={ref} className={styles.menuWrapper} onClick={() => setShowMenu(false)}>
      <CustomLink className={styles.menuItem} to={`/${language}/deposit`}>
        {t('settings.deposit')}
      </CustomLink>
      <CustomLink className={styles.menuItem} to={`/${language}/account`}>
        {t('settings.personalData')}
      </CustomLink>
      <CustomLink className={styles.menuItem} to={`/${language}/devices`}>
        {t('settings.devices')}
      </CustomLink>
      <CustomLink className={styles.menuItem} to={`/${language}/plus-account`}>
        {t('settings.bmPlusAccount')}
      </CustomLink>
      <CustomLink className={styles.menuItem} to={`/${language}/bought-movies`}>
        {t('settings.myFilms')}
      </CustomLink>
      <CustomLink className={styles.menuItem} to={`/${language}/wishlist`}>
        {t('settings.myWatchList')}
      </CustomLink>
      <p className={`${styles.menuItem} ${styles.signout}`} onClick={handleLogout}>
        {t('settings.signout')}
      </p>
    </div>
  );
});

export default Menu;
