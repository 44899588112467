import { MutableRefObject } from 'react';

export function listenForOutsideClick(
  listening: boolean,
  setListening: (listening: boolean) => void,
  menuRef: MutableRefObject<HTMLElement | null>,
  setIsOpen: (isOpen: boolean) => void
) {
  return () => {
    if (listening) return;
    if (!menuRef.current) return;
    setListening(true);
    [`click`, `touchstart`].forEach((type) => {
      document.addEventListener(type, (evt: Event) => {
        if (menuRef.current?.contains(evt.target as Node)) return;
        setIsOpen(false);
      });
    });
  };
}
