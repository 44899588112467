import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { StudiosModel } from '../types';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { createUrl } from 'src/core/api/utils/createUrl';

interface IStudiosModel {
  studios: StudiosModel[];
  areStudiosLoading: boolean;
  isError: boolean;
  refetchStudios: () => void;
}

const useStudiosQuery = (isAuthenticated?: boolean): IStudiosModel => {
  const [isError, setIsError] = useState<boolean>(false);
  const {
    data,
    isLoading: areStudiosLoading,
    refetch: refetchStudios,
  } = useQuery({
    queryKey: [`studios`, isAuthenticated ? 'hard' : 'soft'],
    queryFn: async () => {
      const response: any = await fetchRedirect(createUrl(`/studios`));
      if (!response.response.ok) {
        setIsError(true);
        return response.body;
      }
      return response.body;
    },
  });
  const studios = data?.data;

  return { studios, areStudiosLoading, isError, refetchStudios };
};

export default useStudiosQuery;
