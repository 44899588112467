import React, { FC } from 'react';
import { Col, Form } from 'react-bootstrap';
import { PaymentModel, PaymentTypesModel } from 'src/pages/blueMoviePlus/query/types';
import { SelectedTickerModel } from 'src/pages/blueMoviePlus/types';
import styles from './RenderPaymentMethods.module.scss';
import { generateImageUrl } from '../../../../../utils/cdnService';

type Props = {
  selectedTicket: SelectedTickerModel;
  payment: PaymentModel;
  selectedType: PaymentTypesModel;
  setSelectedType: (value: PaymentTypesModel) => void;
};
const RenderPaymentMethods: FC<Props> = ({ selectedTicket, payment, setSelectedType, selectedType }) => {
  const renderPayments = () => {
    // daily product
    if (selectedTicket.id === 3) {
      return payment?.payment_types.map((item) => (
        <Col sm={6}>
          <div
            onClick={() => setSelectedType(item)}
            className={
              item.id === selectedType.id ? `${styles.typeWrapper} ${styles.selected}` : `${styles.typeWrapper}`
            }
          >
            <Form.Check
              className={styles.radioButton}
              type="radio"
              onChange={() => setSelectedType(item)}
              checked={item.id === selectedType.id}
            />
            <img alt="cdn-img" src={generateImageUrl('gcore', 0, 0, item.image)} />
          </div>
        </Col>
      ));
      // this reference to yearly product
    } else if (![4, 23, 10].includes(selectedTicket.id)) {
      return payment?.payment_types
        .filter((item) => item.name !== 'MicroPayment Sofort')
        .map((item) => (
          <Col sm={6}>
            <div
              onClick={() => setSelectedType(item)}
              className={
                item.id === selectedType.id ? `${styles.typeWrapper} ${styles.selected}` : `${styles.typeWrapper}`
              }
            >
              <Form.Check
                className={styles.radioButton}
                type="radio"
                onChange={() => setSelectedType(item)}
                checked={item.id === selectedType.id}
              />
              <img alt="cdn-img" src={generateImageUrl('gcore', 0, 0, item.image)} />
            </div>
          </Col>
        ));
      // monthly product
    } else {
      return payment?.payment_types
        .filter((item) => item.name !== 'MicroPayment Sofort')
        .map((item) => (
          <Col sm={6}>
            <div
              onClick={() => setSelectedType(item)}
              className={
                item.id === selectedType.id ? `${styles.typeWrapper} ${styles.selected}` : `${styles.typeWrapper}`
              }
            >
              <Form.Check
                className={styles.radioButton}
                type="radio"
                onChange={() => setSelectedType(item)}
                checked={item.id === selectedType.id}
              />
              <img alt="cdn-img" src={generateImageUrl('gcore', 0, 0, item.image)} />
            </div>
          </Col>
        ));
    }
  };
  return <>{renderPayments()}</>;
};

export default RenderPaymentMethods;
