export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const UPDATE_USER = 'UPDATE_USER';
export const START_PLAYER = 'START_PLAYER';
export const PAUSE_PLAYER = 'PAUSE_PLAYER';
export const STOP_PLAYER = 'STOP_PLAYER';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
