import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { MutateMoviePurchaseData } from './types';

const useMoviePurchaseMutation = () => {
  const { mutate: mutateMoviePurchase, isLoading } = useMutation({
    mutationFn: async (data: MutateMoviePurchaseData) => {
      const response: any = await fetchRedirect(createUrl(`/movies/${data.movieId}/purchase`), {
        method: 'POST',
        body: JSON.stringify(data.data),
      });
      if (!response.ok) {
        return response.body;
      }
      return response.body;
    },
  });
  return { mutateMoviePurchase, isLoading };
};

export default useMoviePurchaseMutation;
