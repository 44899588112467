import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { TicketModel } from './types';

type TicketQueryModel = {
  userTicket: TicketModel;
  isTicketsQueryLoading: boolean;
};

const useTicketsQuery = (): TicketQueryModel => {
  const { data, isLoading } = useQuery({
    queryKey: ['user_ticket'],
    queryFn: async () => {
      const res = await fetchRedirect(createUrl('/user/ticket'));
      if (!res.response.ok) {
        return res.body;
      } else {
        return res.body;
      }
    },
  });
  const isTicketsQueryLoading = isLoading;
  const userTicket = data as any;
  return { isTicketsQueryLoading, userTicket };
};

export default useTicketsQuery;
