import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { MovieDetailModel } from './types';

type MovieQueryModel = {
  movie: MovieDetailModel;
  isMovieLoading: boolean;
  refetchMovieQuery: () => void;
};

const useMovieQuery = (id: string, isAuthenticated: boolean): MovieQueryModel => {
  const navigate = useNavigate();
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: [`movies_${id}`, isAuthenticated ? '-hard' : '-soft'],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl(`/movies/${id}`));
      if (!res.response.ok) {
        navigate('/');
        return res.body;
      }
      return res.body;
    },
  });
  if ((error as { status: number })?.status === 404) {
    navigate('/404');
  }
  const movie = data?.data;
  const isMovieLoading = isLoading;
  const refetchMovieQuery = refetch;
  return { movie, isMovieLoading, refetchMovieQuery };
};

export default useMovieQuery;
