import React, { FC } from 'react';
import styles from './Footer.module.scss';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { CustomLink } from '../CustomLink';

const Footer: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <footer className={styles.footer}>
      <Container>
        <ul className={styles.ul}>
          <li>
            <CustomLink to={`/${language}/impressum`}>{t('footer.imprint')}</CustomLink>
          </li>
          <li>
            <CustomLink to={`/${language}/support`}>{t('footer.support')}</CustomLink>
          </li>
          <li>
            <CustomLink to={`/${language}/support`}>{'Inhalt melden'}</CustomLink>
          </li>
          <li>
            <CustomLink to={`/${language}/jugendschutz`}>{t('footer.youthProtection')}</CustomLink>
          </li>
          <li>
            <CustomLink to={`/${language}/datenschutz`}>{t('footer.privacyPolicy')}</CustomLink>
          </li>
          <li>
            <CustomLink to={`/${language}/agb`}>{t('footer.termsAndConditions')}</CustomLink>
          </li>
          <li>
            <CustomLink to={`/${language}/preise`}>{t('footer.price')}</CustomLink>
          </li>
          <li>
            <CustomLink to={`/${language}/faq`}>FAQ</CustomLink>
          </li>
        </ul>
        <br/>
        <CustomLink className={styles.statement} to={`/${language}/18-U-S-C`}>
          18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement
        </CustomLink>
        <div className={styles.buttonTop}>
          <button onClick={scrollTop}>
            <i className="fa fa-chevron-up"></i>
          </button>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
