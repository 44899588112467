import React, { CSSProperties, FC } from 'react';
import styles from './Spinner.module.scss';
import Spinner from 'react-bootstrap/Spinner';

type Props = {
  overlay?: boolean;
};
const CustomSpinner: FC<Props> = ({ overlay = false }) => {
  const customOverlay: CSSProperties = overlay
    ? {
        background: 'rgba(0,0,0, .2)',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
      }
    : {};
  return (
    <div style={customOverlay} className={styles.spinner}>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};

export default CustomSpinner;
