import React, { Suspense, useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { CustomSpinner } from '../../shared/Spinner';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import qs from 'qs';
import { BannersList } from '../../components/Banners';
import { PaymentModal } from '../../components/UI/Modals/PaymentModal';
import { useBannersQuery, useBmPlusMoviesQuery, usePaymentQuery, useProductsQuery } from './query';
import { SelectedTickerModel, URLQueryStateModel } from './types';
import { PaymentTypesModel } from './query/types';
import { usePaymentInitMutation, usePaymentMutation } from './mutation';
import { BlueBoxBanner } from './components/BlueBoxBanner';
import { PaymentMethods } from './components/PaymentMethods';
import { UpsellModal } from './components/UpsellModal';
import { WarningModal } from './components/WarningModal';
import { PaginationContainer } from './components/PaginationContainer';
import { oneClickPayment } from './utils';
import isObjectEmpty from 'src/utils/isObjectEmpty';
import useHandleSepaPayment from '../../hooks/useHandleSepaPayment';

const BlueMoviePlusPage = () => {
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user, isAuthenticated, loadUser } = authContext;
  const location = useLocation();

  const [query, setQuery] = useState<URLQueryStateModel>({
    page: qs.parse(window.location.search, { ignoreQueryPrefix: true }).page || 1,
  });
  const [selectedType, setSelectedType] = useState<PaymentTypesModel>({
    id: -1,
    gateway: '',
    last_id: -1,
  });
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [selectedTicket, setSelectedTicket] = useState<SelectedTickerModel>({ id: 23 });
  const [showMethods, setShowMethods] = useState<boolean>(location?.state?.location === 'singleMovie');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showUpsellModal, setShowUpsellModal] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);

  const { bmPlusMovies, isBmPlusQueryLoading, bmPlusMeta } = useBmPlusMoviesQuery(query, isAuthenticated);
  const { banners, areBannersLoading } = useBannersQuery(2, isAuthenticated);
  const { payment, isPaymentLoading, paymentError } = usePaymentQuery(isAuthenticated);
  const { products, isProductsQueryLoading, refetchProductsQuery } = useProductsQuery(isAuthenticated);
  const { mutate } = usePaymentMutation();
  const { mutate: paymentInitMutate } = usePaymentInitMutation();
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);

  const handleSepaPayment = useHandleSepaPayment(selectedType);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAndConditions(event.target.checked);
  };

  useEffect(() => {
    let queryData = query;
    if (queryData.headers) {
      delete queryData.headers;
    }
    const formatedQuery = qs.stringify(queryData);

    navigate(`?${formatedQuery}`, { replace: true });
  }, [navigate, query]);

  useEffect(() => {
    if (payment?.last_payments?.length > 0) {
      setSelectedType({
        id: 0,
        gateway: payment.last_payments[0].gateway,
        last_id: payment.last_payments[0].id,
      });
    }
  }, [payment?.last_payments]);

  useEffect(() => {
    if (!isObjectEmpty(user) && !user.subscribed) {
      if (products?.trial) {
        setSelectedTicket({ ...products?.trial });
      } else {
        setSelectedTicket({
          ...products?.active_products[products?.active_products?.length === 3 ? 2 : 0],
        });
      }

      try {
        if (products?.active_products?.length > 1) {
          localStorage.setItem(
            'blue_price',
            JSON.stringify(products?.active_products[products?.active_products?.length === 3 ? 2 : 0].price)
          );
        }
      } catch (e) {
        console.error('Error while pre-selecting active product', e);
      }
    }
  }, [products?.active_products, products?.trial, refetchProductsQuery, user]);

  const handlePageChange = (data: { selected: number }) => {
    const selectedPage = data.selected + 1;
    setQuery({
      ...query,
      page: selectedPage,
    });
  };

  const handleSubmit = async () => {
    oneClickPayment(
      setIsLoading,
      setShowPaymentModal,
      selectedType,
      mutate,
      loadUser,
      language,
      navigate,
      location,
      paymentInitMutate,
      selectedTicket.price_id,
      selectedTicket.id
    );
  };

  const toggleMethods = () => {
    if (user && !user.ticket_purchase_enabled) {
      setShowWarningModal(true);
    } else {
      // id=4 month, id=3 day, id=5 month
      if (selectedTicket.id === 4) {
        setShowUpsellModal(true);
        setShowMethods((prev) => !prev);
      }
      setShowMethods((prev) => !prev);
    }
  };

  const handleUpsell = () => {
    setSelectedTicket(products?.active_products[products?.active_products?.length === 3 ? 2 : 1]);
    setShowMethods((prev) => !prev);
    setShowUpsellModal(false);
  };

  const getColumnWidth = () => {
    const totalProductsForDisplay = products?.active_products?.length;
    return totalProductsForDisplay % 2 === 0 ? 6 : 4;
  };
  const isTranslationPresent = () => {
    return products && products.trial && products.trial.asset && products.trial.asset.translation;
  };

  if (
    areBannersLoading ||
    (isAuthenticated && isPaymentLoading) ||
    (isAuthenticated && isProductsQueryLoading) ||
    areBannersLoading
  ) {
    return (
      <Container>
        <CustomSpinner />
      </Container>
    );
  }

  return (
    <Container>
      <PaymentModal
        show={showPaymentModal}
        handleClose={() => setShowPaymentModal(false)}
        amount={selectedTicket?.price as number}
        handlePayment={handleSepaPayment}
      />
      {showWarningModal && (
        <WarningModal
          showWarningModal={showWarningModal}
          onHide={() => setShowWarningModal(false)}
          onClick={() => setShowWarningModal(false)}
        />
      )}
      {showUpsellModal && (
        <UpsellModal
          handleUpsell={handleUpsell}
          showUpsellModal={showUpsellModal}
          onHide={() => setShowUpsellModal(false)}
          handleButtonClick={() => {
            setShowUpsellModal(false);
            setShowMethods((prev) => !prev);
          }}
        />
      )}
      <Suspense fallback={<CustomSpinner />}>
        {!isAuthenticated && banners && <BannersList banners={banners} alt="blue-movie-plus-img" />}
      </Suspense>
      <PaymentMethods
        paymentError={paymentError}
        user={user}
        products={products}
        selectedTicket={selectedTicket}
        setSelectedTicket={setSelectedTicket}
        isTranslationPresent={isTranslationPresent()}
        getColumnWidth={getColumnWidth()}
        showMethods={showMethods}
        toggleMethods={toggleMethods}
        payment={payment}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        termsAndConditions={termsAndConditions}
        handleCheckboxChange={handleCheckboxChange}
      />
      <PaginationContainer
        isBmPlusQueryLoading={isBmPlusQueryLoading}
        bmPlusMovies={bmPlusMovies}
        bmPlusMeta={bmPlusMeta}
        handlePageChange={handlePageChange}
        query={query}
      />
      {!isAuthenticated && <BlueBoxBanner />}
    </Container>
  );
};

export default BlueMoviePlusPage;
