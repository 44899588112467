import React, { FC } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserModel } from 'src/core/shared/types';
import { PaymentModel, PaymentTypesModel, ProductModel } from '../../query/types';
import { SelectedTickerModel } from '../../types';
import { Product } from '../Product';
import styles from './PaymentMethods.module.scss';
import { RenderPaymentMethods } from './utils';
import { generateImageUrl } from '../../../../utils/cdnService';
import { TermsAndConditionsComponent } from '../../../../components/TermsAndConditionsComponent';

type Props = {
  paymentError: boolean;
  user: Partial<UserModel>;
  products: ProductModel;
  selectedTicket: SelectedTickerModel;
  setSelectedTicket: (value: SelectedTickerModel) => void;
  isTranslationPresent: { title: string; price_text: string };
  getColumnWidth: number;
  showMethods: boolean;
  toggleMethods: () => void;
  payment: PaymentModel;
  selectedType: PaymentTypesModel;
  setSelectedType: (value: PaymentTypesModel) => void;
  isLoading: boolean;
  handleSubmit: () => void;
  termsAndConditions: boolean;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const PaymentMethods: FC<Props> = ({
  getColumnWidth,
  isTranslationPresent,
  setSelectedTicket,
  paymentError,
  user,
  products,
  selectedTicket,
  showMethods,
  toggleMethods,
  payment,
  selectedType,
  setSelectedType,
  isLoading,
  handleSubmit,
  termsAndConditions,
  handleCheckboxChange,
}) => {
  const { t,  i18n: { language } } = useTranslation();

  return (
    <Row>
      {!paymentError && !user?.subscribed && products && (
        <Col sm={12}>
          {products.trial && (
            <Row>
              <Col sm={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className={`${styles.bigButton} ${
                    selectedTicket.id === products.trial.id ? styles.trialSelected : null
                  }`}
                  onClick={() => {
                    localStorage.setItem('blue_productPrice', JSON.stringify(products.trial.price));
                    setSelectedTicket(products.trial);
                  }}
                >
                  <img alt="trial-img" src={`${generateImageUrl('gcore', 0, 0, products.trial.asset.image)}`} />
                  {selectedTicket.id === products.trial.id && (
                    <div
                      style={{
                        marginLeft: '2px',
                        transform: 'unset',
                        top: 'unset',
                      }}
                      className={styles.checkMark}
                    >
                      <i className="fa fa-check" aria-hidden="true" />
                    </div>
                  )}
                  <div style={{ padding: '0 50px' }}>
                    <h3 style={{ fontWeight: 400, fontSize: 32, marginBottom: 20 }}>
                      {isTranslationPresent ? products.trial.asset.translation.title.split('für')[0] : ''}
                      <br />
                      <span
                        style={{
                          borderBottom: '3px solid #ad140f',
                        }}
                      >
                        {t('bmPlusPage.for')}{' '}
                        {isTranslationPresent && products.trial.asset.translation.title.split('für')[1]}
                      </span>
                    </h3>
                    <p style={{ fontSize: '0.8rem' }}>
                      {isTranslationPresent ? products.trial.asset.translation.price_text : ''}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={12} style={{ textAlign: 'center', marginTop: 15 }}>
              {products.active_products.some((item) => item.id === 30 || item.id === 23) && (
                <h2>{t('bmPlusPage.standardTickets')}</h2>
              )}
            </Col>
            {products.active_products?.map((product) => {
              return (
                <Product
                  onSelected={(product) => setSelectedTicket(product)}
                  selectedTicket={selectedTicket}
                  columnWidth={getColumnWidth}
                  key={product.id}
                  product={product}
                />
              );
            })}
          </Row>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 30,
            }}
          >
            {!showMethods && (
              <button style={{ margin: '50px 0px' }} className={styles.button} onClick={toggleMethods}>
                {t('bmPlusPage.subscribe').toUpperCase()}
              </button>
            )}
          </div>
          {!showMethods && (
            <Row>
              <hr
                style={{
                  borderColor: 'white',
                  marginBottom: '60px',
                  width: '100%',
                }}
              />
            </Row>
          )}

          <Row>
            {showMethods && (
              <Row style={{ width: '100%' }}>
                {payment.last_payments?.length > 0 && (
                  <Col sm={6}>
                    <div
                      onClick={() => setSelectedType({ ...selectedType, id: 0 })}
                      className={
                        selectedType.id === 0 ? `${styles.typeWrapper} ${styles.selected}` : `${styles.typeWrapper}`
                      }
                    >
                      <Form.Check
                        className={styles.radioButton}
                        type="radio"
                        onChange={() =>
                          setSelectedType({
                            ...selectedType,
                            id: 0,
                          })
                        }
                        checked={selectedType.id === 0}
                      />
                      <Form.Select
                        onChange={(e) => {
                          const gateway = payment.last_payments.filter((item) => item.id === Number(e.target.value))[0]
                            .gateway;

                          setSelectedType({
                            last_id: e.target.value,
                            gateway: gateway,
                            id: 0,
                          });
                        }}
                      >
                        {payment.last_payments.map((p) => (
                          <option value={p.id}>{p.info}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </Col>
                )}

                <RenderPaymentMethods
                  selectedTicket={selectedTicket}
                  payment={payment}
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                />
                <TermsAndConditionsComponent
                  handleCheckboxChange={handleCheckboxChange}
                  termsAndConditions={termsAndConditions}
                />
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '50px 0',
                  }}
                >
                  <button
                    style={{ padding: '8px 50px' }}
                    onClick={handleSubmit}
                    className={styles.button}
                    disabled={isLoading || !termsAndConditions}
                  >
                    {selectedType.id === 0 ? t('bmPlusPage.orderForFee') : t('bmPlusPage.continue')}
                  </button>
                </div>
                <hr
                  style={{
                    borderColor: 'white',
                    marginBottom: '60px',
                    width: '100%',
                  }}
                />
              </Row>
            )}
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default PaymentMethods;
