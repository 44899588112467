import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DepositPage } from 'src/pages/Deposit';
import { DevicesPage } from 'src/pages/Devices';
import { PaymentOverview } from 'src/pages/PaymentOverview';
import { Account } from 'src/pages/account';
import { BoughtMovies } from 'src/pages/boughtMovies';
import { PlusAccountPage } from 'src/pages/plusAccount';
import { UserDetails } from 'src/pages/userDetails';
import { Wishlist } from 'src/pages/wishlist';
import { useTranslation } from 'react-i18next';
import { PageNotFound } from 'src/pages/404';
import { LogoutPage } from 'src/pages/Logout';
import { SpecialPage } from 'src/pages/SpecialPage';
import { BlueMoviePlusPage } from 'src/pages/blueMoviePlus';
import { BrandNewPage } from 'src/pages/brandNew';
import { HomePage } from 'src/pages/home';
import { LoginPage } from 'src/pages/login';
import { MoviesPage } from 'src/pages/movies';
import { MovieDetailsPage } from 'src/pages/movies/movieDetails';
import { MovieByTagPage } from 'src/pages/movies/movieTag';
import { PaymentSuccess } from 'src/pages/payment';
import { SearchPage } from 'src/pages/search';
import { SectionPage } from 'src/pages/section';
import { UscPage } from 'src/pages/static/18usc';
import { CancellationTerms } from 'src/pages/static/cancellationTerms';
import { ContactPage } from 'src/pages/static/contact';
import { DataProtection } from 'src/pages/static/dataProtection';
import { Faq } from 'src/pages/static/faq';
import { ImpressumPage } from 'src/pages/static/impressum';
import { Prices } from 'src/pages/static/prices';
import { ProtectionPage } from 'src/pages/static/protection';
import { TermsAndConditions } from 'src/pages/static/termsConditions';
import { UseEU } from 'src/pages/static/useEu';
import { VrMoviesPage } from 'src/pages/vrMovies';
import { PrivateRoute } from './utils';
import { LoginAsUser } from '../pages/loginAsUser';

const RoutesList: FC = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path={`/${language}/movies/`} element={<MoviesPage />} />
      <Route path={`/${language}/movie/:id`} element={<MovieDetailsPage />} />
      <Route path={`/${language}/tag/:id`} element={<MovieByTagPage />} />
      <Route path={`/${language}/vrmovies`} element={<VrMoviesPage />} />
      <Route path={`/${language}/blue-movie-plus`} element={<BlueMoviePlusPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path={`/${language}/impressum`} element={<ImpressumPage />} />
      <Route path={`/${language}/18-U-S-C`} element={<UscPage />} />
      <Route path={`/${language}/support`} element={<ContactPage />} />
      <Route path={`/${language}/jugendschutz`} element={<ProtectionPage />} />
      <Route path={`/${language}/datenschutz`} element={<DataProtection />} />
      <Route path={`/${language}/cancellation-terms`} element={<CancellationTerms />} />
      <Route path={`/${language}/agb`} element={<TermsAndConditions />} />
      <Route path={`/${language}/nutzung-eu`} element={<UseEU />} />
      <Route path={`/${language}/faq`} element={<Faq />} />
      <Route path={`/${language}/preise`} element={<Prices />} />
      <Route path={`/sonderangebot/:hash`} element={<SpecialPage />} />
      <Route path={`/${language}/search`} element={<SearchPage />} />
      <Route path={`/${language}/payment/message/:gateway`} element={<PaymentSuccess />} />
      <Route path={`/${language}/themes/:slug`} element={<SectionPage type={'theme'} />} />
      <Route path={`/${language}/promotions/:slug`} element={<SectionPage type={'promotion'} />} />
      <Route path={`/${language}/logout`} element={<LogoutPage />} />
      <Route path={`/${language}/login-as-user`} element={<LoginAsUser />} />
      <Route path={'/404'} element={<PageNotFound />} />
      <Route path={'*'} element={<Navigate to="/404" />} />
      <Route element={<PrivateRoute />}>
        <Route path={`/${language}/brandnew`} element={<BrandNewPage />} />
        <Route path={`/${language}/payment/overview`} element={<PaymentOverview />} />
        <Route path="/details" element={<UserDetails />} />
        <Route path={`/${language}/account`} element={<Account />} />
        <Route path={`/${language}/wishlist`} element={<Wishlist />} />
        <Route path={`/${language}/devices`} element={<DevicesPage />} />
        <Route path={`/${language}/bought-movies`} element={<BoughtMovies />} />
        <Route path={`/${language}/deposit`} element={<DepositPage />} />
        <Route path={`/${language}/plus-account`} element={<PlusAccountPage />} />
      </Route>
    </Routes>
  );
};

export default RoutesList;
