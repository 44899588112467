import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { PaymentModel } from './types';

type PaymentQueryModel = {
  payment: PaymentModel;
  isPaymentLoading: boolean;
  refetchPaymentQuery: () => void;
  paymentError: boolean;
};

const usePaymentQuery = (isAuthenticated?: boolean, query?: { deposit_page: boolean }): PaymentQueryModel => {
  const [paymentError, setPaymentError] = useState(false);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['payment', isAuthenticated ? '-hard' : '-soft'],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/payment/initialize', query));
      if (!res.response.ok) {
        setPaymentError(true);
        return res.body;
      } else {
        return res.body;
      }
    },
    initialData: { payment_types: [], last_payments: [] },
    enabled: isAuthenticated,
  });
  const payment = data?.data;
  const isPaymentLoading = isLoading;
  const refetchPaymentQuery = refetch;
  return { payment, isPaymentLoading, refetchPaymentQuery, paymentError };
};

export default usePaymentQuery;
