import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { FingerprintsWebModel } from './types';

type FingerprintQueryModel = {
  isFingerprintQueryLoading: boolean;
  refetchFingerprintQuery: () => void;
  fingerprintsWeb: FingerprintsWebModel[];
  fingerprintsBeethoven: FingerprintsWebModel[];
  data: any;
};

const useFingerprintQuery = (): FingerprintQueryModel => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['fingerprint'],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/fingerprint'));
      if (res.response.ok) {
        return res.body.data;
      }
    },
  });
  const isFingerprintQueryLoading = isLoading;
  const refetchFingerprintQuery = refetch;
  const fingerprintsWeb = data?.web;
  const fingerprintsBeethoven = data?.beethoven;
  return { data, fingerprintsWeb, isFingerprintQueryLoading, refetchFingerprintQuery, fingerprintsBeethoven };
};

export default useFingerprintQuery;
