import React, { FC } from 'react';
import CookieConsent from 'react-cookie-consent';
import { CustomLink } from '../CustomLink';
import { useTranslation } from 'react-i18next';

const CookieModal: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <CookieConsent
      location="bottom"
      buttonClasses="cookieButton"
      contentClasses="cookieContent"
      buttonText="OK"
      contentStyle={{ flex: 'unset' }}
      cookieName="cookiesBM"
      style={{ background: '#cccccc', color: '#000', textAlign: 'center', display: 'flex', justifyContent: 'center' }}
      buttonStyle={{ color: '#fff', fontSize: '21px', background: '#3e92d4', padding: '15px' }}
      expires={150}
    >
      {t('cookieModal.text')} <CustomLink to={`/${language}/datenschutz`}>{t('cookieModal.link')}</CustomLink>
    </CookieConsent>
  );
};

export default CookieModal;
