import React, { FC, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { CustomSpinner } from '../../shared/Spinner';
import styles from './DevicesPage.module.scss';
import boxWhite from '../../assets/img/SkyIcon_TV_Q_SkyQ_Box_RGB_WHITE.png';
import { ConfirmModal } from 'src/components/UI/Modals/ConfirmModal';
import { useFingerprintQuery } from './query';
import { FingerprintsWebModel } from './query/types';
import { useDeleteFingerprintMutation } from './mutation';

const DevicesPage: FC = () => {
  // const [data, setData] = useState([]);
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const { data, refetchFingerprintQuery, isFingerprintQueryLoading } = useFingerprintQuery();
  const { mutate } = useDeleteFingerprintMutation();

  const handleDelete = (item: string) => {
    setItemToDelete(item);
    setShow(true);
  };

  const deleteItem = async () => {
    mutate(itemToDelete, {
      onSuccess(data: any) {
        if (data.status === 'success') {
          refetchFingerprintQuery();
          setShow(false);
          setError('');
        }
      },
      onError(error: any, variables, context) {
        setError(error?.body.message);
      },
    });
  };
  const renderIcon = (type: string) => {
    if (type === 'web') {
      return <i className="fa fa-desktop" aria-hidden="true" />;
    } else if (type === 'mobile') {
      return <i style={{ fontSize: 42 }} className="fa fa-mobile" aria-hidden="true" />;
    } else {
      return <img style={{ width: 40 }} src={boxWhite} alt="device" />;
    }
  };

  const makeTitle = (name: string) => {
    if (name === 'qbox') {
      return '18+ App';
    } else if (name === 'beethoven' || name==='stream') {
      return '18+ App';
    } else {
      return 'Web';
    }
  };
  if (isFingerprintQueryLoading) {
    return <CustomSpinner />;
  }
  return (
    <Container>
      <ConfirmModal
        error={error}
        show={show}
        onHide={() => {
          setError('');
          setShow(false);
        }}
        handleSuccess={() => deleteItem()}
      />
      <h2>Geräteliste</h2>
      <div>
        Sie können auf die Inhalte von Blue Movie 18+ über maximal fünf registrierte Geräte zugreifen. Die Registrierung
        erfolgt automatisch, wenn Sie sich mit Ihrem Gerät das erste Mal auf der 18+ App oder www.bluemovie.com
        anmelden. Sie können drei Geräte wie zum Beispiel Smartphones, Tablets oder Computer auf www.bluemovie.com
        anmelden. Über die 18+ App können Sie bis zu zwei Sky Q Geräte oder bis zu drei Sky Q over IP Geräte anmelden.
      </div>
      <hr style={{ background: '#D7D7D7' }} />
      <div className={styles.listContainer}>
        {data &&
          Object.keys(data).map((item, i) => {
            return (
              <details className={styles.listItem} key={item}>
                <summary className={styles.summary}>
                  <i className="fa fa-play " aria-hidden="true" />
                  <span style={{ marginLeft: 15 }}>{makeTitle(item)}</span>
                </summary>
                {data[item].map((l: FingerprintsWebModel, index: number) => {
                  return (
                    <div className={styles.singleDevice} key={`${l.id}`}>
                      <div className={styles.deviceInfo}>
                        <div>
                          <div className={styles.iconWrapper}>{renderIcon(item)}</div>
                        </div>
                        <div className={styles.textWrapper}>
                          <p className={styles.device}>{l.device_name}</p>
                          <p className={styles.lastActivity}>
                            <span>Zuletzt verwendet: </span>
                            {l.last_activity}
                          </p>
                        </div>
                      </div>
                      <i
                        onClick={() => handleDelete(data[item][index].device)}
                        style={{ cursor: 'pointer' }}
                        className="fa fa-trash"
                        aria-hidden="true"
                      />
                    </div>
                  );
                })}
              </details>
            );
          })}
      </div>
    </Container>
  );
};

export default DevicesPage;
