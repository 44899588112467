import React, { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const LoginAsUser: FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (token) localStorage.setItem('blue_token', token);
    navigate('/');
  }, [token]);

  return <></>;
};

export default LoginAsUser;
