import { queryClient } from 'src/core/root/components/ReactQueryProvider/ReactQueryProvider';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  AUTH_ERROR,
  USER_LOADED,
  SET_LOADING,
  UPDATE_USER,
  SET_USER_LOCATION,
} from '../types';
import { ActionsModel, InitialStateModel } from './utils';

const authReducer = (state: InitialStateModel, action: ActionsModel): InitialStateModel => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          balance: action.payload.balance,
        },
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('blue_token', action.payload.access_token);
      localStorage.setItem('blue_refresh_token', action.payload.refresh_token);

      //sessopm storage
      const { vorname, smartcardNr, customerNr, name } = action.payload;
      localStorage.setItem('blue_firstName', vorname);
      localStorage.setItem('blue_customerNumber', customerNr);
      localStorage.setItem('blue_smartcardNumber', smartcardNr);
      localStorage.setItem('blue_lastName', name);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
        firstLogin: true,
      };
    case LOGIN_FAIL:
    case LOGOUT:
    case AUTH_ERROR:
      localStorage.removeItem('blue_lastTimeStamp');
      localStorage.removeItem('blue_token');
      localStorage.removeItem('blue_refresh_token');
      localStorage.removeItem('blue_username');
      localStorage.removeItem('blue_firstName');
      localStorage.removeItem('blue_customerNumber');
      localStorage.removeItem('blue_smartcardNumber');
      localStorage.removeItem('blue_lastName');
      queryClient.invalidateQueries({ queryKey: ['homepage_movies'] });

      return {
        ...state,
        userLocation: state.userLocation,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: {},
        error: action.payload.error,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: '',
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_USER_LOCATION:
      return {
        ...state,
        userLocation: action.payload.userLocation,
      };
    default:
      return state;
  }
};

export default authReducer;
