import { createContext } from 'react';

const playerContext = createContext({
  startPlayer: () => {},
  pausePlayer: () => {},
  stopPlayer: () => {},
  playerActive: false,
  playerPaused: false,
});

export default playerContext;
