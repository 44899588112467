import React, { FC } from 'react';
import styles from './BigButton.module.scss';
import Col, { ColProps } from 'react-bootstrap/Col';

type Props = {
  text: string | React.ReactNode;
  icon: string;
  md?: ColProps['md'];
  sm?: ColProps['sm'];
  xs?: ColProps['xs'];
  lg?: ColProps['lg'];
  xl?: ColProps['xl'];
  active?: boolean;
  share?: boolean;
  onClick: () => void;
};

const BigButton: FC<Props> = ({ text, icon, onClick, md, sm, xs, active, share, lg, xl }) => {
  return (
    <Col md={md} sm={sm} xs={xs} lg={lg} xl={xl} className="mb-2">
      <div onClick={onClick} className={`${styles.menuBox} ${active && 'bg-success'} ${share && 'bg-danger'}`}>
        <i style={{ marginBottom: 5 }} className={icon} />
        <p className={styles.mainText}>{text}</p>
      </div>
    </Col>
  );
};

export default BigButton;
