import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { DepositOverviewModel } from './types';

type DepositQueryModel = {
  depositData: DepositOverviewModel[];
  isDepositOverviewLoading: boolean;
  refetchDepositQuery: () => void;
  depositError: boolean;
};

const useDepositQuery = (): DepositQueryModel => {
  const [depositError, setDepositError] = useState(false);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['deposit_overview'],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/payment/overview/deposits'));
      if (!res.response.ok) {
        setDepositError(true);
        return res.body.data;
      } else {
        return res.body.data;
      }
    },
  });

  const depositData = data;
  const isDepositOverviewLoading = isLoading;
  const refetchDepositQuery = refetch;
  return { depositData, isDepositOverviewLoading, refetchDepositQuery, depositError };
};

export default useDepositQuery;
