import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useStaticPageQuery } from 'src/hooks/query';
import Spinner from 'src/shared/Spinner/Spinner';

const CancellationTerms: FC = () => {
  const { html, isLoading } = useStaticPageQuery('cancellationTerms');

  if (isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (html.__html) {
    return (
      <Container>
        <Row>
          <Col sm={12}>
            <div dangerouslySetInnerHTML={html} />
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Container>
      <>
        <Row>
          <Col sm={12}>
            <h2>Widerrufsrecht</h2>
            <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>
            <p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses</p>
            <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</p>
            <p>tmc Media Group AG, Poststrasse 24, 6300 Zug, Schweiz</p>
            <p>Faxnummer: +41 62 756 13 64</p>
            <p>E-Mail-Adresse : help@tmc-mediagroup.ch</p>
            <p>
              mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über
              Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte
              Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
            </p>
            <p>
              Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
              Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
            </p>
            <h2>Folgen des Widerrufs</h2>
            <p>
              Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
              einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie
              eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben),
              unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
              Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
              Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde
              ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte
              berechnet.
            </p>
            <h2>Erlöschen des Widerrufsrechts</h2>
            <p>
              Das Widerrufsrecht erlischt bei einem Vertrag über die Lieferung von nicht auf einem körperlichen
              Datenträger befindlichen digitalen Inhalten auch dann, wenn der Unternehmer mit der Ausführung des
              Vertrags begonnen hat, nachdem der Verbraucher 1. ausdrücklich zugestimmt hat, dass der Unternehmer mit
              der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt, und 2. seine Kenntnis davon bestätigt
              hat, dass er durch seine Zustimmung mit Beginn der Ausführung des Vertrags sein Widerrufsrecht verliert.
            </p>
          </Col>
        </Row>
      </>
    </Container>
  );
};

export default CancellationTerms;
