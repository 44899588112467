import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { MovieTagsListModel, TagsListAllMoviesModel } from './types';

type TagsQueryModel = {
  tagsList: MovieTagsListModel[];
  isTagsQueryLoading: boolean;
  refetchTagsQuery: () => void;
  allMovies: TagsListAllMoviesModel;
};

const useTagsQuery = (isAuthenticated: boolean): TagsQueryModel => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [`tags`, isAuthenticated ? '-hard' : '-soft'],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl(`/tags`));
      if (!res.response.ok) {
        return res.body;
      }
      return res.body;
    },
  });
  const tagsList = data?.data;
  const isTagsQueryLoading = isLoading;
  const refetchTagsQuery = refetch;
  const allMovies = data?.all_movies;
  return { tagsList, isTagsQueryLoading, refetchTagsQuery, allMovies };
};

export default useTagsQuery;
