import React, { FC, Suspense, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../MoviesPage.module.scss';
import Form from 'react-bootstrap/Form';
import { CustomSpinner } from '../../../shared/Spinner';
import { MoviesList } from '../../../components/MoviesList';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import qs from 'qs';
import { URLQueryModel } from '../types';
import { useTagQuery, useTagsQuery } from './query';
import { AuthContext } from 'src/context/auth';
import { renderFormattedTitle } from '../../../utils/stringManipulation';
import { CustomLink } from 'src/components/CustomLink';

const MovieByTagPage: FC = () => {
  let { id } = useParams();
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;
  //TODO refactor filter to component, pagination to component, and tags list to component

  const navigate = useNavigate();
  const [navExpanded, setNavExpanded] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<URLQueryModel>({
    page: qs.parse(window.location.search, { ignoreQueryPrefix: true }).page || 1,
  });
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { tag, tagMeta, isTagQueryLoading } = useTagQuery(isAuthenticated, id as string, query);
  const { tagsList, isTagsQueryLoading } = useTagsQuery(isAuthenticated);

  useEffect(() => {
    let formatedQuery = qs.stringify(query);
    navigate(`?${formatedQuery}`, { replace: true });
  }, [navigate, query]);

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setQuery({
      ...query,
      sort: e.target.value,
    });
    if (e.target.value === 'new') {
      setQuery({
        ...query,
        sort: 'license.online_start',
      });
    }
    if (e.target.value === 'old') {
      setQuery({
        ...query,
        sort: '-license.online_start',
      });
    }
    if (e.target.value === 'title-a') {
      setQuery({
        ...query,
        sort: 'content_data.title',
      });
    }
    if (e.target.value === 'title-d') {
      setQuery({
        ...query,
        sort: '-content_data.title',
      });
    }
  };
  const getActiveCategoryName = () => {
    const activeCategory = tagsList && tagsList.find((tag) => tag.id === parseInt(id as string));
    return activeCategory ? activeCategory.name : '';
  };
  const closeNav = () => {
    setNavExpanded(false);
    setOpen(false);
  };
  const renderPagination = () => {
    if (tagMeta && !isTagQueryLoading) {
      return (
        <ReactPaginate
          previousLabel={<i className="fa fa-chevron-left"></i>}
          nextLabel={<i className="fa fa-chevron-right"></i>}
          breakLabel={'...'}
          breakClassName={styles.breakMe}
          pageCount={tagMeta && tagMeta.last_page}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          forcePage={(query.page as number) - 1}
          containerClassName={'pagination'}
          activeClassName={styles.active}
          previousClassName={query.page === 1 ? `${styles.hideButton}` : `${styles.showButton}`}
          nextClassName={tagMeta && query.page === tagMeta.last_page ? `${styles.hideButton}` : `${styles.showButton}`}
        />
      );
    }
    return null;
  };

  const handlePageChange = (data: { selected: number }) => {
    const selectedPage = data.selected + 1;
    setQuery({
      ...query,
      page: selectedPage,
    });
  };
  const setNav = (expanded: boolean) => {
    setNavExpanded(expanded);
    setOpen(!open);
  };
  const renderTags = () => {
    if (tagsList) {
      return (
        <ul className={styles.category}>
          <Navbar onToggle={setNav} expanded={navExpanded} className={styles.navbarWrapper} expand="md">
            <Navbar.Toggle aria-controls="category-box" className={styles.categoryButton}>
              <div>
                Kategorien
                <span id="category-head-icon-wrap" className="open">
                  {open ? (
                    <i className="fa fa-caret-up" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  )}
                </span>
              </div>
            </Navbar.Toggle>
            <Navbar.Collapse className={styles.categoryBox} id="category-box">
              <Nav onClick={() => closeNav()}>
                <CustomLink to={`/${language}/movies`}>
                  <li>Alle Filme</li>
                </CustomLink>
                {tagsList.map((tag, index) => (
                  <CustomLink key={index} to={`/${language}/tag/${tag.id}`}>
                    <li className={tag.id === parseInt(id as string) ? styles.active : ''}>
                      {renderFormattedTitle(tag.name)}
                    </li>
                  </CustomLink>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </ul>
      );
    }
  };

  const renderMovieList = () => {
    if (isTagQueryLoading && !isTagsQueryLoading) {
      return <CustomSpinner />;
    } else if (tag) {
      return <MoviesList data={tag} />;
    }
  };

  if (isTagsQueryLoading && isTagQueryLoading) {
    return (
      <Container className={styles.mainWrapper}>
        <CustomSpinner />
      </Container>
    );
  }

  return (
    <Container className={styles.mainWrapper}>
      <Row>
        <Col md={3}>
          <div className={styles.title}>
            <h2>{tagsList && id && tagsList[id] && tagsList[id].title}</h2>
          </div>
        </Col>
        <Col md={9}>
          <Row>
            <Col className={styles.sortWrapper} md={4}>
              <div className={styles.sortOptions}>
                <Form.Select onChange={handleOptionChange} size="sm">
                  <option value="new">{t('moviesPage.filterNew')}</option>
                  <option value="old">{t('moviesPage.filterOld')}</option>
                  <option value="title-a">{t('moviesPage.filterA')}</option>
                  <option value="title-d">{t('moviesPage.filterZ')}</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={styles.categoryWrapper} sm={3}>
          <div>
            <div className={styles.categories}>{renderTags()}</div>
          </div>
        </Col>
        <Col md={9}>
          <Suspense fallback={<CustomSpinner />}>
            <Row>
              <Col sm={{ span: 6 }}>
                <div>
                  <h2>{getActiveCategoryName()}</h2>
                </div>
              </Col>
              <Col sm={{ span: 6 }}>
                <div className={styles.paginationWrapper}>
                  <div className={styles.pagination}>{renderPagination()}</div>
                </div>
              </Col>
            </Row>

            <Row>{renderMovieList()}</Row>
            <Row>
              <Col sm={{ span: 9, offset: 3 }}>
                <div className={styles.paginationWrapper}>
                  <div className={styles.pagination}>{renderPagination()}</div>
                </div>
              </Col>
            </Row>
          </Suspense>
        </Col>
      </Row>
    </Container>
  );
};

export default MovieByTagPage;
