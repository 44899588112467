import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { BoughtMoviesModel } from '../query/types';

type DataModel = {
  movie: BoughtMoviesModel;
  data: { content_name?: string; license_type?: string; price?: number };
};

const usePurchaseMovieMutation = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: DataModel) => {
      const response = await fetchRedirect(createUrl(`/movies/${data.movie.id}/purchase`), {
        method: 'POST',
        body: JSON.stringify(data.data),
      });
      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
  });
  return { mutate, isLoading };
};

export default usePurchaseMovieMutation;
