import React, { FC, useCallback, useContext, useState } from 'react';
import styles from './StbPromotionsModal.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Modal, Row } from 'antd';
import { AuthContext } from 'src/context/auth';
import { useDontShowStbPromotion } from './mutation';
import { useMediaQuery } from 'react-responsive';
import {generateImageUrl} from "../../../../utils/cdnService";

type Props = {
  show: boolean;
  onHide: () => void;
};


const StbPromotionsModal: FC<Props> = ({ show, onHide }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const [isChecked, setIsChecked] = useState(false);
  const [stbPromotionIndex, setStbPromotionIndex] = useState<number>(0);
  const { mutate } = useDontShowStbPromotion();

  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  const isTablet = useMediaQuery({
    query: '(max-width: 1023px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const isThereStbPromotion = user.stb_promotions?.length && user.stb_promotions?.length > stbPromotionIndex + 1;

  const handleDontShowCheckbox = useCallback(
    (promotionId: number) => {
      if (isChecked) {
        mutate({ id: promotionId });
        setIsChecked(false);
      }
    },
    [isChecked, mutate]
  );

  const handleNextPromotion = useCallback(
    (promotionId: number) => {
      handleDontShowCheckbox(promotionId);
      if (isThereStbPromotion) {
        setStbPromotionIndex(stbPromotionIndex + 1);
      } else {
        onHide();
      }
    },
    [handleDontShowCheckbox, isThereStbPromotion, onHide, stbPromotionIndex]
  );

  const renderPromotions = () => {
    return user.stb_promotions?.map((promotion, index) => {
      const getDeviceImg = () => {
        if (isMobile) {
          return promotion.stb_promotion.background_image_mobile;
        }
        if (isTablet) {
          return promotion.stb_promotion.background_image_tablet;
        }
        if (isDesktop) {
          return promotion.stb_promotion.background_image;
        }
      };
      if (index === stbPromotionIndex) {
        return (
          <div className={styles.background} key={promotion.id}>
            <img className={styles.background_img} src={generateImageUrl('gcore', 0,0, getDeviceImg())} alt="background" />
            <Row className={styles.row_container_columns}>
              {promotion.stb_promotion.left_column_data && (
                <Col className={`stb_left_column ${styles.stb_left_column}`}>
                  {
                    <div
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                      dangerouslySetInnerHTML={{ __html: promotion.stb_promotion.left_column_data as string }}
                    />
                  }
                </Col>
              )}
              {promotion.stb_promotion.right_column_image && (
                <Col className={styles.right_column}>
                  <img src={generateImageUrl('gcore', 0,0, promotion.stb_promotion.right_column_image)} alt="right-column" />
                </Col>
              )}
            </Row>
            <Row className={styles.row_container_buttons} align={'top'} justify={'center'}>
              <Col className={styles.column_buttons}>
                <Button type="primary" className={styles.button} onClick={() => handleNextPromotion(promotion.id)}>
                  {isThereStbPromotion ? t('stbPromotionsModal.continue') : t('stbPromotionsModal.close')}
                </Button>

                <Checkbox onChange={() => setIsChecked(!isChecked)} className={styles.checkbox}>
                  {t('stbPromotionsModal.dont_show')}
                </Checkbox>
              </Col>
            </Row>
          </div>
        );
      }
      return null;
    });
  };

  return (
    <Modal
      open={show}
      onCancel={onHide}
      className={styles.container}
      width="100%"
      footer={false}
      style={{ padding: 0 }}
      bodyStyle={{ height: '100%' }}
    >
      {renderPromotions()}
    </Modal>
  );
};

export default StbPromotionsModal;
