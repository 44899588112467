import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { createUrl } from 'src/core/api/utils/createUrl';
import { URLSearchQueryStateModel } from 'src/pages/search/types';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { SectionModel } from './types';

type SearchQueryModel = {
  sectionData: SectionModel;
  isSectionQueryLoading: boolean;
  isError: boolean;
  isSectionError: boolean;
};

const useSectionQuery = (
  query: URLSearchQueryStateModel,
  isAuthenticated: boolean,
  fetchUrls: string
): SearchQueryModel => {
  const [isSectionError, setIsSectionError] = useState(false);
  const { data, isLoading, isError } = useQuery({
    queryKey: ['section', isAuthenticated ? `${Object.values(query)}-hard` : `${Object.values(query)}-soft`],
    queryFn: async () => {
      const res = await fetchRedirect(createUrl(fetchUrls, query));
      if (!res.response.ok) {
        setIsSectionError(true);
        return res.body;
      } else {
        return res.body;
      }
    },
  });
  const isSectionQueryLoading = isLoading;
  const sectionData = (data as any)?.data;
  return { isSectionQueryLoading, sectionData, isError, isSectionError };
};

export default useSectionQuery;
