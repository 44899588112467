import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { createUrl } from 'src/core/api/utils/createUrl';
import { TagsModel } from '../types';
import { fetch as fetchRedirect } from 'src/utils/refetch';

interface ITagsModel {
  tags: TagsModel['data'][];
  areTagsLoading: boolean;
  isError: boolean;
  refetchTags: () => void;
}

const useTagsQuery = (isAuthenticated?: boolean): ITagsModel => {
  const [isError, setIsError] = useState<boolean>(false);
  const {
    data,
    isLoading: areTagsLoading,
    refetch: refetchTags,
  } = useQuery({
    queryKey: ['tags', isAuthenticated ? 'hard' : 'soft'],
    queryFn: async () => {
      const response: any = await fetchRedirect(createUrl('/tags'));
      if (!response.response.ok) {
        setIsError(true);
        return response.body;
      }
      return response.body;
    },
  });

  const tags = data?.data;

  return { tags, areTagsLoading, isError, refetchTags };
};

export default useTagsQuery;
