import React, { FC } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './WarningModal.module.scss';
type Props = {
  onHide: () => void;
  onClick: () => void;
  showWarningModal: boolean;
};

const WarningModal: FC<Props> = ({ onHide, onClick, showWarningModal }) => {
  const { t } = useTranslation();
  return (
    <Modal centered={true} show={showWarningModal} onHide={onHide}>
      <Modal.Header closeButton className={styles.modalHeader} closeVariant="white">
        <Modal.Title className={styles.modalTitle}>{t('bmPlusPage.warning')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Row>
          <Col sm={12}>
            <p>{t('bmPlusPage.purchaseTicketNotEnabledWarning')}</p>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <button className={styles.button} onClick={onClick}>
              {t('euPortability.ok')}
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default WarningModal;
