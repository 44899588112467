import { baseUrl } from 'src/core/constants';

const queryString = (params: any) => {
  let durationFilter = 'filter[content_property.length]';
  let studioFilter = 'filter[studio_id]';

  if (params.hasOwnProperty('studio') || params.hasOwnProperty('duration')) {
    let str = Object.keys(params)
      .map((key) => {
        let newKey;
        if (key === 'duration') {
          newKey = durationFilter;
        } else if (key === 'studio') {
          newKey = studioFilter;
        } else {
          newKey = key;
        }
        return `${newKey}=${params[key]}`;
      })
      .join('&');
    return str;
  } else {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
  }
};
export const createUrl = (url: string, query?: {}) => {
  if (query) {
    return `${baseUrl}${url}?${queryString(query)}`;
  }
  return `${baseUrl}${url}`;
};
