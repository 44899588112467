import { PaymentTypesModel } from '../pages/blueMoviePlus/query/types';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../core/constants/constants';

const useHandleSepaPayment = (selectedType: PaymentTypesModel) => {
  const {
    i18n: { language },
  } = useTranslation();

  const handleSepaPayment = (paymentData: { iban: string; name: string; lastName: string; bank: string }) => {
    paymentData.iban = paymentData.iban.replace(/\s/g, '');
    localStorage.setItem('blue_payment_body_data', JSON.stringify(paymentData));
    window.location.href = `${BASE_URL}/${language}/payment/message/${selectedType.gateway}`;
  };

  return handleSepaPayment;
};

export default useHandleSepaPayment;
