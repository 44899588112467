import { useMutation } from '@tanstack/react-query';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { createUrl } from 'src/core/api/utils/createUrl';

export type DontShowStbPromotionModel = {
  id: number;
};

const useDontShowStbPromotion = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: DontShowStbPromotionModel) => {
      const response = await fetchRedirect(createUrl(`/stb-promotions/${data.id}`), {
        method: 'DELETE',
      });
      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
  });
  return { mutate, isLoading };
};

export default useDontShowStbPromotion;
