import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './EuPortability.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

type Props = {
  centered: boolean;
  show: boolean;
  onHide: () => void;
  text: string;
};

const EuPortability: FC<Props> = ({ centered, show, onHide, text }) => {
  const { t } = useTranslation();

  return (
    <Modal centered={centered} show={show} onHide={onHide}>
      <Modal.Header closeButton className={styles.modalHeader} closeVariant="white"></Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Row>
          <Col>
            <h2>{t('euPortability.title')}</h2>
            <p>{t('euPortability.text1')}</p>
            <p>{text && parse(text)}</p>
            <p>
              {t('euPortability.text2')}{' '}
              <a href="https://www.bluemovie.com/de/agb">{t('euPortability.text3')}</a>.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <button onClick={onHide}>{t('euPortability.ok')}</button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default EuPortability;
