import React from 'react';
// disable typescript
// @ts-ignore
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = (props: any) => {
    const videoRef = React.useRef<any>(null);
    const playerRef = React.useRef<any>(null);
    const { options, onPlay, onPause } = props;

    React.useEffect(() => {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement("video-js");

            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, options);

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            const player = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
        }

        playerRef.current.on('play', onPlay);
        playerRef.current.on('pause', onPause);
    }, [options, onPlay, videoRef, onPause]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current.off('play', onPlay)
                playerRef.current.off('pause', onPause)
                playerRef.current = null;
            }
        };
    }, [playerRef, onPlay, onPause]);

    return (
        <div data-vjs-player>
            <div ref={videoRef} />
        </div>
    );
}

export default React.memo(VideoJS);