import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from '../../../shared/Spinner/Spinner';
import { useStaticPageQuery } from 'src/hooks/query';

const UseEU: FC = () => {
  const { html, isLoading } = useStaticPageQuery('usage-eu');

  if (isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <div dangerouslySetInnerHTML={html} />
        </Col>
      </Row>
    </Container>
  );
};

export default UseEU;
