import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { URLBoughtMoviesQueryStateModel } from '../types';
import { BoughtMoviesMetaModel, BoughtMoviesModel } from './types';

type PurchasedContentQueryModel = {
  boughtMovies: BoughtMoviesModel[];
  boughtMoviesMeta: BoughtMoviesMetaModel;
  areBoughtMoviesLoaded: boolean;
  refetchBoughtMovies: () => void;
};

const usePurchasedContentQuery = (query: URLBoughtMoviesQueryStateModel): PurchasedContentQueryModel => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['purchased_content', query],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/user/purchased/content', query));
      if (res.response.ok) {
        return res.body;
      }
    },
  });

  const boughtMovies = data?.data;
  const boughtMoviesMeta = data?.meta;
  const areBoughtMoviesLoaded = isLoading;
  const refetchBoughtMovies = refetch;

  return { boughtMovies, areBoughtMoviesLoaded, boughtMoviesMeta, refetchBoughtMovies };
};

export default usePurchasedContentQuery;
