import React, { FC, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './EmailModal.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useEmailMutation } from './mutation';
import { EmailDataModel } from './types';
import Alert from 'react-bootstrap/Alert';

type Props = {
  centered: boolean;
  show: boolean;
  onHide: () => void;
  ticket: EmailDataModel['ticket'];
  handleSuccess: () => void;
};

const EmailModal: FC<Props> = ({ centered, show, onHide, ticket, handleSuccess }) => {
  const { t } = useTranslation();
  const [user_email, setEmail] = useState<string>('');
  const [subject, setSubject] = useState<string>('Abo Kündigung');
  const [message, setMessage] = useState<string>('');
  const [isAlertShow, setIsAlertShow] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<string | undefined>('');
  const { mutate, isLoading } = useEmailMutation();

  useEffect(() => {
    if (isAlertShow) {
      setTimeout(() => {
        setIsError(false);
        setIsAlertShow(false);
      }, 3000);
    }
  }, [isAlertShow]);

  const handleButtonClick = () => {
    if (user_email && subject && message) {
      sendEmail({ user_email, subject, message, ticket });
    } else {
      return;
    }
  };

  const sendEmail = (data: EmailDataModel) => {
    mutate(data, {
      onSuccess() {
        setEmail('');
        setSubject('Abo Kündigung');
        setMessage('');
        handleSuccess();
        setIsAlertShow(true);
      },
      onError() {
        setIsError(true);
      },
      onSettled(data, error) {
        setStatusMessage((data as Partial<EmailDataModel>).message);
        console.error(error);
      },
    });
  };

  const handleHide = () => {
    setStatusMessage('');
    setEmail('');
    setSubject(t('emailModal.subject'));
    setMessage('');
    onHide();
  };

  return (
    <>
      <Alert style={{ maxWidth: '300px' }} transition show={isAlertShow} variant={isError ? 'danger' : 'success'}>
        {isError ? 'Email not sent' : 'Email sent'}
      </Alert>
      <Modal centered={centered} show={show} onHide={() => handleHide()}>
        <Modal.Header closeButton className={styles.modalHeader} closeVariant="white">
          <Modal.Title className={styles.title}>{t('emailModal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Row>
            <Col>
              <p>{t('emailModal.subTitle')}</p>
            </Col>
          </Row>
          <Row>
            <Col sm="7">
              <input
                required
                value={user_email}
                style={{ marginBottom: 10 }}
                placeholder={t('emailModal.yourEmail')}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
            <Col sm="7">
              <input
                required
                value={subject}
                style={{ marginBottom: 10 }}
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
                type="text"
              />
            </Col>
            <Col sm="7">
              <textarea
                required
                rows={7}
                value={message}
                style={{ width: '100%', marginBottom: 10 }}
                placeholder={t('emailModal.message')}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <p>{statusMessage}</p>
              <button onClick={handleButtonClick}>
                {isLoading ? t('emailModal.sending') : t('emailModal.sendEmail')}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>{' '}
    </>
  );
};

export default EmailModal;
