import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { AuthContext } from 'src/context/auth';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';

type PrefetchQueryModel = {
  prefetchData: { user_country_code: 'RS' };
  isPrefetchLoading: boolean;
  refetchPrefetchQuery: () => void;
};

const usePrefetchQuery = (): PrefetchQueryModel => {
  const authContext = useContext(AuthContext);
  const { setUserLocation } = authContext;
  const { data, isLoading, refetch } = useQuery({
    queryKey: [`prefetch`],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl(`/prefetch`));

      if (!res.response.ok) {
        return res.body;
      }
      setUserLocation({ userLocation: res.body.user_country_code });
      return res.body;
    },
  });
  const prefetchData = data;
  const isPrefetchLoading = isLoading;
  const refetchPrefetchQuery = refetch;
  return { prefetchData, isPrefetchLoading, refetchPrefetchQuery };
};

export default usePrefetchQuery;
