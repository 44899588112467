import React, { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MovieWall.module.scss';
import { useTranslation } from 'react-i18next';
import { PromotionBadge } from '../PromotionBadge';
import { MovieWallDataModel } from './types';
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { CustomLink } from '../CustomLink';
import { generateImageUrl } from '../../utils/cdnService';

type Props = {
  title: string;
  rightText: string;
  data: MovieWallDataModel[];
};

const MovieWall: FC<Props> = ({ title, data, rightText }) => {
  const navigate = useNavigate();
  const params = {
    loop: true,
    navigation: {
      nextEl: `${styles.nextButton}`,
      prevEl: `${styles.prevButton}`,
    },
    slidesPerView: 4,
    spaceBetween: 10,
    // Responsive breakpoints
    breakpoints: {
      // when window width is <= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      411: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10,
      },
      650: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 10,
      },
      991: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 6,
        spaceBetween: 10,
      },
    },
  };

  const ref = useRef<SwiperRef | null>(null);

  const handleNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper?.slideNext();
    }
  };

  const handlePrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper?.slidePrev();
    }
  };

  const {
    i18n: { language },
  } = useTranslation();

  const renderGallery = () => {
    if (data && data?.length > 0) {
      return (
        <Swiper modules={[Navigation, Autoplay]} ref={ref} {...params}>
          {data.map((movie) => (
            <SwiperSlide key={movie.id}>
              <div className={`${styles.movieDetails} ${styles.movieWrapper}`}>
                <div className={styles.movieImage}>
                  {movie.banner ? <div className={`${styles.marker} ${styles[movie.banner]}`}></div> : null}
                  {movie.promotional_image && movie.promotion_value && (
                    <PromotionBadge image={movie.promotional_image} value={movie.promotion_value} />
                  )}
                  <CustomLink to={`/${language}/movie/${movie.id}`}>
                    <img src={generateImageUrl('gcore', 242, 323, movie.image)} alt="movie" />
                  </CustomLink>
                </div>
                <div className={styles.movieLink}>
                  <CustomLink className={styles.movieTitle} to={`/${language}/movie/${movie.id}`}>
                    {movie.title}
                  </CustomLink>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      );
    }
  };

  if (data && data?.length === 0) {
    return null;
  }

  return (
    <div className={styles.secondarySlider}>
      <div className={styles.headline}>
        <div onClick={() => navigate(`/${language}/brandnew`)} className={styles.title}>
          <h2>{title}</h2>
        </div>
        <div className={styles.link}>
          <CustomLink className={styles.navLink} to={`/${language}/brandnew`}>
            Alle <strong>{rightText}</strong>
          </CustomLink>
        </div>
      </div>
      <div className={styles.swiper}>
        {renderGallery()}
        <div onClick={handleNext} className={styles.nextButton}>
          <i className="fa fa-angle-right" aria-hidden="true" />
        </div>
        <div onClick={handlePrev} className={styles.prevButton}>
          <i className="fa fa-angle-left" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
};

export default MovieWall;
