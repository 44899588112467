import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';

const useMovieDetailBookmarksMutation = () => {
  const { mutate: mutateBookmark, isLoading: isBookmarksMutationLoading } = useMutation({
    mutationFn: async (data: { content_id: string }) => {
      const response = await fetchRedirect(createUrl('/user/bookmarks'), {
        method: 'POST',
        body: JSON.stringify(data),
      });
      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
  });
  return { mutateBookmark, isBookmarksMutationLoading };
};

export default useMovieDetailBookmarksMutation;
