import { Container, Row, Col } from 'react-bootstrap';
import styles from './USP.module.css';
import { FC } from 'react';
import React from 'react';

const USP: FC = () => {
  return (
    <Container className={styles.uspContainer}>
      <Row>
        <Col sm={12}>
          <h2>Das bietet die Blue Movie online-videothek</h2>
        </Col>
      </Row>
      <div className={styles.uspPoints}>
        <Col sm={12} className={styles.uspColumnPoints}>
          <Row>
            <Col md={6} className={styles.uspColumnPointsSection}>
              <ul className={styles.uspColumnPoints}>
                <li>
                  <i className="fa fa-check-circle"></i>Hochwertige Erotik ab 18 - auch für den besonderen Geschmack
                </li>
                <li>
                  <i className="fa fa-check-circle"></i>Diskreter Filmabruf - einfach und bequem von zu Hause und
                  unterwegs
                </li>
                <li>
                  <i className="fa fa-check-circle"></i>Mehr als 2.500 von der Blue Movie-Redaktion ausgewählte Filme
                </li>
              </ul>
            </Col>
            <Col md={6} className={styles.uspColumnPointsSection}>
              <ul className={styles.uspColumnPoints}>
                <li>
                  <i className="fa fa-check-circle"></i>Regelmäßig neue Filme
                </li>
                <li>
                  <i className="fa fa-check-circle"></i>Filme zum Leihen, Kaufen oder auch im Abo
                </li>
                <li>
                  <i className="fa fa-check-circle"></i>Diskrete Bestellung und perfekter Jugendschutz
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </div>
    </Container>
  );
};
export default USP;
