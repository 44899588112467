import React, { FC, useState } from 'react';
import Container from 'react-bootstrap/Container';
import styles from './PlusAccountPage.module.scss';
import { useTranslation } from 'react-i18next';
import { CustomSpinner } from '../../shared/Spinner';
import { EmailModal } from '../../components/UI/Modals/EmailModal';
import { useTicketsQuery } from './query';
import useGeneratePdfQuery from '../../hooks/query/useGeneratePdfQuery';

const PlusAccountPage: FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const { userTicket, isTicketsQueryLoading } = useTicketsQuery();
  const handleClose = () => setShow(false);
  const { t } = useTranslation();
  const { mutate } = useGeneratePdfQuery();
  function firstCharToUpper(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  if (isTicketsQueryLoading) {
    return <CustomSpinner />;
  }

  return (
    <Container>
      <h2>{t('plusAccountPage.title')}</h2>
      <table className={styles.tableNoSideBorders}>
        <thead>
          <tr>
            <th>{t('plusAccountPage.yourTicket')}</th>
            <th>{t('plusAccountPage.nextCharge')}</th>
            <th>{t('plusAccountPage.status')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{userTicket?.ticket}</td>
            <td>{userTicket?.recurring_at ? userTicket.recurring_at : '--'}</td>
            <td>{userTicket?.status ? firstCharToUpper(userTicket.status) : '--'}</td>
            <td colSpan={3}>
              {userTicket?.recurring_at && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={() => setShow(true)}>
                  {t('plusAccountPage.cancel')}
                </a>
              )}
              {userTicket?.cancelled_at && <p>{`Gekündigt zum ${userTicket.cancelled_at}`}</p>}
              {userTicket?.has_invoice && (
                <div className={styles.downloadMandat}>
                  <button onClick={() => mutate(userTicket.transaction_id)}>{t('plusAccountPage.download')}</button>
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <EmailModal
        handleSuccess={() => setShow(false)}
        centered={true}
        show={show}
        ticket={userTicket}
        onHide={handleClose}
      />
    </Container>
  );
};

export default PlusAccountPage;
