import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { PaymentMutationModel } from './types';

const usePaymentFetchMutation = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: PaymentMutationModel) => {
      const response = await fetchRedirect(createUrl('/payment/fetch'), {
        method: 'POST',
        body: JSON.stringify(data),
      });
      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
  });
  return { mutate, isLoading };
};

export default usePaymentFetchMutation;
