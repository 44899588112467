import React, { FC, useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './PinConfirmationModal.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoginButton } from '../../LoginButton';
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from '../../../../context/auth';
import { useCheckPinMutation } from '../OrderModal/mutation';
import { useGetUsername } from 'src/utils';

type Props = {
  centered: boolean;
  show: boolean;
  selectedButton: string;
  onHide: () => void;
  handleSuccess: () => void;
  streamLimitReached?: boolean;
};

const PinConfirmationModal: FC<Props> = ({
  centered,
  show,
  onHide,
  handleSuccess,
  selectedButton,
}) => {
  const [pin, setPin] = useState<string>('');
  const [error, setError] = useState<string | null | unknown>(null);
  const authContext = useContext(AuthContext);
  const { userLocation } = authContext;
  const username = useGetUsername();

  const { mutate, isLoading } = useCheckPinMutation();

  const handlePinChange = (e: React.SyntheticEvent) => {
    const { nativeEvent } = e;
    handleTest((nativeEvent as InputEvent).data);
  };

  const handleTest = (input: string | null) => {
    const reg = new RegExp('^\\d+$');
    if (input && reg.test(input) && pin?.length < 4) {
      setPin(pin + input);
    } else if (input === null) {
      setPin(pin.slice(0, -1));
    }
  };
  const handleCheckPin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      mutate(
        { pin: pin, username: username },
        {
          onSuccess(data: any) {
            if (data.status === 'success') {
              handleSuccess();
            }
          },
          onError(error: any, variables, context) {
            setError(error?.body.message);
            setPin('');
          },
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal centered={centered} show={show} onHide={onHide}>
      <Modal.Header closeButton className={styles.modalHeader} closeVariant="white">
        {/*<Modal.Title>Modal heading</Modal.Title>*/}
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Row>
          <Col>
            <h2>{userLocation === 'DE' ? '18+' : 'Sky'} PIN</h2>
            {(selectedButton === 'hd' || selectedButton === 'sd') && (
              <p className={styles.conent}>
                Der Inhalt ist nach dem Download nicht zugriffsbeschränkt. Er darf Minderjährigen unter keinen Umständen
                zugänglich gemacht werden.
              </p>
            )}
            <p>
              Um diesen Film ansehen zu können, müssen Sie hier Ihre {userLocation === 'DE' ? '18+' : 'Sky'} PIN
              eingeben.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <form autoComplete="off" onSubmit={(e) => handleCheckPin(e)}>
              <input
                style={{ marginBottom: 10 }}
                autoComplete="off"
                placeholder={userLocation === 'DE' ? '18+ PIN' : 'Sky PIN'}
                type="text"
                value={pin
                  .split('')
                  .map((item) => '⚫')
                  .join('')}
                onChange={handlePinChange}
              />
            </form>
          </Col>
          <Col sm="6">
            <LoginButton loading={isLoading} handleLogin={handleCheckPin} disabled={pin?.length < 4 ? true : false} />
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {error ? (
              <Alert show={!!error} variant={'danger'}>
                <>{error}</>
              </Alert>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PinConfirmationModal;
