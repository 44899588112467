import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';

const useDeleteFingerprintMutation = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: string | null) => {
      const response: any = await fetchRedirect(createUrl(`/fingerprint/${data}`), {
        method: 'DELETE',
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        return response.json();
      }
      return response.json();
    },
  });
  return { mutate, isLoading };
};

export default useDeleteFingerprintMutation;
