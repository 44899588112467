import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './SuccessModal.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

type Props = {
  centered: boolean;
  show: boolean;
  onHide: () => void;
  handleSuccess?: () => void;
};

const SuccessModal: FC<Props> = ({ centered, show, onHide, handleSuccess }) => {
  const { t } = useTranslation();
  return (
    <Modal centered={centered} show={show} onHide={onHide}>
      <Modal.Header closeButton className={styles.modalHeader} closeVariant="white" />
      <Modal.Body className={styles.modalBody}>
        <Row>
          <Col>
            <h2>{t('voucherModal.title')}</h2>
            <p>{t('voucherModal.text')}</p>
          </Col>
        </Row>
        <Row>
          <Col sm="6"></Col>
          <Col sm="6">
            <button onClick={onHide}>Exit</button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
