import { UserModel } from 'src/core/shared/types';

const userInitialState: Partial<UserModel> = {
  access_token: '',
  balance: 0,
  contractId: '',
  currency: '',
  customerNr: '',
  euPortabilityEligibility: '',
  euPortabilityExpiryDate: '',
  euPortabilityStatus: '',
  expires_in: 0,
  is_tracked: 0,
  land: '',
  language: '',
  location: '',
  mahnstufe: '',
  name: '',
  prepaid: false,
  refresh_token: '',
  returnCode: '',
  show_trial: false,
  smartcardNr: '',
  subscribed: false,
  ticket_purchase_enabled: false,
  token_type: '',
  user_id: 0,
  vorname: '',
  stb_promotions: [],
};

export default userInitialState;
