import { useMutation } from '@tanstack/react-query';
import { useReducer } from 'react';
import { CLEAR_ERRORS } from 'src/context/types';
import { INITIAL_STATE } from 'src/core/constants/constants';
import { deviceDetect, isDesktop, isMobile } from 'react-device-detect';
import { generateFingerPrint } from 'src/utils/fingerPrint';
import { AuthReducer } from 'src/context/auth';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';

const useLoginMutation = () => {
  const [, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  const { mutate, isLoading, data, error } = useMutation({
    mutationFn: async (data: { username: string; pin: string; visitorId?: string; delete_sessions?: boolean }) => {
      const clearErrors = () => dispatch({ type: CLEAR_ERRORS });
      let device;
      let device_type;

      if (isMobile) {
        device = deviceDetect(window.navigator.userAgent)?.model || 'Mobile';
        device_type = 'Mobile';
      } else if (isDesktop) {
        device = 'Desktop';
        device_type = 'Desktop';
      }
      let fingerprint = await generateFingerPrint();
      if (device_type === 'Mobile') {
        fingerprint = { hash: data.visitorId };
      }

      clearErrors();
      const { username, pin } = data;
      const response = await fetchRedirect(createUrl(`/login? `), {
        method: 'POST',
        body: JSON.stringify({
          username,
          pin,
          fingerprint,
          device_name: device,
          device_type,
          delete_sessions: data.delete_sessions || false,
        }),
      });

      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
  });

  return { mutate, isLoading, data, error };
};

export default useLoginMutation;
