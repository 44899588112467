import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './BlueBoxBanner.module.scss';
import { useTranslation } from 'react-i18next';
import {generateImageUrl} from "../../../../utils/cdnService";

const BlueBoxBanner: FC = () => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col className={styles.info} sm={12}>
        <Row>
          <Col sm={4} className="mb-2">
            <div className={styles.infoBox}>
              <img
                  src={generateImageUrl('gcore', 0, 0, '/old_images/rocket.png?v=59')}

                  alt="" />
              <h2>{t('bmPlusPage.streamBlueBoxTitle')}</h2>
              <p>{t('bmPlusPage.streamBlueBoxText')}</p>
            </div>
          </Col>
          <Col sm={4} className="mb-2">
            <div className={styles.infoBox}>
              <img
                  src={generateImageUrl('gcore', 0, 0, '/old_images/hd.png?v=59')}

                   alt="" />
              <h2>{t('bmPlusPage.qualityBlueBoxTitle')}</h2>
              <p>{t('bmPlusPage.qualityBlueBoxText')}</p>
            </div>
          </Col>
          <Col sm={4} className="mb-2">
            <div className={styles.infoBox}>
              <img src={generateImageUrl('gcore', 0, 0, '/old_images/devices.png?v=59')} alt="" />
              <h2>{t('bmPlusPage.mobileBlueBoxTitle')}</h2>
              <p>{t('bmPlusPage.mobileBlueBoxText')}</p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BlueBoxBanner;
