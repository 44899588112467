import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import './i18n';
import AuthState from './context/auth/AuthState';
import { BrowserRouter as Router } from 'react-router-dom';
import { PlayerState } from './context/player';
import Maintenance from './maintenance';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { Suspense } from 'react';
import { CoreProvider } from './core';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import {Replay} from "@sentry/react";

const maintenance = process.env.REACT_APP_MAINTENANCE || '';
const environment = process.env.REACT_APP_ENVIRONMENT;

if (environment === 'production') {
  Sentry.init({
    dsn: 'https://7f4a100dffe540f99cd57dbbc043e1ee@o498991.ingest.sentry.io/4503891904430080',
    integrations: [new BrowserTracing(), new Replay()],
    environment: 'production',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.1,
  });
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <>
    {maintenance === 'true' ? (
      <Maintenance />
    ) : (
      <Sentry.ErrorBoundary>
        <Router>
          <FpjsProvider
            loadOptions={{
              apiKey: 'cyXF8QhDBNpYenF92KyX',
              region: 'eu',
            }}
          >
            <CoreProvider>
              <Suspense fallback={null}>
                <AuthState>
                  <PlayerState>
                    <App />
                  </PlayerState>
                </AuthState>
              </Suspense>
            </CoreProvider>
          </FpjsProvider>
        </Router>
      </Sentry.ErrorBoundary>
    )}
  </>
);
