import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { MovieModel } from 'src/pages/movies/types';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { BannersDataModel, BrandNewMetaModel } from './types';

type BrandNewMoviesQueryModel = {
  brandNewMovies: MovieModel[];
  areBrandNewMoviesLoading: boolean;
  refetchBrandNewMoviesQuery: () => void;
  brandNewMeta: BrandNewMetaModel;
  bannersData: BannersDataModel;
};

const useBrandNewMoviesQuery = (query: { page: number }, isAuthenticated: boolean): BrandNewMoviesQueryModel => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['brandNew_movies', isAuthenticated ? `${query.page}-hard` : `${query.page}-soft`],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl(`/movies/brandNew`, query));

      if (res.response.ok) {
        return res.body;
      }
    },
  });

  const brandNewMovies = data?.data;
  const brandNewMeta = data?.meta;
  const areBrandNewMoviesLoading = isLoading;
  const refetchBrandNewMoviesQuery = refetch;
  const bannersData = data?.bannersData;
  return { bannersData, brandNewMovies, areBrandNewMoviesLoading, refetchBrandNewMoviesQuery, brandNewMeta };
};

export default useBrandNewMoviesQuery;
