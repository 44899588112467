import styles from './BannersList.module.scss';
import { SuspenseImg } from '../../utils/imageSuspense';
import { MainSlider } from '../MainSlider';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BannersModel } from 'src/pages/home/query/types';
import {generateImageUrl} from "../../utils/cdnService";

type Props = {
  banners: BannersModel[];
  alt: string;
};

const BannersList: FC<Props> = ({ banners, alt }) => {
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();

  const handleClick = (url: string | null) => (banners[0].url ? navigate(`${language}${url}`) : null);

  if (!banners || banners?.length === 0) {
    return null;
  }

  if (banners?.length === 1) {
    return (
      <div className={styles.headerImage} onClick={() => handleClick(banners[0].url)}>
        <SuspenseImg alt={alt} src={`${generateImageUrl('gcore', 0, 0 , banners[0].image)}`} />
      </div>
    );
  }

  if (banners && banners?.length > 1) {
    return <MainSlider images={banners} />;
  }

  return null;
};

export default BannersList;
