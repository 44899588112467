import React, { FC } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import { useNavigate } from 'react-router-dom';
import { StudiosModel } from 'src/pages/movies/types';
import { SuspenseImg } from 'src/utils/imageSuspense';
import { BoughtMoviesModel } from '../../query/types';
import { URLBoughtMoviesQueryStateModel } from '../../types';
import styles from './BoughtMoviesList.module.scss';
import {generateImageUrl} from "../../../../utils/cdnService";

type Props = {
  query: URLBoughtMoviesQueryStateModel;
  handleOptionChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  areStudiosLoading: boolean;
  studios: StudiosModel[];
  formatLabel: (name: string, no: number) => string;
  selected: { label: string; value: number }[];
  handleStudioChange: (values: { label: string; value: number }[]) => void;
  boughtMovies: BoughtMoviesModel[];
  renderMenu: (movie: BoughtMoviesModel) => React.ReactNode;
};

const BoughtMoviesList: FC<Props> = ({
  selected,
  formatLabel,
  studios,
  query,
  handleOptionChange,
  areStudiosLoading,
  handleStudioChange,
  boughtMovies,
  renderMenu,
}) => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <>
      <Row>
        <Col sm="12">
          <Row>
            <Col className={styles.sortWrapper} md={3}>
              <div className={styles.sortOptions} style={{ height: '40px' }}>
                <Form.Select
                  size="sm"
                  value={(query.sort as string) || 'license.online_start'}
                  onChange={handleOptionChange}
                >
                  <option value="license.online_start">{t('filterNew')}</option>
                  <option value="-license.online_start">{t('filterOld')}</option>
                  <option value="content_data.title">{t('filterA')}</option>
                  <option value="-content_data.title">{t('filterZ')}</option>
                </Form.Select>
              </div>
            </Col>
            <Col className={styles.sortWrapper} md={3}>
              <MultiSelect
                className={styles.customSelect}
                isLoading={areStudiosLoading}
                options={
                  studios &&
                  studios.map((studio) => ({
                    label: formatLabel(studio.name, studio.content_length),
                    value: studio.id,
                  }))
                }
                value={selected}
                onChange={handleStudioChange}
                labelledBy={'Select'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {boughtMovies?.length > 1 &&
          boughtMovies?.map((item) => {
            return (
              <Col sm="12" className={styles.movieWrapper} key={item.valid_from}>
                <Row>
                  <Col sm="3">
                    <figure className={styles.movieFigure}>
                      <SuspenseImg
                        className="img-responsive"
                        src={`${generateImageUrl('gcore', 0, 0, item.image)}`}
                      />
                    </figure>
                  </Col>
                  <Col sm="9">
                    <Row>
                      <Col lg="7" md="6" sm="12" xs="12">
                        <h2 onClick={() => navigate(`/${language}/movie/${item.id}`)} className={styles.headline}>
                          {item.title}
                        </h2>
                      </Col>
                      <Col lg="5" md="6" sm="12" xs={false} style={{ textAlign: 'right' }}>
                        <div className={styles.headerButtons}>
                          <span className="d-none d-md-block" onClick={() => navigate(`/${language}/movie/${item.id}`)}>
                            <i className="fa fa-film" aria-hidden="true"></i>
                            {t('boughtMoviesPage.goToMovie')}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        {item.label && (
                          <div className={styles.infoWrapper}>
                            <div className={styles.info}>
                              <span className={styles.infoLabel}> {t('boughtMoviesPage.label')}</span>
                              <span className={styles.infoValue}> {item.label}</span>
                            </div>
                            <div className={styles.info}>
                              <span className={styles.infoLabel}> {t('boughtMoviesPage.validFrom')}</span>
                              <span className={styles.infoValue}> {item.valid_from}</span>
                              <span style={{ marginLeft: 15 }} className={styles.infoLabel}>
                                {' '}
                                {t('boughtMoviesPage.validUntil')}
                              </span>
                              <span className={styles.infoValue}> {item.valid_until}</span>
                            </div>
                          </div>
                        )}
                        <p className="mt-15">{item.description}</p>
                      </Col>
                      {renderMenu(item)}
                    </Row>
                  </Col>
                </Row>
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default BoughtMoviesList;
