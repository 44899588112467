import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { URLSearchQueryStateModel } from '../types';
import { SearchDataModel, SearchMetaModel } from './types';

type SearchQueryModel = {
  searchData: SearchDataModel[];
  searchMeta: SearchMetaModel;
  isSearchQueryLoading: boolean;
  refetchSearchQuery: () => void;
};

const useSearchQuery = (query: URLSearchQueryStateModel, isAuthenticated: boolean): SearchQueryModel => {
  const {
    data,
    isLoading,
    refetch: refetchSearchQuery,
  } = useQuery({
    queryKey: ['movie_search', isAuthenticated ? `${Object.values(query)}-hard` : `${Object.values(query)}-soft`],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/movies/search', query));
      if (!res.response.ok) {
        return res.body;
      } else {
        return res.body;
      }
    },
  });
  const isSearchQueryLoading = isLoading;
  const searchData = data?.data;
  const searchMeta = data?.meta;
  return { isSearchQueryLoading, searchData, searchMeta, refetchSearchQuery };
};

export default useSearchQuery;
