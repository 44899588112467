import React, { FC, useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import styles from './LoginPage.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from '../../context/auth';
import Alert from 'react-bootstrap/Alert';
import { LoginButton } from '../../components/UI/LoginButton';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SessionManagementModal } from '../../components/UI/Modals/SessionManagementModal';

const LoginPage: FC = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { login, error, isAuthenticated, loading, userLocation, showModal, closeSessionModal, lastLoginData } =
    authContext;
  const [form, setForm] = useState({
    username: '',
    pin: '',
  });
  const [pin, setPin] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [navigate, isAuthenticated]);

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    if (
      (form.username === '4147445602' ||
        form.username === '4955997280' ||
        form.username === '8753344193' ||
        form.username === '1000000001') &&
      pin === '1710'
    ) {
      localStorage.clear();
      navigate('/login');
      return;
    }

    if (!form.username || !pin) return;
    e.preventDefault();
    let data = {
      username: form.username,
      pin,
    };
    login(data);
  };

  const handleConfirm = () => {
    if (lastLoginData.username !== '' && lastLoginData.pin !== '') {
      const loginDataWithSessionDeletion = { ...lastLoginData, delete_sessions: true };
      login(loginDataWithSessionDeletion);
      closeSessionModal();
    }
  };

  const handlePinChange = (e: React.SyntheticEvent) => {
    const { nativeEvent } = e;
    handleTest((nativeEvent as InputEvent).data);
  };

  const handleTest = (input: string | null) => {
    const reg = new RegExp('^\\d+$');
    if (input && reg.test(input) && pin?.length < 4) {
      setPin(pin + input);
    } else if (input === null) {
      setPin(pin.slice(0, -1));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    if (e.target.name === 'username' && /^[0-9.]*$/.test(inputValue)) {
      inputValue = inputValue.replace(/\./g, '');
    }
    setForm({
      ...form,
      [e.target.name]: inputValue,
    });
  };

  return (
    <Container>
      <div className={styles.wrapper}>
        <Row>
          <Col className={styles.content} lg={6} md={7} sm={8}>
            <h2>{t('loginPage.loginForCustomer')}</h2>
            {error && (
              <Alert show={!loading as boolean} variant={'danger'}>
                {parse(error)}
              </Alert>
            )}
            <form className={styles.formWrapper} onSubmit={handleLogin}>
              <Row className={styles.formItem}>
                <Col sm={3}>
                  <p>{t('loginPage.loginCustomerNumber')}</p>
                </Col>
                <Col sm={9}>
                  <input
                    name="username"
                    autoComplete="off"
                    placeholder={t('loginPage.loginCustomerNumber')}
                    onChange={handleChange}
                    value={form.username}
                    type="text"
                  />
                </Col>
              </Row>
              <Row className={styles.formItem}>
                <Col sm={3}>
                  <p>
                    {userLocation === 'DE' ? '18+' : 'Sky'} {t('loginPage.loginCustomerPin')}
                  </p>
                </Col>
                <Col sm={9}>
                  <input
                    name="pin"
                    maxLength={4}
                    autoComplete="off"
                    placeholder={userLocation === 'DE' ? '18+ PIN' : 'Sky PIN'}
                    value={pin
                      .split('')
                      .map((item) => '⚫')
                      .join('')}
                    onChange={handlePinChange}
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 9, offset: 3 }}>
                  <LoginButton loading={loading} handleLogin={handleLogin} disabled={pin?.length < 4 ? true : false} />
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </div>
      <Row className={styles.textWrapper}>
        <Col xs={12}>
          <h2>Wie kann ich Blue Movie Kunde werden?</h2>
          <p>
            Die Blue Movie Web-Videothek ist ein Service nur für Blue Movie Kunden. <br />
            Nur Personen, die über 18 Jahre alt sind, sind berechtigt, Blue Movie Kunde zu werden. <br />
            Daher benötigen Sie, um das Blue Movie Angebot nutzen zu können, als Kunde von der Sky Deutschland Fernsehen
            GmbH & Co. KG die {userLocation === 'DE' ? '18+' : 'Sky'} PIN und als Kunde von der Sky Österreich Fernsehen
            GmbH die Sky PIN.
          </p>
          <ul>
            <li>
              Sie sind Kunde von Sky Deutschland und haben noch keine {userLocation === 'DE' ? '18+' : 'Sky'} PIN? Bitte
              klicken Sie{' '}
              <a href="https://www.bluemovie.cc/blue/cms/de/registration-identification.jsp" target='"_blank" '>
                {' '}
                hier{' '}
              </a>
              .
            </li>
            <li>
              Sie sind Kunde von Sky Österreich und haben noch keine Sky PIN? Bitte klicken Sie{' '}
              <a href="https://www.sky.at/jetzt-in-3-schritten-registrieren" target='"_blank" '>
                {' '}
                hier{' '}
              </a>
              .
            </li>
          </ul>
        </Col>
      </Row>
      {showModal && (
        <SessionManagementModal centered={true} show={showModal} onHide={closeSessionModal} onConfirm={handleConfirm} />
      )}
    </Container>
  );
};

export default LoginPage;
