import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { URLQueryStateModel } from '../types';
import { BmPlusMetaModel, BmPlusMovieModel } from './types';

type BmPlusMoviesQueryModel = {
  bmPlusMovies: BmPlusMovieModel[];
  isBmPlusQueryLoading: boolean;
  bmPlusMeta: BmPlusMetaModel;
  refetchBmPlusMoviesQuery: () => void;
};

const useBmPlusMoviesQuery = (
  query: URLQueryStateModel & { headers?: any },
  isAuthenticated?: boolean
): BmPlusMoviesQueryModel => {
  let queryData = query;
  if (queryData.headers) {
    delete queryData.headers;
  }
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['bmPlus', isAuthenticated ? `${Object.values(queryData)}-hard` : Object.values(queryData)],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/movies/bmPlus', queryData));
      if (res.response.ok) {
        return res.body;
      }
    },
  });
  const bmPlusMovies = data?.data;
  const bmPlusMeta = data?.meta;
  const isBmPlusQueryLoading = isLoading;
  const refetchBmPlusMoviesQuery = refetch;

  return { bmPlusMovies, isBmPlusQueryLoading, refetchBmPlusMoviesQuery, bmPlusMeta };
};

export default useBmPlusMoviesQuery;
