import React, { FC, useEffect, useReducer, useState } from 'react';
import { AuthContext } from '.';
import { useNavigate } from 'react-router-dom';
import {
  LOGOUT,
  CLEAR_ERRORS,
  USER_LOADED,
  AUTH_ERROR,
  SET_LOADING,
  UPDATE_USER,
  SET_USER_LOCATION,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
} from '../types';

import { fetch as fetchRedirect } from '../../utils/refetch';
import useLoginMutation from 'src/hooks/mutation/login/useLoginMutation';
import { INITIAL_STATE } from 'src/core/constants/constants';
import { headers } from 'src/hooks/constants';
import { useUserQuery } from 'src/hooks/query';
import { AuthReducer } from '.';
import { Container } from 'react-bootstrap';
import { CustomSpinner } from 'src/shared/Spinner';
import { createUrl } from 'src/core/api/utils/createUrl';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import PusherSingleton from '../../utils/pusherSingleton';

type Props = {
  children: React.ReactNode;
};

interface LastLoginData {
  username: string;
  pin: string;
}

const AuthState: FC<Props> = ({ children }) => {
  const { mutate, isLoading } = useLoginMutation();
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
  const navigate = useNavigate();
  const { user, isError, isUserLoading, isSuccess, refetchUserQuery } = useUserQuery(!!state.token);
  const { data: visitorData, isLoading: isVisitorDataLoading } = useVisitorData();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [lastLoginData, setLastLoginData] = useState<LastLoginData>({ username: '', pin: '' });

  const closeSessionModal = (): void => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!isUserLoading) {
      dispatch({
        type: SET_LOADING,
      });

      if (isSuccess)
        dispatch({
          type: USER_LOADED,
          payload: { user: user },
        });

      if (isError) {
        dispatch({
          type: AUTH_ERROR,
          payload: { error: 'Something went wrong with User' },
        });
      }
    }
  }, [isError, isSuccess, isUserLoading, user]);

  if (isUserLoading && (!!state.token || state.isAuthenticated)) {
    return (
      <Container>
        <CustomSpinner />
      </Container>
    );
  }

  const loadUser = async () => {
    refetchUserQuery();
  };

  /**
   * Logout function wrapped in refresh token functionality
   * @alias logout
   */
  const logout = () => {
    const token = localStorage.getItem('blue_token');
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    // logout
    fetchRedirect(createUrl(`/logout`))
      .then(({ body }: { body: any }) => {
        localStorage.removeItem('blue_webusername');
        localStorage.removeItem('blue_token');
        dispatch({
          type: LOGOUT,
          payload: { error: '' },
        });
        navigate('/de/logout');
      })
      .catch(() => {
        localStorage.removeItem('blue_webusername');
        localStorage.removeItem('blue_token');
        dispatch({
          type: LOGOUT,
          payload: { error: '' },
        });
        navigate('/de/logout');
      });
  };

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const updateUser = (data: { balance: number }) => {
    dispatch({
      type: UPDATE_USER,
      payload: data,
    });
  };
  const setUserLocation = (data: { userLocation: string }) => {
    dispatch({
      type: SET_USER_LOCATION,
      payload: data,
    });
  };

  const login = (loginData: { username: string; pin: string }) => {
    setLastLoginData(loginData);

    dispatch({
      type: SET_LOADING,
    });
    mutate(
      { ...loginData, visitorId: visitorData?.visitorId },
      {
        onSuccess: async (data: any) => {
          if (data.status === 'success') {
            const pusher = PusherSingleton.getInstance();
            const sessionChannel = pusher.subscribe('session_end');
            setTimeout(() => {
              sessionChannel.bind(`session_${data.data.user_id}`, function (data: any) {
                if (data.success) {
                  pusher.unsubscribe('session_end');
                  logout();
                }
              });
            }, 5000);

            localStorage.setItem('blue_webusername', loginData.username);
            dispatch({
              type: LOGIN_SUCCESS,
              payload: data.data,
            });
          }
          if (data.status === 403 && data?.body?.data?.returnCode === 'session_management') {
            setShowModal(true);
          }
        },
        onError(error: any, variables, context) {
          if (error.status === 403 && error?.body.data?.returnCode === 'session_management') {
            setShowModal(true);
          }
          dispatch({
            type: LOGIN_FAIL,
            payload: { error: error?.body.message },
          });
        },
      }
    );
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        showModal: showModal,
        lastLoginData: lastLoginData,
        closeSessionModal,
        loading: state.loading || isLoading || !!isVisitorDataLoading,
        user: state.user,
        error: state.error,
        euPortabilityStatusPopup: state.euPortabilityStatusPopup,
        euPortabilityStatusPopupText: state.euPortabilityStatusPopupText,
        firstLogin: state.firstLogin,
        userLocation: state.userLocation,
        login,
        logout,
        clearErrors,
        loadUser,
        updateUser,
        setUserLocation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
