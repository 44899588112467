import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './ConfirmModal.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

type Props = {
  show: boolean;
  onHide: () => void;
  handleSuccess: () => void;
  error: string | null | unknown;
  centered?: boolean;
};

const ConfirmModal: FC<Props> = ({ centered, show, onHide, handleSuccess, error }) => {
  return (
    <Modal centered={centered} show={show} onHide={onHide}>
      <Modal.Header closeButton className={styles.modalHeader} closeVariant="white" />
      <Modal.Body className={styles.modalBody}>
        <Row>
          <Col style={{ marginBottom: 20 }}>
            <h2>Gerät entfernen</h2>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <button onClick={handleSuccess}>Entfernen</button>
          </Col>
          <Col sm="6">
            <button onClick={onHide}>Abbrechen</button>
          </Col>
          <Col sm="12">
            {error ? (
              <Alert show={!!error} variant={'danger'}>
                <>{error}</>
              </Alert>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
