import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { URLQueryModel } from '../../types';
import { MovieTagLinks, MovieTagMetaModel, MovieTagModel } from './types';

type TagQueryModel = {
  tag: MovieTagModel[];
  isTagQueryLoading: boolean;
  refetchTagQuery: () => void;
  tagMeta: MovieTagMetaModel;
  tagLinks: MovieTagLinks[];
  tagName: string;
};

const useTagQuery = (isAuthenticated: boolean, id: string | number, query: URLQueryModel): TagQueryModel => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [`tags_${id}`, isAuthenticated ? `${Object.values(query)}-hard` : `${Object.values(query)}-soft`],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl(`/tags/${id}`, query));

      if (!res.response.ok) {
        return res.body;
      }
      return res.body;
    },
  });
  const tag = data?.data;
  const tagMeta = data?.meta;
  const isTagQueryLoading = isLoading;
  const tagLinks = data?.links;
  const refetchTagQuery = refetch;
  const tagName = data?.name;

  return { tag, isTagQueryLoading, refetchTagQuery, tagMeta, tagLinks, tagName };
};

export default useTagQuery;
