const priceParser = (price: string | number | undefined, currency = '€'): string => {
  if (!price) return `0,00 ${currency}`;

  if (typeof price === 'string') {
    if (isNaN(Number(price.trim())) || isNaN(parseFloat(price.trim()))) {
      return `0,00 ${currency}`;
    }

    return `${parseFloat(price.trim()).toFixed(2).toString().replace('.', ',')} ${currency}`;
  }

  return `${price.toFixed(2).toString().replace('.', ',')} ${currency}`;
};

export default priceParser;
