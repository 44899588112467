import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useStaticPageQuery } from 'src/hooks/query';
import Spinner from 'src/shared/Spinner/Spinner';

const UscPage: FC = () => {
  const { html, isLoading } = useStaticPageQuery('18usc');

  if (isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (html.__html) {
    return (
      <Container>
        <Row>
          <Col sm={12}>
            <div dangerouslySetInnerHTML={html} />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <h2>18 USC §2257, 2257A AND / OR 28 CFR 75 RECORD KEEPING REQUIREMENTS COMPLIANCE STATEMENT</h2>
      <Row>
        <Col sm={12}>
          <p>
            Alle Modelle, Schauspieler, Schauspielerinnen und andere Personen, die in visuellen Darstellungen von
            tatsächlichem sexuell explizitem Verhalten erscheinen, die auf dieser Website erscheinen oder anderweitig
            enthalten sind, waren zum Zeitpunkt der Erstellung solcher Darstellungen über achtzehn Jahre alt. Die
            Eigentümer und Betreiber dieser Webseite sind nicht der primäre Produzent (gemäß der Definition des Begriffs
            in 18 U.S.C. Abschnitt 2257) von den auf der Webseite enthaltenen visuellen Inhalten.
          </p>
          <p>
            Bitte richten Sie Fragen zu Inhalten auf dieser Webseite an:
            <a href="mailto:help@tmc-mediagroup.ch"> help@tmc-mediagroup.ch</a>{' '}
          </p>
          <p>
            Die gemäß 18 U.S.C. Section 2257 und 28 C. F. R. 75 erforderlichen Unterlagen bzgl. der auf der Webseite
            gezeigten Materialien werden von dem folgenden Verwalter der Unterlagen vorgehalten:
          </p>
          <p>
            tmc Media Group AG <br />
            Poststr. 24 <br />
            6300 Zug <br />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default UscPage;
