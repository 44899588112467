import { useContext } from 'react';
import { useEffect } from 'react';
import { AuthContext } from '../../../../context/auth';
import { PlayerContext } from 'src/context/player';
import { idleLogoutTime } from './constants';
import moment from 'moment';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

export const useAppLogout = () => {
  const isOnPageMsg = window.location.pathname.startsWith('/de/payment/message');
  const authContext = useContext(AuthContext);
  const { playerPaused, playerActive } = useContext(PlayerContext);
  const { isAuthenticated, logout } = authContext;
  let timeStamp;
  if (playerActive || isOnPageMsg) {
    localStorage.removeItem('blue_lastTimeStamp');
  }

  const timerInterval = (): void => {
    if (playerPaused) {
      handleLogoutTimer(null, idleLogoutTime);
      return;
    } else if (isOnPageMsg || playerActive) {
      localStorage.removeItem('blue_lastTimeStamp');
      return;
    } else {
      handleLogoutTimer(null, idleLogoutTime);
      return;
    }
  };

  let timer: NodeJS.Timeout | null = null;
  const handleLogoutTimer = (e: any, timerValue?: number) => {
    if (!timerValue) {
      return timerInterval();
    }
    if (timer) {
      timeStamp = moment();
      localStorage.setItem('blue_lastTimeStamp', timeStamp.toDate().toISOString());
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      logout();
    }, timerValue);
  };

  const handleEventListeners = () => {
    timerInterval();
    timeStamp = moment();
    localStorage.setItem('blue_lastTimeStamp', timeStamp.toDate().toISOString());
    Object.values(events).forEach((item) => {
      window.addEventListener(item, handleLogoutTimer);
    });
  };

  useEffect(() => {
    if (isAuthenticated && !playerActive && !isOnPageMsg) {
      handleEventListeners();
    } else if (playerPaused && !isOnPageMsg) {
      handleEventListeners();
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
        Object.values(events).forEach((item) => {
          window.removeEventListener(item, handleLogoutTimer);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, playerPaused, playerActive, isOnPageMsg]);

  return { handleLogoutTimer };
};
