import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { createUrl } from 'src/core/api/utils/createUrl';
import { PaymentModel } from 'src/pages/blueMoviePlus/query/types';
import { fetch as fetchRedirect } from 'src/utils/refetch';

type PaymentQueryModel = {
  payment: PaymentModel;
  isPaymentLoading: boolean;
  refetchPaymentQuery: () => void;
  paymentError: boolean;
};

const useDepositPaymentQuery = (isAuthenticated?: boolean, query?: { deposit_page: boolean }): PaymentQueryModel => {
  const [paymentError, setPaymentError] = useState(false);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['deposit_payment'],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/payment/initialize', query));
      if (res.response.ok) {
        return res.body;
      } else {
        setPaymentError(true);
      }
    },
    enabled: isAuthenticated,
  });
  const payment = data?.data;
  const isPaymentLoading = isLoading;
  const refetchPaymentQuery = refetch;
  return { payment, isPaymentLoading, refetchPaymentQuery, paymentError };
};

export default useDepositPaymentQuery;
