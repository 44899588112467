import { PAUSE_PLAYER, START_PLAYER, STOP_PLAYER } from '../types';
import { PlayerActionModel, PlayerInitialStateModel } from './utils/types';

const playerReducer = (state: PlayerInitialStateModel, action: PlayerActionModel) => {
  const { type } = action;
  switch (type) {
    case START_PLAYER: {
      return {
        ...state,
        playerActive: true,
        playerPaused: false,
      };
    }
    case PAUSE_PLAYER: {
      return {
        ...state,
        playerPaused: true,
      };
    }
    case STOP_PLAYER: {
      return {
        ...state,
        playerActive: false,
        playerPaused: false,
      };
    }
    default:
      return state;
  }
};

export default playerReducer;
