import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const UserDetails: FC = () => {
  return (
    <Container>
      USER DETAILS PAGE
      <Row></Row>
    </Container>
  );
};

export default UserDetails;
