import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './DataProtection.module.scss';
import Spinner from '../../../shared/Spinner/Spinner';
import { useStaticPageQuery } from 'src/hooks/query';

const DataProtection: FC = () => {
  const { html, isLoading } = useStaticPageQuery('data protection');
  if (isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (html.__html) {
    return (
      <Container>
        <Row>
          <Col sm={12}>
            <div dangerouslySetInnerHTML={html} />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <h2>DATENSCHUTZERKLÄRUNG</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <p>
            Der Schutz Ihrer persönlichen Daten und Ihrer Privatsphäre ist uns, tmc Media Group AG, sehr wichtig. Diese
            Datenschutzerklärung gilt für personenbezogene Daten, die wir von Ihnen verarbeiten, um Ihnen unsere
            Produkte und Services auf unseren Webseiten zur Verfügung zu stellen. Im Folgenden finden Sie Informationen
            dazu, wer wir sind und wie Sie uns kontaktieren können. Wir erklären auch, wie und warum wir
            personenbezogene Daten von Ihnen verarbeiten, an wen wir sie übermitteln und wie lange wir sie speichern.
            Abschließend informieren wir Sie über die Rechte, die Ihnen hinsichtlich der Verarbeitung Ihrer
            personenbezogenen Daten durch uns zustehen.
          </p>

          <h2>1. Wer wir sind und wie Sie uns kontaktieren können</h2>
          <p>
            Wir, die tmc Media Group AG, Poststraße 24, CH-6300 Zug, vertreten durch die Geschäftsführerin Manuela
            Stöckli, sind Verantwortlicher für die Verarbeitung Ihrer personenbezogenen Daten im Zusammenhang mit der
            Nutzung unserer Produkte und Services auf unseren Webseiten.
          </p>

          <p>
            Wir haben einen Datenschutzbeauftragten bestellt, den Sie unter service shop Hein, Bernd Hein,
            Friedrichsorter Straße 11, 24159 Kiel, Deutschland, Telefon: +49 431-13027, E-Mail{' '}
            <a href="mailto:datenschutz@tmc-mediagroup.ch">datenschutz@tmc-mediagroup.ch</a>, erreichen können.
          </p>

          <h2>2. Wie und warum wir Ihre personenbezogenen Daten verarbeiten</h2>
          <p>
            Im Folgenden informieren wir Sie darüber, welche personenbezogenen Daten wir von Ihnen verarbeiten, wenn Sie
            unsere Produkte und Services auf unseren Webseiten nutzen. Wir erklären außerdem, zu welchem Zweck wir diese
            Daten nutzen und auf welcher rechtlichen Grundlage.
          </p>

          <p>
            <strong>2.1.</strong> Daten, die wir für die Erfüllung eines Vertrages verarbeiten, Art. 6 Abs. 1b) DS-GVO
          </p>
          <p>
            <strong>2.1.1</strong> Kontaktaufnahme
          </p>
          <p>
            Sofern Sie mit uns (z.B. über unser Kontaktformular oder per Email) Kontakt aufnehmen, verarbeiten und
            speichern wir die in diesem Zusammenhang angegebenen personenbezogenen Daten wie Name, Email – Adresse und
            Kundennummer, um Ihr Anliegen zu bearbeiten.
          </p>
          <p>
            Die Bereitstellung von Kontakt- und (sofern Sie bei uns Kunde sind) von Vertragsdaten wie Ihre Kundennummer
            ist erforderlich, um Ihr Anliegen beantworten zu können. Andernfalls könnten wir Ihre Anfrage leider nicht
            bzw. nicht zufriedenstellend bearbeiten.
          </p>

          <p>
            <strong>2.1.2</strong> Anmeldung/Login
          </p>
          <p>
            Als Sky-Kunde können Sie sich auch mittels Ihrer Kundennummer und Ihrer 18+-PIN/Sky-PIN auf den Webseiten
            einloggen. Beim ersten Login wird Ihre Kundennummer von uns gespeichert.
          </p>
          <p>Wir benötigen diese Daten, um Ihnen den Service auf dieser Website anbieten zu können.</p>
          <p>Wenn wir diese Daten nicht speichern, können wir Ihnen diesen Service nicht ordnungsgemäß anbieten.</p>

          <p>
            <strong>2.1.3</strong> Vertrag mit tmc Media Group AG / Durchführung einer Zahlung
          </p>
          <p>
            Als Sky-Kunde können Sie sich auch mittels Ihrer Kundennr und Ihrer 18+-Pin/Sky-PIN auf den Webseiten
            einloggen und dort Einzahlungen auf Ihr Kundenkonto vornehmen.
          </p>
          <p>
            Wenn Sie über unsere Webseiten eine Zahlung durchführen, geben Sie Ihre Daten (z.B. Name, Postanschrift,
            Telefonnummer, Bezahlweise und Bezahldaten) zum Zweck der Zahlungsabwicklung in Formulare der Heidelberger
            Payment GmbH ein, die in unsere Webseiten eingebettet sind. Beim Absenden der Formulare werden diese Daten
            von Heidelberger Payment GmbH verarbeitet, gespeichert und an uns übermittelt.
          </p>
          <p>
            Wir speichern und verarbeiten diese Daten in Ihrem Kundenkonto, um Ihnen einen umfangreichen Service rund um
            Ihre Einzahlungen anbieten zu können. Andernfalls könnten wir Ihre Anfragen leider nicht bzw. nicht
            zufriedenstellend bearbeiten.
          </p>
          <p>
            Bei Zahlung per Kreditkarte, SEPA-Lastschrift und Sofortüberweisung via Heidelpay erfolgt die
            Zahlungsabwicklung über den Zahlungsdienstleister
          </p>
          <p>
            Heidelberger Payment GmbH
            <br />
            Vangerowstraße 18
            <br />
            69115 Heidelberg
            <br />
            Deutschland
            <br />
            Amtsgericht Heidelberg: HRB 337681
            <br />
            Geschäftsführer: Dipl. Vw. Mirko Hüllemann, Tamara Huber.
          </p>
          <p>
            an den wir Ihre im Rahmen des Bestellprozesses mitgeteilten Daten ausschließlich zum Zwecke der
            Zahlungsabwicklung gemäß Art. 6 Abs. 1 lit. b DSGVO weitergeben. Die Weitergabe erfolgt nur, soweit sie für
            die Zahlungsabwicklung tatsächlich erforderlich ist.
          </p>
          <p>
            Bei Zahlung per PayPal und Kreditkarte via EPOCH erfolgt die Zahlungsabwicklung über den
            Zahlungsdienstleister
          </p>
          <p>
            EPOCH
            <br />
            3110 Main Street, Suite 220
            <br />
            Santa Monica, CA, 90405
            <br />
            PHONE: +1 310 664 5700
            <br />
            Billing Support:{' '}
            <a href="https://epoch.com" rel="noopener noreferrer" target="_blank">
              https://epoch.com
            </a>
          </p>
          <p>
            an den wir Ihre im Rahmen des Bestellprozesses mitgeteilten Daten ausschließlich zum Zwecke der
            Zahlungsabwicklung gemäß Art. 6 Abs. 1 lit. b DSGVO weitergeben. Die Weitergabe erfolgt nur, soweit sie für
            die Zahlungsabwicklung tatsächlich erforderlich ist.
          </p>

          <p>
            <strong>2.1.4</strong> Abruf von kostenpflichtigen Inhalten
          </p>
          <p>
            Wenn Sie auf unseren Webseiten kostenpflichtige Inhalte abrufen, speichern wir den abgerufenen Inhalt in
            Ihrem Kundenkonto.
          </p>
          <p>
            Die Speicherung dieser Daten ist erforderlich, um eine Abrechnung der abgerufenen Inhalte ordnungsgemäß
            durchführen zu können. Weiterhin um Ihnen Inhalte, die Sie für einen bestimmten Zeitraum erwerben, bei einem
            erneuten Abruf innerhalb des angegebenen Zeitraumes kostenlos zur Verfügung zu stellen. Wenn wir diese Daten
            nicht speichern, könnten wir Ihnen diese Möglichkeit nicht bzw. nicht ordnungsgemäß anbieten.
          </p>

          <p>
            <strong>2.1.5</strong> Teilnahme an Gewinnspielen
          </p>
          <p>
            Gelegentlich werden auf den Webseiten Gewinnspiele angeboten. Wir verarbeiten und speichern Ihre
            personenbezogenen Daten, die Sie bei der Teilnahme an einem Gewinnspiel angegeben haben, für die
            Durchführung des Gewinnspiels. Es gelten in diesem Fall die jeweiligen Teilnahmebedingungen und
            Datenschutzerklärung des Gewinnspiels, auf die Sie im Rahmen des jeweiligen Gewinnspiels gesondert
            hingewiesen werden. Wenn Sie uns die hierfür erforderlichen Daten, z.B. Kontaktdaten zur
            Gewinnbenachrichtigung, nicht zur Verfügung stellen, könnten wir das Gewinnspiel nicht bzw. nicht
            ordnungsgemäß durchführen.
          </p>

          <h2>3. An wen wir personenbezogene Daten übermitteln</h2>
          <p>
            Im diesem Absatz erklären wir, an wen wir personenbezogene Daten im Zusammenhang mit der Nutzung unserer
            Produkte und Services auf unseren Webseiten übermitteln und, sofern wir Daten in ein Drittland bzw. an eine
            internationale Organisation übermitteln, wie wir das tun.
          </p>

          <p>
            <strong>3.1</strong> An wen wir Daten im Rahmen der Abwicklung des Vertrages übermitteln
          </p>
          <p>Abhängig vom jeweiligen Angebot übermitteln wir personenbezogene Daten an folgende Empfänger:</p>

          <table className={styles.table}>
            <tbody>
              <tr>
                <td
                  style={{
                    width: '25%',
                    padding: '10px',
                  }}
                >
                  <strong>Empfänger</strong>
                </td>
                <td
                  style={{
                    width: '25%',
                    padding: '10px',
                  }}
                >
                  <strong>Zweck der Übermittlung</strong>
                </td>
                <td
                  style={{
                    width: '50%',
                    padding: '10px',
                  }}
                >
                  <strong>Übermittlung in ein Drittland bzw. an eine internationale Organisation</strong>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  Öffentliche Stellen
                </td>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  Zur Erfüllung vorrangiger gesetzlicher Verpflichtungen
                </td>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  Eine Übermittlung Ihrer personenbezogenen Daten in Drittstaaten wird derzeit nicht durchgeführt und
                  ist auch nicht geplant
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>3.2</strong> An wen wir Daten beim Einsatz von Webtracking – Tools übermitteln
          </p>
          <p>
            Detaillierte Informationen dazu, welche Anbieter wir beim Einsatz vom Webtracking – Tools wie z.B. Cookies
            einsetzen und welche Daten in diesem Zusammenhang verarbeitet werden, finden Sie in Ziffer 5.
          </p>

          <h2>4. Wie lange wir Ihre personenbezogenen Daten speichern</h2>
          <p>
            Im Folgenden möchten wir Sie darüber informieren, wie lange wir personenbezogene Daten von Ihnen speichern
            oder, falls die Angabe eines konkreten Zeitraums nicht möglich ist, wie die Kriterien für die Festlegung der
            Speicherdauer sind.
          </p>
          <p>
            Wir bewahren Ihre Daten über die folgenden Zeiträume auf; nach Ablauf dieser Fristen werden die
            entsprechenden Daten routinemäßig gelöscht.
          </p>

          <table className={styles.table}>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  <strong>Art der Daten</strong>
                </td>
                <td>
                  <strong>Aufbewahrungsfrist</strong>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  Vertragsdaten (z.B. Kaufgegenstand, Name, Postanschrift, E-Mail-Adresse, Bezahlweise und Zahldaten)
                </td>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  10 Jahre, beginnend mit Ende des Kalenderjahres, in dem der Vertrag beendet/der Dienst letztmalig
                  genutzt wurde
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  Geschäftsbriefe (z.B. Briefpost, Emails)
                </td>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  6 Jahre, beginnend mit Ende des Kalenderjahres, in dem der Geschäftsbrief empfangen bzw. abgesandt
                  worden ist
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            Sofern Daten hiervon nicht berührt sind, werden die Daten gelöscht, wenn die Zwecke wegfallen, für die sie
            erhoben wurden, oder wenn Sie eine Einwilligung, auf der eine Datenverarbeitung beruht, widerrufen.
          </p>
          <p>
            Für den Fall, dass eine Löschung aus rechtlichen, technischen oder organisatorischen Gründen nicht oder nur
            mit unverhältnismäßigem Aufwand möglich ist, wird die Verarbeitung Ihrer Daten eingeschränkt.
          </p>

          <h2>
            5. Welche Webtracking – Tools (Cookies und sonstigen Technologien) wir einsetzen und wie Sie der Verwendung
            widersprechen können
          </h2>
          <p>
            Beim Besuch unserer Webseite können Cookies gesetzt werden, um unser Webseitenangebot nutzungsfreundlicher
            für Sie zu gestalten. Cookies sind kleine Textdateien, die auf der Festplatte Ihres Computers abgelegt
            werden und die eine Wiedererkennung ermöglichen, aber keine persönliche Identifikation Ihrer Person
            zulassen. Es gibt zwei Arten von Cookies, sog. „Session-Cookies“, die so lange auf Ihrem Endgerät bleiben,
            bis Sie den Browser schließen, und sog. „Permanente Cookies“, die für eine bestimmte Zeitspanne auf Ihrem
            Endgerät gespeichert bleiben, auch nachdem Sie den Browser geschlossen haben.
          </p>
          <p>
            Mit dem gleichen Ziel werden auch Web-Beacons (oder sogenannte Zählpixel) verwendet. Web-Beacons sind kleine
            Grafiken, die eine Logdatei- Aufzeichnung und eine Logdateienanalyse ermöglichen und so zu statistischen
            Auswertungen verwendet werden.
          </p>
          <p>
            Im Folgenden finden Sie eine Übersicht der von uns eingesetzten Webtracking - Tools sowie Informationen
            dazu, zu welchem Zweck wir die Webtracking Tools verwenden und wie Sie diese deaktivieren, sollten Sie die
            hierfür erforderliche Datenverarbeitung nicht wünschen („Opt - Out“). Allgemeine Informationen dazu, wie Sie
            das Speichern von Cookies verhindern bzw. verwalten können, finden Sie unter Ziff. 5.2.
          </p>

          <p>
            <strong>5.1</strong> Webtracking – Tools, die wir einsetzen
          </p>

          <table className={styles.table}>
            <tbody>
              <tr>
                <td
                  style={{
                    width: '30%',
                    padding: '10px',
                  }}
                >
                  <strong>Zweck</strong>
                </td>
                <td
                  style={{
                    width: '20%',
                    padding: '10px',
                  }}
                >
                  <strong>Tool / Anbieter</strong>
                </td>
                <td
                  style={{
                    width: '25%',
                    padding: '10px',
                  }}
                >
                  <strong>Beschreibung</strong>
                </td>
                <td
                  style={{
                    width: '25%',
                    padding: '10px',
                  }}
                >
                  <strong>Opt-Out</strong>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  Optimierung der Webseite, z.B. um festzustellen, welche Inhalte bei den Nutzern der Webseite
                  beliebt/weniger beliebt sind.
                </td>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  Google LLC
                  <br />
                  1600 Amphitheatre Parkway
                  <br />
                  Mountain View, CA 94043
                  <br />
                  USA
                  <br />
                  Tel: +1 650 253 0000
                  <br />
                  Fax: +1 650 253 0001
                  <br />
                  E-Mail: <a href="mailto:support-de@google.com">support-de@google.com</a>
                </td>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  Permanenter Cookie mit einer Lebensdauer von 2 Jahren.
                </td>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  <a href="https://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener noreferrer" target="_blank">
                    https://tools.google.com/dlpage/gaoptout?hl=de
                  </a>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  Messverfahren zur Ermittlung statistischer Kennwerte über die Nutzung unserer Angebote
                  (Reichweitenmessung um die Nutzungsintensität, die Anzahl der Nutzer einer Webseite und das
                  Surfverhalten statistisch – auf Basis eines einheitlichen Standardverfahrens - zu bestimmen und somit
                  marktweit vergleichbare Werte zu erhalten)
                </td>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  Google LLC
                  <br />
                  1600 Amphitheatre Parkway
                  <br />
                  Mountain View, CA 94043
                  <br />
                  USA
                  <br />
                  Tel: +1 650 253 0000
                  <br />
                  Fax: +1 650 253 0001
                  <br />
                  E-Mail: <a href="mailto:support-de@google.com">support-de@google.com</a>
                </td>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  Permanenter Cookie mit einer Lebensdauer von 2 Jahren.
                </td>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  <a href="https://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener noreferrer" target="_blank">
                    https://tools.google.com/dlpage/gaoptout?hl=de
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>5.2</strong> Wie Sie der Verwendung von Webtracking – Tools widersprechen können
          </p>
          <p>
            <strong>5.2.1</strong> Widerspruch über Browsereinstellungen
          </p>
          <p>
            Neben den unter Ziffer 4.1 genannten individuellen Widerspruchsmöglichkeiten können Sie über die
            Einstellungen des von Ihnen verwendeten Browsers das Speichern von Cookies generell verhindern oder
            einstellen, dass Sie dem Setzen von Cookies zustimmen müssen. Sie können auch einmal gesetzte Cookies im
            Nachhinein jederzeit wieder löschen. Falls Sie mehrere Browser benutzen, müssen Sie diese Einstellungen in
            jedem Ihrer Browser vornehmen. Bitte beachten Sie insofern die Anleitung Ihres jeweiligen
            Browser-Herstellers. Wenn Sie insgesamt das Setzen von Cookies ablehnen, kann es zu
            Funktionsbeeinträchtigungen bei der Nutzung dieser Webseite kommen.
          </p>
          <p>
            Bitte beachten Sie, dass der Widerspruch von Cookies technisch in aller Regel durch das Setzen eines
            Widerspruchs-Cookies gelöst wird. Es wird also ein weiterer Cookie auf Ihrem Endgerät gespeichert, der
            verhindert, dass relevante Informationen an den Webseitenbetreiber übermittelt werden. Wenn Sie also die auf
            Ihrem Endgerät gespeicherten Cookies löschen, so löschen Sie auch diese Widerspruchs-Cookies. Wird später
            erneut ein Cookie - z.B. zur Webseitenoptimierung – gesetzt, so werden wieder Informationen an den
            Webseitenbetreiber übermittelt.
          </p>

          <h2>6. Ihre Rechte</h2>
          <p>
            Im Folgenden möchten wir Sie auf die Rechte, die Ihnen gem. Art. 15ff DS-GVO im Hinblick auf die
            Verarbeitung Ihrer personenbezogenen Daten zustehen, hinweisen:
          </p>
          <ul>
            <li>
              Sie haben das Recht, unentgeltlich Auskunft über Ihre von uns gespeicherten personenbezogenen Daten zu
              verlangen.
            </li>
            <li>
              Sie haben das Recht, die Berichtigung, Löschung („Recht auf Vergessenwerden“) oder Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Sie haben das Recht, einer Datenverarbeitung, die zur Wahrung berechtigter Interessen von tmc Media Group
              AG oder eines Dritten erforderlich ist, oder die zum Zweck der Direktwerbung erfolgt, zu widersprechen.
            </li>
            <li>
              Sie haben das Recht, die Sie betreffenden Daten in einem strukturierten, gängigen und maschinenlesbaren
              Format zu erhalten (sog. „Recht auf Datenübertragbarkeit“).
            </li>
          </ul>

          <p>
            Sofern Sie eines dieser Rechte ausüben möchten, kontaktieren Sie uns bitte wie unter Ziffer 1 beschrieben.
            Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten durch tmc
            Media Group AG einschlägigen gesetzlichen Bestimmungen widerspricht, können Sie sich auch an die zuständige
            Aufsichtsbehörde wenden.
          </p>

          <p>Version 1.1, Stand März 2019</p>
        </Col>
      </Row>
    </Container>
  );
};

export default DataProtection;
