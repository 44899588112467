import Fingerprint2 from 'fingerprintjs2';
import UAParser from 'ua-parser-js';

/**
 * Generate gingerptint and return object
 * @alias generateFingerPrint
 * @returns {Promise<unknown>}
 */
export const generateFingerPrint = async () => {
  // const components = await Fingerprint2.getPromise(options)

  return new Promise(function (resolve, reject) {
    return new Fingerprint2().get(function (result: any, components: any) {
      const finished = {};
      const fp = Fingerprint2();
      for (var i = 0; i < components.length; i++) {
        var key = components[i].key;
        var value;

        if (components[i].value === undefined) {
          value = 'undefined';
        }
        if (typeof components[i].value === 'object') {
          value = components[i].value.join(';');
        } else {
          value = components[i].value;
        }
        if (
          key === 'plugins' ||
          key === 'canvas' ||
          key === 'webgl' ||
          key === 'js_fonts' ||
          key === 'regular_plugins' ||
          key === 'ie_plugins' ||
          key === 'fonts'
        ) {
          value = fp.x64hash128(value, 31);
        }

        if (key === 'user_agent') {
          const parser = new UAParser(value);
          const userAgentMinusVersion = {
            osName: parser.getOS().name || 'undefined',
            osVersion: parser.getOS().version || 'undefined',
            cpu: parser.getCPU().architecture || 'undefined',
            browserName: parser.getBrowser().name || 'undefined',
            browserVersion: parser.getBrowser().version || 'undefined',
            deviceModel: parser.getDevice().model || 'undefined',
            deviceType: parser.getDevice().type || 'undefined',
            deviceVendor: parser.getDevice().vendor || 'undefined',
            engineName: parser.getEngine().name || 'undefined',
            engineVersion: parser.getEngine().version || 'undefined',
          };
          for (var innerkey in userAgentMinusVersion) {
            finished[innerkey] = userAgentMinusVersion[innerkey];
          }
        }
        finished[key] = value;
      }
      resolve(finished);
    });
  });
};
