import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { MetaModel, MovieModel } from '../types';
import { fetch as fetchRedirect } from 'src/utils/refetch';

interface IMoviesModel {
  movies: MovieModel[];
  meta: MetaModel;

  isMovieLoading: boolean;
  refetchMovies: () => void;
}

const useMoviesQuery = (query: any, isAuthenticated?: boolean): IMoviesModel => {
  let queryData = query;
  if (!queryData.sort) {
    delete queryData.sort;
  }
  if (!queryData.studio) {
    delete queryData.studio;
  }
  if (!queryData.duration) {
    delete queryData.duration;
  }
  const {
    data,
    isLoading: isMovieLoading,
    refetch: refetchMovies,
  } = useQuery({
    queryKey: ['all_movies', isAuthenticated ? `${Object.values(queryData)}-hard` : Object.values(queryData)],
    queryFn: async () => {
      const response: any = await fetchRedirect(createUrl('/movies', queryData));
      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
  });
  const movies = data?.data;
  const meta = data?.meta;
  return { movies, meta, isMovieLoading, refetchMovies };
};

export default useMoviesQuery;
