import React, { FC, useCallback, useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './OrderModal.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from '../../../../context/auth';
import { useCheckPinMutation } from './mutation';
import { useGetUsername } from 'src/utils';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../Header';

type Props = {
  location: string;
  balance: number | string;
  price: number;
  centered: boolean;
  show: boolean;
  selectedButton: string;
  onHide: () => void;
  handleSuccess: () => void;
  rent?: boolean;
  streamLimitReached?: boolean;
};

const OrderModal: FC<Props> = ({
  location,
  balance,
  price,
  centered,
  show,
  onHide,
  handleSuccess,
  selectedButton,
  rent,
  streamLimitReached,
}) => {
  const [error, setError] = useState<string | unknown>('');
  const authContext = useContext(AuthContext);
  const { userLocation } = authContext;
  const [checkbox1, setCheckbox1] = useState<boolean>(false);
  const [checkbox2, setCheckbox2] = useState<boolean>(false);
  const [checkbox3, setCheckbox3] = useState<boolean>(false);
  const [errors, setErrors] = useState<boolean>(false);
  const username = useGetUsername();
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { t } = useTranslation();

  const [pin, setPin] = useState('');
  const [pinReadonly, setPinReadonly] = useState(true);
  const { mutate, isLoading } = useCheckPinMutation();

  const handlePinChange = (e: React.SyntheticEvent) => {
    const { nativeEvent } = e;
    handleTest((nativeEvent as InputEvent).data);
  };

  console.log(streamLimitReached, 'streamLimitReached');

  const handleTest = (input: string | null) => {
    const reg = new RegExp('^\\d+$');
    if (input && reg.test(input) && pin?.length < 4) {
      setPin(pin + input);
    } else if (input === null) {
      setPin(pin.slice(0, -1));
    }
  };

  const handleNext = (e: any) => {
    e.preventDefault();
    if (!checkbox1 || !checkbox2 || !checkbox3) {
      setErrors(true);
      return;
    } else if (pin?.length < 4) {
      return;
    }

    try {
      mutate(
        {
          pin: pin,
          username: username,
        },
        {
          onSuccess(data: any) {
            setIsDisabled(true);
            if (data?.status === 'success') {
              if (typeof balance === 'number' ? balance < price : parseFloat(balance) < price) {
                navigate('/de/deposit', {
                  state: {
                    prevPath: location,
                  },
                });
              } else {
                handleSuccess();
              }
            }
          },
          onError(error: any) {
            setError(error?.body.message);
          },
        }
      );
    } catch (e) {
      setError(e.message);
    }
  };

  const handleHide = () => {
    setCheckbox1(false);
    setCheckbox2(false);
    setCheckbox3(false);
    setErrors(false);
    setPin('');
    setError('');
    onHide();
  };

  const isInvalid = useCallback(() => {
    if (pin?.length < 4 || !checkbox1 || !checkbox2 || !checkbox3 || isLoading) {
      return true;
    }
    return false;
  }, [checkbox1, checkbox2, checkbox3, pin?.length]);

  return (
    <Modal centered={centered} show={show} onHide={handleHide}>
      <Modal.Header closeButton className={styles.modalHeader} closeVariant="white" />
      <Modal.Body className={styles.modalBody}>
        <Row>
          <Col>
            <h2>FILM BESTELLEN</h2>
          </Col>
          <Col className="mb-15 col-sm-12">
            <Row>
              <Col className={styles.bodyLeft} xs={5}>
                Preis:
              </Col>
              <Col xs={7}>{price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</Col>
              <Col className={styles.bodyLeft} xs={5}>
                inkl. MwSt.:
              </Col>
              <Col xs={7}>1,33 €</Col>
              <Col className={styles.bodyLeft} xs={5}>
                Freischaltung:
              </Col>
              <Col xs={8} sm={5}>
                {rent
                  ? 'Sie erwerben das Recht diesen Film für 48 Stunden erneut ohne weitere Kosten im Stream anzuschauen.'
                  : ' Sie erwerben das Recht diesen Film für 48 Stunden erneut ohne weitere Kosten herunterzuladen'}
              </Col>
            </Row>
            <Row className="mt-5">
              <Col className={styles.conent}>
                <div className="mb-20">
                  <div>
                    <label className={styles.labelOrder} htmlFor="agb">
                      <input
                        onChange={() => setCheckbox1(!checkbox1)}
                        required
                        type="checkbox"
                        id="agb"
                        name="agb"
                        // value={checkbox1}
                        className="agb_checkbox"
                      />
                      <span className={styles.classCheck}></span>
                      <span className="">
                        Ich habe die{' '}
                        <a href="/de/agb" target='"_blank"'>
                          {' '}
                          AGB{' '}
                        </a>{' '}
                        gelesen und stimme diesen zu.
                      </span>
                    </label>
                  </div>

                  <div>
                    <label className={styles.labelOrder} htmlFor="cancellation-terms">
                      <input
                        required
                        type="checkbox"
                        id="cancellation-terms"
                        name="cancellation-terms"
                        className="cancellation-terms_checkbox"
                        // value={checkbox2}
                        onChange={() => setCheckbox2(!checkbox2)}
                      />
                      <span className={styles.classCheck}></span>
                      <span className="">
                        Ich nehme die{' '}
                        <a href="/de/cancellation-terms" target='"_blank"'>
                          {' '}
                          Widerrufsbelehrung{' '}
                        </a>{' '}
                        zur Kenntnis.
                        <br />
                        Ich stimme zu, dass tmc Media Group AG bei Bestellungen mit Programmstart vor Ablauf der
                        14tägigen Widerrufsfrist zum Startzeitpunkt mit der Ausführung beginnt. Mit Beginn des Vertrages
                        verliere ich mein Widerrufsrecht.{' '}
                      </span>
                    </label>
                  </div>

                  <div>
                    <label className={styles.labelOrder} htmlFor="tmc-abo">
                      <input
                        required
                        type="checkbox"
                        id="tmc-abo"
                        name="tmc-abo"
                        onChange={() => setCheckbox3(!checkbox3)}
                        // value={checkbox3}
                        className="tmc-abo_checkbox"
                      />
                      <span className={styles.classCheck}></span>
                      <span className="">
                        Sollte die tmc das Abonnement (Monats- und Jahresticket) zu einem späteren Zeitpunkt mit allen
                        Rechten und Pflichten auf die Sky Deutschland Fernsehen GmbH &amp; Co. KG, Medienallee 26, 85774
                        Unterföhring („Sky“) übertragen, stimme ich bereits jetzt hiermit dieser Vertragsübertragung zu.
                      </span>
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              {errors && (!checkbox1 || !checkbox2 || !checkbox3) && (
                <div className={styles.bgDanger}>
                  {errors && !checkbox1 && (
                    <p className="mt-2">
                      <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Bitte bestätigen Sie, dass Sie
                      die AGB gelesen und zugestimmt haben.
                    </p>
                  )}
                  {errors && !checkbox2 && (
                    <p className="mt-2">
                      <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Bitte bestätigen Sie, dass Sie
                      die Widerrufsbelehrung zur Kenntnis genommen haben.
                    </p>
                  )}
                  {errors && !checkbox3 && (
                    <p className="mt-2">
                      <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Bitte bestätigen Sie, dass Sie
                      die Abonnement Übertragung zur Kenntnis genommen haben.
                    </p>
                  )}
                </div>
              )}
            </Row>
            <Row className="mt-2">
              <div>
                {(selectedButton === 'hd' || selectedButton === 'sd') && (
                  <p className={styles.conent}>
                    Der Inhalt ist nach dem Download nicht zugriffsbeschränkt. Er darf Minderjährigen unter keinen
                    Umständen zugänglich gemacht werden.
                  </p>
                )}
                <p className="mt-2">
                  Um diesen Film ansehen zu können, müssen Sie hier Ihre {userLocation === 'DE' ? '18+' : 'Sky'} PIN
                  eingeben.
                </p>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <form autoComplete="off" onSubmit={handleNext}>
              <input
                name="pin"
                placeholder={userLocation === 'DE' ? '18+ PIN' : 'Sky PIN'}
                maxLength={4}
                value={pin
                  .split('')
                  .map((item) => '⚫')
                  .join('')}
                onChange={handlePinChange}
                autoComplete="off"
                onFocus={() => setPinReadonly(false)}
                readOnly={pinReadonly}
                type="text"
              />
            </form>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm="6">
            <Button className={styles.red}>abbrechen</Button>
          </Col>
          <Col sm="6">
            <Button disabled={isInvalid() || isLoading || isDisabled} onClick={handleNext}>
              kostenpflichtig bestellen
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {error ? (
              <Alert show={!!error} variant={'danger'}>
                {<>{error}</>}
              </Alert>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {streamLimitReached && (
              <div className={styles.error}>
                <h3>{t('orderModal.limitReached')}</h3>
                <p>{t('orderModal.limitReachedText')}</p>
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default OrderModal;
