import Col from 'react-bootstrap/Col';
import styles from './Product.module.scss';
import React, { FC } from 'react';
import { ActiveProductsModel } from '../../query/types';
import {generateImageUrl} from "../../../../utils/cdnService";

type Props = {
  product: ActiveProductsModel;
  columnWidth: number;
  onSelected: (product: any) => void;
  selectedTicket: { id: number };
};

const Product: FC<Props> = ({ product, columnWidth, onSelected, selectedTicket }) => {
  return (
    <Col
      sm={columnWidth}
      className={`${styles.ticketWrapper}`}
      onClick={() => {
        localStorage.setItem('blue_price', JSON.stringify(product.price));
        onSelected(product);
      }}
    >
      <div style={{ position: 'relative' }}>
        {selectedTicket.id === product.id && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
            }}
            className={styles.checkMark}
          >
            <i className="fa fa-check" aria-hidden="true"></i>
          </div>
        )}
        <div style={{ textAlign: 'center' }} className={`${selectedTicket.id === product.id ? styles.selected : null}`}>
          <img alt="url-img" src={`${generateImageUrl('gcore', 344, 165, product.asset.image)}`} />
        </div>
      </div>

      {product.asset && product.asset.translation && selectedTicket.id === product.id ? (
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ fontSize: '1.4em', fontWeight: 700 }}>
            <i style={{ marginRight: 10 }} className="fa fa-ticket" aria-hidden="true" />
            {product.asset.translation.title.split('für')[0]}für
          </h3>
          <p className={styles.price}>{product.asset.translation.title.split('für')[1]}</p>
          <p style={{ fontSize: '0.8rem' }}>{product.asset.translation.price_text}</p>
        </div>
      ) : (
        ''
      )}
    </Col>
  );
};

export default Product;
