import React, { FC, Suspense, useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './BrandNewPage.module.scss';
import { MoviesList } from '../../components/MoviesList';
import { CustomSpinner } from '../../shared/Spinner';
import { useTranslation } from 'react-i18next';
import { BannersList } from '../../components/Banners';
import { AuthContext } from '../../context/auth';
import ReactPaginate from 'react-paginate';
import useBrandNewMoviesQuery from './query/useBrandNewMoviesQuery';

const BrandNewPage: FC = () => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;
  const [query, setQuery] = useState({
    page: 1,
  });
  const { bannersData, brandNewMovies, areBrandNewMoviesLoading, brandNewMeta } = useBrandNewMoviesQuery(
    query,
    isAuthenticated
  );

  const handlePageChange = (data: { selected: number }) => {
    const selectedPage = data.selected + 1;
    setQuery({
      ...query,
      page: selectedPage,
    });
  };
  const renderPagination = () => {
    if (brandNewMeta && !areBrandNewMoviesLoading) {
      return (
        <ReactPaginate
          previousLabel={<i className="fa fa-chevron-left" />}
          nextLabel={<i className="fa fa-chevron-right" />}
          breakLabel={'...'}
          breakClassName={styles.breakMe}
          pageCount={brandNewMeta && brandNewMeta.last_page}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          forcePage={query.page - 1}
          containerClassName={'pagination'}
          activeClassName={styles.active}
          previousClassName={query.page === 1 ? `${styles.hideButton}` : `${styles.showButton}`}
          nextClassName={
            brandNewMeta && query.page === brandNewMeta.last_page ? `${styles.hideButton}` : `${styles.showButton}`
          }
        />
      );
    }
    return null;
  };

  const renderMovieList = () => {
    if (areBrandNewMoviesLoading && brandNewMovies) {
      return <CustomSpinner />;
    }
    return (
      <>
        <MoviesList data={brandNewMovies} />
      </>
    );
  };

  return (
    <Container>
      <Row>
        <Col sm={12}>
          {bannersData && bannersData.image && (
            <BannersList banners={bannersData.image} alt="brand-new-img" />
          )}
        </Col>
      </Row>

      <h2>{t('newStartPage.title')}</h2>
      <Suspense fallback={<CustomSpinner />}>
        <Row>{renderMovieList()}</Row>
        <Row>
          <Col sm={{ span: 9, offset: 3 }}>
            <div className={styles.paginationWrapper}>
              <div className={styles.pagination}>{renderPagination()}</div>
            </div>
          </Col>
        </Row>
      </Suspense>
    </Container>
  );
};

export default BrandNewPage;
