import { InitialStateModel } from 'src/context/auth/utils';
import userInitialState from 'src/context/auth/utils/userInitialState';

export const BASE_URL = process.env.REACT_APP_URL;
export const baseUrl = process.env.REACT_APP_BACKEND_URL;
export const BASE_IMG_URL = process.env.REACT_APP_IMAGE_BASE_URL;
export const BASE_GCORE_IMG_URL = process.env.REACT_APP_GCORE_IMAGE_BASE_URL;
export const PUSHER_ENV = process.env.REACT_APP_PUSHER_ENV;

export const SEPA_PAYMENT_METHOD = "MicroPayment Sepa" ;


export const INITIAL_STATE: InitialStateModel = {
  token: localStorage.getItem('blue_token'),
  isAuthenticated: false,
  showModal: false,
  loading: false,
  user: userInitialState,
  error: '',
  firstLogin: false,
  euPortabilityStatusPopup: '',
  euPortabilityStatusPopupText: '',
  userLocation: localStorage.getItem('blue_lang') || 'DE',
  balance: 0,
};
