import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { FetchedMovieModel } from './types';
import { fetch as fetchRedirect } from 'src/utils/refetch';

interface IFetchMovies {
  homePage: FetchedMovieModel;
  isLoading: boolean;
  refetch: () => void;
}
const useHomepageAllQuery = (isAuthenticated?: boolean): IFetchMovies => {
  const {
    data: homePage,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['homepage_movies', isAuthenticated ? 'hard' : ''],
    queryFn: async () => {
      const response: any = await fetchRedirect(createUrl('/homepage/all'));
      if (!response.body) {
        throw new Error('Network response was not ok');
      }
      return response.body;
    },
  });

  return { homePage, isLoading, refetch };
};

export default useHomepageAllQuery;
