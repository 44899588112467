import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { RangesModel } from '../types';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { createUrl } from 'src/core/api/utils/createUrl';

interface IMoviesRangesModel {
  ranges: RangesModel[];
  areRangesLoading: boolean;
  isError: boolean;
  refetchRanges: () => void;
}

const useMoviesRangesQuery = (isAuthenticated?: boolean): IMoviesRangesModel => {
  const [isError, setIsError] = useState<boolean>(false);
  const {
    data: ranges,
    isLoading: areRangesLoading,
    refetch: refetchRanges,
  } = useQuery({
    queryKey: [`movies_ranges`, isAuthenticated ? 'hard' : 'soft'],
    queryFn: async () => {
      const response: any = await fetchRedirect(createUrl(`/movies/ranges`));
      if (!response.response.ok) {
        setIsError(true);
        return response.body;
      }
      return response.body;
    },
  });

  return { ranges, areRangesLoading, isError, refetchRanges };
};

export default useMoviesRangesQuery;
