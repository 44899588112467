import React, { FC } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import styles from './UpsellModal.module.scss';

type Props = {
  showUpsellModal: boolean;
  onHide: () => void;
  handleUpsell: () => void;
  handleButtonClick: () => void;
};

const UpsellModal: FC<Props> = ({ showUpsellModal, onHide, handleUpsell, handleButtonClick }) => {
  return (
    <Modal centered={true} show={showUpsellModal} onHide={onHide}>
      <Modal.Header closeButton className={styles.modalHeader} closeVariant="white">
        <Modal.Title className={styles.modalTitle}>UNSER ANGEBOT FÜR SIE!</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Row>
          <Col sm={12}>
            <p>
              Holen Sie sich jetzt ein Blue Movie Jahresticket für{' '}
              <span style={{ color: '#49b70e' }}>16,50€ / Monat</span> und sparen sie pro{' '}
              <span style={{ color: '#49b70e' }}>Monat 5€.</span>
            </p>
            <p>Alle Preise inkl. MwSt.</p>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <button className={styles.button} onClick={handleUpsell}>
              JA, DAS WILL ICH
            </button>
          </Col>
          <Col sm={6}>
            <button className={styles.button} onClick={handleButtonClick}>
              NEIN, DANKE
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UpsellModal;
