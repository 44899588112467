import React, { FC, useEffect, useRef } from 'react';

type Props = {
  onFileSelectError: ({ error }: { error: string }) => void;
  onFileSelectSuccess: (file: File) => void;
  submited: boolean;
};

const FileUploader: FC<Props> = ({ onFileSelectError, onFileSelectSuccess, submited }) => {
  const fileInput = useRef<any>(null);
  useEffect(() => {
    if (submited) {
      fileInput.current.value = '';
    }
  }, [submited]);
  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // handle validation
    if (!e.target.files || e.target.files?.length < 0) {
      return;
    }
    const file = e.target.files[0];
    if (!file) {
      fileInput.current.value = '';
    }
    if (file?.size / 1024 > 4096) {
      onFileSelectError({ error: 'File size cannot exceed more than 2MB' });
      fileInput.current.value = '';
    } else {
      onFileSelectSuccess(file);
    }
  };

  return (
    <div className="file-uploader">
      <input ref={fileInput} type="file" onChange={handleFileInput} />
    </div>
  );
};

export default FileUploader;
