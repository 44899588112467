import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import styles from './DepositPage.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Balance } from '../../components/Balance';
import { CustomSpinner } from '../../shared/Spinner';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import { AuthContext } from '../../context/auth';
import { SuccessModal } from '../../components/UI/Modals/SuccessModal';
import parse from 'html-react-parser';
import { priceParser } from '../../utils';
import { PaymentModal } from '../../components/UI/Modals/PaymentModal';
import { PaymentModel, PaymentTypesModel, ProductPricesModel } from '../blueMoviePlus/query/types';
import { useDepositQuery, useDepositPaymentQuery, useVouchersQuery } from './query';
import { oneClickPayment } from '../blueMoviePlus/utils';
import { usePaymentInitMutation, usePaymentMutation } from '../blueMoviePlus/mutation';
import { useBonusCodeMutation } from './mutation';
import { generateImageUrl } from '../../utils/cdnService';
import { SEPA_PAYMENT_METHOD } from '../../core/constants/constants';
import useHandleSepaPayment from '../../hooks/useHandleSepaPayment';
import { TermsAndConditionsComponent } from '../../components/TermsAndConditionsComponent';
import useGeneratePdfQuery from '../../hooks/query/useGeneratePdfQuery';

const baseAppUrl = process.env.REACT_APP_URL;

const DepositPage = () => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [paymentData, setPaymentData] = useState<PaymentModel>({
    payment_types: [],
    product_prices: [],
    last_payments: [],
    bonus_code: {
      bonus_code_money: 0,
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [voucherValue, setVoucherValue] = useState('');
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loadUser } = authContext;
  const [selectedValue, setSelectedValue] = useState<ProductPricesModel>({ id: -1, price: -1 });
  const [selectedType, setSelectedType] = useState<PaymentTypesModel>({
    id: -1,
    gateway: '',
    last_id: -1,
  });
  const [selectedLatsPaymentCard, setSelectedLatsPaymentCard] = useState<number | string>();
  const [voucherResponse, setVoucherResponse] = useState<string>('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const { payment, isPaymentLoading } = useDepositPaymentQuery(isAuthenticated, {
    deposit_page: true,
  });
  const { depositData, isDepositOverviewLoading } = useDepositQuery();
  const { vouchersData } = useVouchersQuery();

  const { mutate } = usePaymentMutation();
  const { mutate: paymentInitMutate } = usePaymentInitMutation();
  const { mutate: bonusCodeMutate } = useBonusCodeMutation();
  const { mutate: generatePdfMutate } = useGeneratePdfQuery();

  const handleSepaPayment = useHandleSepaPayment(selectedType);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAndConditions(event.target.checked);
  };

  useEffect(() => {
    if (payment?.last_payments?.length > 0) {
      setSelectedType({
        id: 0,
        gateway: payment?.last_payments[0].gateway,
        last_id: payment?.last_payments[0].id,
      });
      setSelectedLatsPaymentCard(payment?.last_payments[0].id);
    } else {
      setSelectedType(payment?.payment_types[0]);
    }

    setPaymentData({
      ...paymentData,
      ...payment,
    });

    if (payment?.product_prices && payment?.product_prices[1] && !isPaymentLoading) {
      setSelectedValue(payment?.product_prices[1]);
    }
    //paymentData will render into loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentLoading, payment]);

  const handleSubmit = async () => {
    oneClickPayment(
      setIsLoading,
      setShowPaymentModal,
      selectedType,
      mutate,
      loadUser,
      language,
      navigate,
      location,
      paymentInitMutate,
      selectedValue.id,
      paymentData.product_id as number
    );
  };

  const renderDepositData = () => {
    return (
      depositData &&
      depositData.map((data) => (
        <tr key={data.id}>
          <th>{data.payment_type}</th>
          <th>
            {data.amount}
            {data.currency}
          </th>
          <th>{data.date}</th>
          {data.has_invoice && (
            <th>
              <button onClick={() => generatePdfMutate(data?.id.toString())}>DOWNLOAD</button>
            </th>
          )}
        </tr>
      ))
    );
  };

  const handleVoucherSubmit = async () => {
    try {
      bonusCodeMutate(
        {
          bonus_code: voucherValue,
        },
        {
          onSuccess(data: any) {
            if (data.status === 'success') {
              setVoucherResponse(data.message);
              setVoucherValue('');
              if (data.data.bonus_code_money) {
                setPaymentData({
                  ...paymentData,
                  bonus_code: {
                    bonus_code_money: data.data.bonus_code_money,
                  },
                });
              } else {
                //TODO update user balance in context //
                setShowSuccessModal(true);
              }
            }
          },
          onError(error: any, variables, context) {
            setVoucherResponse(error?.body.message);
            setVoucherValue('');
          },
        }
      );
    } catch (e) {
      console.error('USE BONUSCODE ERROR: ', e.message);
      setVoucherResponse(e.message);
      setVoucherValue('');
    }
  };

  const renderVoucherData = () => {
    return (
      vouchersData &&
      vouchersData.map((data) => (
        <tr key={data.id}>
          <th>{data.payment_type}</th>
          <th>
            {data.amount}
            {data.currency}
          </th>
          <th>{data.date}</th>
          <th>{data.platform}</th>
        </tr>
      ))
    );
  };

  if (isLoading || isPaymentLoading || isDepositOverviewLoading) {
    return <CustomSpinner />;
  }

  return (
    <Container>
      <PaymentModal
        show={showPaymentModal}
        handleClose={() => setShowPaymentModal(false)}
        amount={selectedValue.price}
        handlePayment={handleSepaPayment}
      />
      <div className={styles.pageHeader}>
        <Balance />
      </div>
      <h2>{t('depositPage.depositTitle')}</h2>
      <Row>
        <Col
          className={styles.left}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          md={6}
        >
          <Form>
            <p>{t('depositPage.amount')}</p>
            <Form.Group>
              <Row>
                {paymentData &&
                  paymentData?.product_prices?.map((item, index) => (
                    <Col xs={6} key={item.id}>
                      <div
                        onClick={() => {
                          setSelectedValue(item);
                          localStorage.setItem('blue_price', item.price.toString());
                        }}
                        className={
                          item.id === selectedValue.id
                            ? `${styles.radioWrapper} ${styles.selected}`
                            : `${styles.radioWrapper}`
                        }
                      >
                        <Form.Check
                          className={styles.radioButton}
                          type="radio"
                          id={`default-${index}`}
                          checked={item.id === selectedValue.id}
                        />
                        <div className={styles.priceHolder}>
                          <p>{priceParser(item.price)}</p>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Form.Group>
          </Form>

          <Row>
            <Col xs={12}>
              <div className={styles.totalWrapper}>
                <div>
                  <div>{t('depositPage.numbers')}</div>
                  <div>{t('depositPage.voucher')}</div>
                  <div style={{ fontWeight: 'bold' }}>{t('depositPage.total')}</div>
                </div>
                <div
                  style={{
                    textAlign: 'right',
                  }}
                >
                  <div>{priceParser(selectedValue.price)}</div>
                  <div>{priceParser(paymentData?.bonus_code?.bonus_code_money)}</div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      {priceParser(
                        selectedValue.price +
                          (paymentData?.bonus_code?.bonus_code_money ? paymentData?.bonus_code?.bonus_code_money : 0)
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        <Col
          className={styles.right}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          md={{ span: 6 }}
        >
          {paymentData && paymentData?.last_payments?.length > 0 && (
            <div>
              <p>{t('depositPage.paymentType')}</p>
              <div
                onClick={() =>
                  setSelectedType({
                    ...selectedType,
                    gateway: payment?.last_payments[0].gateway,
                    last_id: selectedLatsPaymentCard ?? payment?.last_payments[0].id,
                    id: 0,
                  })
                }
                className={selectedType.id === 0 ? `${styles.typeWrapper} ${styles.selected}` : `${styles.typeWrapper}`}
              >
                <Form.Check
                  className={styles.radioButton}
                  type="radio"
                  onChange={() =>
                    setSelectedType({
                      ...selectedType,
                      gateway: payment?.last_payments[0].gateway,
                      last_id: selectedLatsPaymentCard ?? payment?.last_payments[0].id,
                      id: 0,
                    })
                  }
                  checked={selectedType.id === 0}
                />
                <Form.Select
                  onChange={(e) => {
                    const gateway = paymentData?.last_payments.filter((item) => item.id === Number(e.target.value))[0]
                      .gateway;
                    setSelectedType({
                      last_id: e.target.value,
                      gateway: gateway,
                      id: 0,
                    });
                    setSelectedLatsPaymentCard(e.target.value);
                  }}
                >
                  {paymentData &&
                    paymentData?.last_payments.map((p) => (
                      <option value={p.id} key={p.id}>
                        {p.info}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div>
          )}
          <div>
            <p>
              {paymentData && paymentData.last_payments && !paymentData.last_payments.length
                ? t('depositPage.paymentType')
                : t('depositPage.otherPaymentTypes')}
            </p>
            <Row>
              {paymentData &&
                paymentData?.payment_types.map((item) => {
                  return (
                    <Col md={6} key={item.id}>
                      <div
                        onClick={() => setSelectedType(item)}
                        className={
                          item.id === selectedType.id
                            ? `${styles.typeWrapper} ${styles.selected}`
                            : `${styles.typeWrapper}`
                        }
                      >
                        <Form.Check
                          className={styles.radioButton}
                          type="radio"
                          onChange={() => setSelectedType(item)}
                          checked={item.id === selectedType.id}
                        />
                        <img
                          alt="type-img"
                          className={styles.typeImage}
                          src={generateImageUrl('gcore', 0, 0, item.image)}
                        />
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>

          <div className={styles.voucherWrapper}>
            <p>{t('depositPage.redeemVoucher')}</p>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
              <input value={voucherValue} onChange={(e) => setVoucherValue(e.target.value)} />
              <button disabled={!voucherValue} onClick={handleVoucherSubmit}>
                {t('depositPage.further')}
              </button>
            </div>
            <p>{parse(voucherResponse)}</p>
          </div>
        </Col>
        <div style={{marginTop: '50px'}}>
          <TermsAndConditionsComponent
            handleCheckboxChange={handleCheckboxChange}
            termsAndConditions={termsAndConditions}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 50,
            width: '100%',
          }}
        >
          <button className={styles.submitButton} onClick={handleSubmit} disabled={isLoading || !termsAndConditions}>
            {selectedType?.id === 0 ? t('depositPage.orderForFee') : t('depositPage.further')}
          </button>
        </div>
      </Row>
      <Row>
        <Col>
          <Accordion>
            <Card className={styles.card}>
              <Accordion.Item className={styles.accordion_item} eventKey="2">
                <Accordion.Button className={styles.accordion_button}>{t('depositPage.yourDeposits')}</Accordion.Button>
                <Accordion.Body>
                  <Card.Body>
                    <Table className={styles.table} bordered size="sm">
                      <thead>
                        <tr>
                          <th>{t('depositPage.accountType')}</th>
                          <th>{t('depositPage.amountTable')}</th>
                          <th>{t('depositPage.depositDate')}</th>
                          <th>{t('depositPage.andere')}</th>
                        </tr>
                      </thead>
                      <tbody>{renderDepositData()}</tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Card>
            <Card className={styles.card}>
              <Accordion.Item className={styles.accordion_item} eventKey="4">
                <Accordion.Button className={styles.accordion_button} as={Card.Header} eventKey="4">
                  {t('depositPage.yourCredits')}
                </Accordion.Button>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <Table className={styles.table} bordered size="sm">
                      <thead>
                        <tr>
                          <th>{t('depositPage.voucherType')}</th>
                          <th>{t('depositPage.amountTable')}</th>
                          <th>{t('depositPage.dateOfIssue')}</th>
                          <th>{t('depositPage.platform')}</th>
                        </tr>
                      </thead>
                      <tbody>{renderVoucherData()}</tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Accordion.Item>
            </Card>
          </Accordion>
        </Col>
      </Row>
      <SuccessModal
        show={showSuccessModal}
        onHide={() => {
          loadUser();
          setShowSuccessModal(false);
        }}
        centered={true}
      />
    </Container>
  );
};

export default DepositPage;
