import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import styles from './MoviesList.module.scss';
import { useTranslation } from 'react-i18next';
import { SuspenseImg } from '../../utils/imageSuspense';
import { PromotionBadge } from '../PromotionBadge';
import { MoviesListDataModel } from './types';
import { CustomLink } from '../CustomLink';
import {generateImageUrl} from "../../utils/cdnService";

type Props = {
  data: MoviesListDataModel[];
};
const MoviesList: FC<Props> = ({ data }) => {
  const {
    i18n: { language },
  } = useTranslation();

  if (!data || data?.length === 0) {
    return <div>No Content.</div>;
  }
  const renderMoviesList = () =>
    data.map((movie) => {
      return (
        <Col key={movie.id} xs={6} sm={3}>
          <div className={styles.movieWrapper}>
            <div className={styles.movieImage}>
              {movie.promotional_image && movie.promotion_value && (
                <PromotionBadge image={movie.promotional_image} value={movie.promotion_value} />
              )}
              <CustomLink to={`/${language}/movie/${movie.id}`}>
                {movie.banner ? <div className={`${styles.marker} ${styles[movie.banner]}`}></div> : null}
                <SuspenseImg alt="" src={`${generateImageUrl('gcore',382, 565, movie.image)}`} />
              </CustomLink>
            </div>
            <div className={styles.movieText}>
              <CustomLink to={`/${language}/movie/${movie.id}`}>
                <h3>{movie.title}</h3>
              </CustomLink>
            </div>
          </div>
        </Col>
      );
    });

  return <>{renderMoviesList()}</>;
};

export default MoviesList;
