import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './ErrorModal.module.scss';
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';

interface ErrorModalI {
  show: boolean;
  onHide: () => void;
  headingText?: string;
  errorText?: string;
}

const ErrorModal: FC<ErrorModalI> = ({ show, onHide, headingText, errorText }) => {
  const { t } = useTranslation();

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header closeButton className={styles.modalHeader} closeVariant="white">
        {headingText}
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Col sm="12">
          <Alert variant={'danger'}>
            <>{errorText}</>
          </Alert>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
