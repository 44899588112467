import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import DOMPurify from 'dompurify';

const useStaticPageQuery = (pageName: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['static_page', pageName],
    queryFn: async () => {
      const res: any = await fetchRedirect(
        createUrl('/static-page', {
          platform_name: 'web',
          page_name: pageName,
        })
      );

      if (!res.response.ok) {
        return res.body.data;
      }
      return res.body.data;
    },
  });

  const html = { __html: DOMPurify.sanitize(data?.html) };
  return { data, isLoading, html };
};

export default useStaticPageQuery;
