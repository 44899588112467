import React, { FC, useReducer } from 'react';
import { PlayerContext } from '.';
import { START_PLAYER, PAUSE_PLAYER, STOP_PLAYER } from '../types';
import { playerInitialValue } from './utils';
import { PlayerReducer } from '.';

type Props = {
  children: React.ReactNode;
};
const PlayerState: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(PlayerReducer, playerInitialValue);

  const startPlayer = () => {
    dispatch({
      type: START_PLAYER,
    });
  };
  const pausePlayer = () => {
    dispatch({
      type: PAUSE_PLAYER,
    });
  };
  const stopPlayer = () => {
    dispatch({
      type: STOP_PLAYER,
    });
  };

  return (
    <PlayerContext.Provider
      value={{
        startPlayer,
        pausePlayer,
        stopPlayer,
        playerActive: state.playerActive,
        playerPaused: state.playerPaused,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};

export default PlayerState;
