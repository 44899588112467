import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { URLWishlistQueryStateModel } from '../types';
import { WishlistBookmarksLinksModel, WishlistBookmarksMetaModel, WishlistBookmarksModel } from './types';

type BookmarksQueryModel = {
  bookmarksData: WishlistBookmarksModel[];
  bookmarksMeta: WishlistBookmarksMetaModel;
  bookmarksLinks: WishlistBookmarksLinksModel;
  isBookmarksQueryLoading: boolean;
  refetchBookmarksQuery: () => void;
};

const useBookmarksQuery = (query: URLWishlistQueryStateModel): BookmarksQueryModel => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['wishlist_bookmark', `${Object.values(query)}`],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/user/bookmarks', query));
      if (!res.response.ok) {
        return res.body;
      } else {
        return res.body;
      }
    },
  });
  const isBookmarksQueryLoading = isLoading;
  const bookmarksData = data?.data;
  const bookmarksMeta = data?.meta;
  const bookmarksLinks = data?.links;
  const refetchBookmarksQuery = refetch;
  return { isBookmarksQueryLoading, bookmarksData, bookmarksMeta, bookmarksLinks, refetchBookmarksQuery };
};

export default useBookmarksQuery;
