import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { baseUrl } from 'src/core/constants';

const useContactSupportMutation = () => {
  const { mutate: mutateContactSupport, isLoading: isContactSupportMutationLoading } = useMutation({
    mutationFn: async (data: FormData) => axios.post(`${baseUrl}/contact-support`, data),
  });
  return { mutateContactSupport, isContactSupportMutationLoading };
};

export default useContactSupportMutation;
