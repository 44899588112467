import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DesktopSearchBar.module.scss';

type Props = {
  handleSearch: ({ key }: { key: string }) => void;
  setSearchTerm: (value: string) => void;
};
const DesktopSearchBar: FC<Props> = ({ handleSearch, setSearchTerm }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.searchBar}>
      <input
        onKeyDown={handleSearch}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={t('menu.searchPlaceholder')}
        type="text"
      />
      <span>
        <i className="fa fa-search" aria-hidden="true" />
      </span>
    </div>
  );
};

export default DesktopSearchBar;
