import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
import { ProductModel } from './types';

type ProductsQuery = {
  isProductsQueryLoading: boolean;
  refetchProductsQuery: () => void;
  products: ProductModel;
};

const useProductsQuery = (isAuthenticated: boolean): ProductsQuery => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['products', isAuthenticated ? '-hard' : '-soft'],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl('/products'));
      if (res.response.ok) {
        return res.body;
      }
    },
    enabled: isAuthenticated,
  });
  const isProductsQueryLoading = isLoading;
  const refetchProductsQuery = refetch;
  const products = data?.data;
  return { products, isProductsQueryLoading, refetchProductsQuery };
};

export default useProductsQuery;
