import { useMutation } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { fetch as fetchRedirect } from 'src/utils/refetch';
const useDeepLinkValidationMutation = () => {
  const { mutate: mutateDeepLinkValidation, isLoading: isDeepLinkValidationMutationLoading } = useMutation({
    mutationFn: async (data: { code: string }) => {
      const response = await fetchRedirect(createUrl('/products/validate-deeplink'), {
        method: 'POST',
        body: JSON.stringify(data),
      });
      if (!response.response.ok) {
        return response.body;
      }
      return response.body;
    },
  });
  return { mutateDeepLinkValidation, isDeepLinkValidationMutationLoading };
};

export default useDeepLinkValidationMutation;
