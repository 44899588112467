import React, { FC, useContext } from 'react';
import { LoginButton } from 'src/components/UI/LoginButton';
import AuthContext from 'src/context/auth/authContext';
import { HeaderFormModel } from '../../types';
import styles from './LoginForm.module.scss';
import { SessionManagementModal } from '../../../UI/Modals/SessionManagementModal';

type Props = {
  form: HeaderFormModel;
  pin: string;
  handleLogin: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePinChange: (e: React.SyntheticEvent) => void;
};

const LoginForm: FC<Props> = ({ handleLogin, handleChange, form, pin, handlePinChange }) => {
  const authContext = useContext(AuthContext);
  const { loading, userLocation, showModal, closeSessionModal, lastLoginData, login } = authContext;

  const handleConfirm = () => {
    if (lastLoginData.username !== '' && lastLoginData.pin !== '') {
      const loginDataWithSessionDeletion = { ...lastLoginData, delete_sessions: true };
      login(loginDataWithSessionDeletion);
      closeSessionModal();
    }
  };

  return (
    <div className={styles.loginWrapper}>
      <form autoComplete="off" onSubmit={handleLogin}>
        <div className={styles.formItem}>
          <input
            name="username"
            value={form.username}
            type="text"
            pattern="[0-9]*"
            autoComplete="off"
            onChange={handleChange}
            placeholder="Kundennummer"
          />
        </div>
        <div className={styles.formItem}>
          {form.pin}
          <input
            name="pin"
            maxLength={4}
            pattern="[0-9]*"
            value={pin
              .split('')
              .map((item) => '⚫')
              .join('')}
            onChange={handlePinChange}
            type="text"
            placeholder={`${userLocation !== 'AT' ? '18+' : 'Sky'} PIN`}
          />
        </div>
        <div className={styles.formItem}>
          <LoginButton loading={loading} handleLogin={handleLogin} disabled={pin?.length < 4 ? true : false || loading} />
        </div>
      </form>
      {showModal && (
        <SessionManagementModal centered={true} show={showModal} onHide={closeSessionModal} onConfirm={handleConfirm} />
      )}
    </div>
  );
};

export default LoginForm;
