import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { baseUrl } from 'src/core/constants';
import { fetch as fetchRequest } from 'src/utils/refetch';
import { headers } from '../constants';
import { UserQueryModel } from './types';

interface IUserModel {
  user: UserQueryModel['data'];
  isUserLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  refetchUserQuery: () => void;
  isRefetching: boolean;
  isFetching: boolean;
}

const useUserQuery = (hasToken = false): IUserModel => {
  const token = localStorage.getItem('blue_token');
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const [isError, setIsError] = useState<boolean>(false);
  const {
    data,
    isLoading: isUserLoading,
    refetch: refetchUserQuery,
    isSuccess,
    isRefetching,
    isFetching,
  } = useQuery({
    queryKey: [`me`],
    queryFn: () =>
      fetchRequest(`${baseUrl}/user/me`, {
        headers: headers,
      }).then((res: any) => {
        if (!res.response.ok) {
          setIsError(true);
          throw new Error('Network response was not ok');
        }
        if (res.response.ok) {
          return res.body.data;
        }
      }),
    enabled: !!token || hasToken,
  });

  const user = data;

  return { user, isUserLoading, isError, isSuccess, refetchUserQuery, isRefetching, isFetching };
};

export default useUserQuery;
