import { useMutation } from '@tanstack/react-query';
import { baseUrl } from '../../core/constants';
import { headers } from '../constants';

const useGeneratePdfQuery = () => {
    const pdfHeaders = {
        ...headers,
    };

    const mutation = useMutation(
        async (transactionId: string) => {
            const response = await fetch(`${baseUrl}/user/ticket/${transactionId}/invoice`, {
                headers: pdfHeaders,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const newUrl = URL.createObjectURL(blob);

            const win = window.open(newUrl, '_blank');
            if (win) {
                win.focus();
            } else {
                alert('Please allow popups for this website');
            }

            return blob;
        },
        {
            onError: (error) => {
                console.error('Error in useGeneratePdfQuery:', error);
            },
            onSuccess: () => {
                mutation.mutate('generate_pdf');
            },
        }
    );

    return mutation;
};

export default useGeneratePdfQuery;