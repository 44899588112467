import React, { FC } from 'react';
import styles from './MobileSearchBar.module.scss';

type Props = {
  handleSearch: ({ key }: { key: string }) => void;
  setSearchTerm: (value: string) => void;
};

const MobileSearchBar: FC<Props> = ({ handleSearch, setSearchTerm }) => {
  return (
    <div className={styles.mobileSearchBar}>
      <input
        onKeyDown={handleSearch}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Suchbegriff eingeben"
        type="text"
      />
      <span>
        <i className="fa fa-search" aria-hidden="true" />
      </span>
    </div>
  );
};

export default MobileSearchBar;
