import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from '../../../shared/Spinner/Spinner';
import { useStaticPageQuery } from 'src/hooks/query';

const TermsAndConditions: FC = () => {
  const { html, isLoading } = useStaticPageQuery('conditions');

  return (
    <Container>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col sm={12}>
              <h2>
                ALLGEMEINE GESCHÄFTSBEDINGUNGEN FÜR ONLINE DIENSTLEISTUNGEN der <br />
                "tmc Media Group AG"
              </h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div dangerouslySetInnerHTML={html} />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default TermsAndConditions;
