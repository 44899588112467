import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'src/core/api/utils/createUrl';
import { BannersModel } from 'src/pages/home/query/types';
import { fetch as fetchRedirect } from 'src/utils/refetch';

type BannersQueryModel = {
  banners: BannersModel[];
  areBannersLoading: boolean;
  refetchBannerQuery: () => void;
};

const useBannersQuery = (page: string | number, isAuthenticated?: boolean): BannersQueryModel => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['banners', isAuthenticated ? '-hard' : '-soft'],
    queryFn: async () => {
      const res: any = await fetchRedirect(createUrl(`/banners/page/${page}`));

      if (res.response.ok) {
        return res.body;
      }
    },
  });
  const banners = data?.data;
  const areBannersLoading = isLoading;
  const refetchBannerQuery = refetch;
  return { banners, areBannersLoading, refetchBannerQuery };
};

export default useBannersQuery;
